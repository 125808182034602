import { useEffect, useState } from "react";

const DEVICE_UUID = "device-uuid";

const getLocalData = () => localStorage.getItem(DEVICE_UUID) ?? crypto.randomUUID();

function useDeviceUuid() {
  const [deviceUuid] = useState(getLocalData());

  useEffect(() => {
    localStorage.setItem(DEVICE_UUID, deviceUuid);
  }, [deviceUuid]);

  return { deviceUuid };
}

export default useDeviceUuid;
