import {
  AddCommentToAssessmentPayload,
  AssignToUserPayload,
  ChangeEntityStatusPayload,
  Entity,
} from "types/types";

export enum PayloadNames {
  STATUS = "statusPayload",
  ASSIGNMENT = "assignmentPayload",
  COMMENT = "commentPayload",
}

export type PayloadName =
  | PayloadNames.STATUS
  | PayloadNames.ASSIGNMENT
  | PayloadNames.COMMENT;

export type Payload =
  | ChangeEntityStatusPayload
  | AssignToUserPayload
  | AddCommentToAssessmentPayload
  | null;

type SetPayload = (payloadName: PayloadName, payload: Payload) => void;

export type RequestError = {
  status: boolean;
  assignment: boolean;
  comment: boolean;
};

type ClearError = (key: keyof RequestError) => void;

export type StatusProps = {
  entity: Entity;
  entityId: string;
  setPayload: SetPayload;
  isError: boolean;
  clearError: ClearError;
};

export type AssignmentProps = {
  setPayload: SetPayload;
  isError: boolean;
  clearError: ClearError;
};

export type CommentProps = {
  setPayload: SetPayload;
  commentPayload: AddCommentToAssessmentPayload;
  isError: boolean;
  clearError: ClearError;
};

export type AssignedTo = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar: { id: string } | null;
};

export enum RequestNames {
  STATUS = "status",
  ASSIGNMENT = "assignment",
  COMMENT = "comment",
}

export type RequestName =
  | RequestNames.STATUS
  | RequestNames.ASSIGNMENT
  | RequestNames.COMMENT;
