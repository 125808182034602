import { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Layout from "components/layout/Layout";
import Login from "pages/login/Login";
import ForgotPassword from "pages/forgot-password/ForgotPassword";
import ResetPassword from "pages/reset-password/ResetPassword";
import EnterSerialNumber from "pages/enter-serial-number/EnterSerialNumber";
import CheckLists from "pages/check-lists/CheckLists";
import Questionnaire from "pages/questionnaire/Questionnaire";
import ProtectedRoute from "routes/ProtectedRoute";
import ReAuthModal from "components/organisms/ReAuthModal";
import System from "pages/system/System";
import Summary from "pages/summary/Summary";
import AccidentForm from "pages/accident-form/AccidentForm";
import AccidentSummary from "pages/accident-summary/AccidentSummary";
import Home from "pages/landing-page/Home";
import Profile from "pages/profile/Profile";
import DeviceInfo from "pages/device-info/DeviceInfo";
import AccidentFormsList from "pages/accident-forms-list/AccidentFormsList";
import InviteUser from "pages/invite-user/InviteUser";
import CookieConsentBar from "components/molecules/CookieConsentBar";
import CameraTest from "pages/camera-test/CameraTest";
import ReactWebcam from "pages/camera-test/react-webcam/ReactWebcam";
import ReactCameraIos from "pages/camera-test/react-camera-ios/ReactCameraIos";
import ReactHtmlCameraPhoto from "pages/camera-test/react-html-camera-photo/ReactHtmlCameraPhoto";
import TableOfContents from "pages/table-of-contents/TableOfContents";
import QrLogin from "pages/qr-login/QrLogin";
import LoginScanner from "pages/login/components/login-scanner/LoginScanner";
import PhotoGallery from "pages/photo-gallery/PhotoGallery";
import CameraDetection from "pages/camera-detection/CameraDetection";
import SnMethodSelection from "pages/sn-method-selection/SnMethodSelection";
import MachineProcessInfo from "pages/machine-process-info/MachineProcessInfo";
import StatusChange from "pages/status-change/StatusChange";
import SuccessfullySent from "pages/successfully-sent/SuccessfullySent";
import UserTasks from "pages/user-tasks/Tasks";
import IndexedDbError from "pages/indexed-db-error/IndexedDBError";
import SelectedTask from "pages/selected-task-created/SelectedTask";
import TaskCreator from "pages/task-creator/TaskCreator";
import AdditionalOptions from "pages/additional-options/AdditionalOptions";
import ProdServerWarning from "pages/prod-server-warning/ProdServerWarning";
import QrLoginError from "pages/qr-login-error/QrLoginError";
import ServerOffAlert from "pages/server-off-alert/ServerOffAlert";
import ApkUpdateInfo from "pages/apk-update-info/ApkUpdateInfo";

function App() {
  return (
    <Suspense fallback={<div style={{ backgroundColor: "#fff" }}></div>}>
      <Layout>
        <Routes>
          <Route element={<Login />} path='/login' />
          <Route element={<LoginScanner />} path='/login/scanner' />
          <Route element={<QrLogin />} path='/qr-login' />
          <Route element={<QrLoginError />} path='/qr-login-error' />
          <Route element={<ForgotPassword />} path='/forgot-password' />
          <Route element={<ResetPassword />} path='/reset-password' />
          <Route element={<InviteUser />} path='/invite' />
          <Route element={<ApkUpdateInfo />} path='/apk-update-info' />

          <Route
            path='/home'
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />

          <Route
            path='/camera-detection'
            element={<CameraDetection />}
            // element={
            //   <ProtectedRoute>
            //     <CameraDetection />
            //   </ProtectedRoute>
            // }
          />

          <Route
            path='/serial-number-method-selection'
            element={
              <ProtectedRoute>
                <SnMethodSelection />
              </ProtectedRoute>
            }
          />

          <Route
            path='/enter-serial-number'
            element={
              <ProtectedRoute>
                <EnterSerialNumber />
              </ProtectedRoute>
            }
          />

          <Route
            path='/check-lists'
            element={
              <ProtectedRoute>
                <CheckLists />
              </ProtectedRoute>
            }
          />

          <Route
            path='/questionnaire/:id/:question'
            element={
              <ProtectedRoute>
                <Questionnaire />
              </ProtectedRoute>
            }
          />

          <Route
            path='/table-of-contents'
            element={
              <ProtectedRoute>
                <TableOfContents />
              </ProtectedRoute>
            }
          />

          <Route
            path='/questionnaire/summary/:questionnaireId'
            element={
              <ProtectedRoute>
                <Summary />
              </ProtectedRoute>
            }
          />

          <Route
            path='/status-change'
            element={
              <ProtectedRoute>
                <StatusChange />
              </ProtectedRoute>
            }
          />

          <Route
            path='/status-change/successfully-sent'
            element={
              <ProtectedRoute>
                <SuccessfullySent />
              </ProtectedRoute>
            }
          />

          <Route
            path='/device-info'
            element={
              <ProtectedRoute>
                <DeviceInfo />
              </ProtectedRoute>
            }
          />

          <Route
            path='/camera-test'
            element={
              <ProtectedRoute>
                <CameraTest />
              </ProtectedRoute>
            }
          />

          <Route
            path='/camera-test/react-webcam'
            element={
              <ProtectedRoute>
                <ReactWebcam />
              </ProtectedRoute>
            }
          />

          <Route
            path='/camera-test/react-camera-ios'
            element={
              <ProtectedRoute>
                <ReactCameraIos />
              </ProtectedRoute>
            }
          />

          <Route
            path='/camera-test/react-html5-camera-photo'
            element={
              <ProtectedRoute>
                <ReactHtmlCameraPhoto />
              </ProtectedRoute>
            }
          />

          <Route
            path='/system'
            element={
              <ProtectedRoute>
                <System />
              </ProtectedRoute>
            }
          />

          <Route
            path='/accident-forms-list'
            element={
              <ProtectedRoute>
                <AccidentFormsList />
              </ProtectedRoute>
            }
          />

          <Route
            path='/accident-form/:question'
            element={
              <ProtectedRoute>
                <AccidentForm />
              </ProtectedRoute>
            }
          />

          <Route
            path='/accident-form/summary/:accidentFormId'
            element={
              <ProtectedRoute>
                <AccidentSummary />
              </ProtectedRoute>
            }
          />

          <Route
            path='/gallery'
            element={
              <ProtectedRoute>
                <PhotoGallery />
              </ProtectedRoute>
            }
          />

          <Route
            path='/profile'
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />

          <Route
            path='/machine-process-info'
            element={
              <ProtectedRoute>
                <MachineProcessInfo />
              </ProtectedRoute>
            }
          />

          <Route
            path='/tasks'
            element={
              <ProtectedRoute>
                <UserTasks />
              </ProtectedRoute>
            }
          />

          <Route
            path='/indexed-db-error'
            element={
              <ProtectedRoute>
                <IndexedDbError />
              </ProtectedRoute>
            }
          />

          <Route
            path='/task/selected'
            element={
              <ProtectedRoute>
                <SelectedTask />
              </ProtectedRoute>
            }
          />

          <Route
            path='/task-creator'
            element={
              <ProtectedRoute>
                <TaskCreator />
              </ProtectedRoute>
            }
          />

          <Route
            path='/additional-options'
            element={
              <ProtectedRoute>
                <AdditionalOptions />
              </ProtectedRoute>
            }
          />

          <Route
            path='/production-server-warning'
            element={
              <ProtectedRoute>
                <ProdServerWarning />
              </ProtectedRoute>
            }
          />

          <Route path='/server-off-alert' element={<ServerOffAlert />} />

          <Route element={<Navigate replace to='/login' />} path='*' />
        </Routes>

        <ReAuthModal />
        <CookieConsentBar />
      </Layout>
    </Suspense>
  );
}

export default App;
