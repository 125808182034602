import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCookie } from "react-use-cookie";
import Select from "react-select";

import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import { MachineProcessStatus, Collection } from "types/types";
import useMachineProcessInfo from "hooks/useMachineProcessInfo";
import { ACCESS_TOKEN } from "utils/utils";
import { getCollection, getStatusOptions } from "./utils/status.utils";
import { PayloadNames, StatusProps } from "../../types/status-change.types";
import { StatusOption } from "./types/status.types";

import { StyledLabel } from "styles/generalStyles";
import { Container, dot } from "./styles/status.styles";
import { selectControlStyles } from "styles/select.styles";

const { STATUS } = PayloadNames;

function Status({ entityId, entity, setPayload, isError, clearError }: StatusProps) {
  const [selectedStatus, setSelectedStatus] = useState<StatusOption>();
  const {
    i18n: { language },
  } = useTranslation();
  const options = getStatusOptions(language);
  const { statusTranslated } = useMachineProcessInfo();
  const {
    state: { machineProcess },
  } = useContext(QuestionnairesContext);
  const token = getCookie(ACCESS_TOKEN);

  // --------------- Payload handler ---------------

  const handlePayload = (status: MachineProcessStatus) => {
    const collection = getCollection(entity) as Collection;

    const payload = {
      token,
      id: entityId,
      collection,
      status,
    };

    setPayload(STATUS, payload);
  };

  // --------------- On change handler ---------------

  const onSelectChange = (option: StatusOption | null) => {
    if (!option) return;

    setSelectedStatus(option);
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (!machineProcess) return;

    const { Machine, Process } = machineProcess;
    const isInitialStatusInStorage = !!sessionStorage.getItem("initialStatus");

    if (Machine) {
      setSelectedStatus({ label: statusTranslated, value: Machine.status });

      if (!isInitialStatusInStorage) {
        sessionStorage.setItem("initialStatus", Machine.status);
      }
    }

    if (Process) {
      setSelectedStatus({ label: statusTranslated, value: Process.status });

      if (!isInitialStatusInStorage) {
        sessionStorage.setItem("initialStatus", Process.status);
      }
    }
  }, [machineProcess, statusTranslated]);

  useEffect(() => {
    if (selectedStatus) handlePayload(selectedStatus.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus]);

  return (
    <Container>
      <StyledLabel>Status:</StyledLabel>
      <Select
        onChange={onSelectChange}
        options={options}
        value={selectedStatus}
        isSearchable={false}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            ...selectControlStyles(isError),
            zIndex: 1000,
          }),
          singleValue: (styles, params) => ({
            ...styles,
            fontSize: 18,
            fontFamily: "GothamBold",
            color: "#212f5a",
            ...dot(params.data.value),
          }),
        }}
      />
    </Container>
  );
}

export default Status;
