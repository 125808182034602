import { Entity, MachineProcessStatuses, EntitiesEnum } from "types/types";
import { CollectionEnum } from "types/permissions.types";

import { getMachineProcessStatusTranslation } from "utils/utils";

const { OUT_OF_ORDER, PUBLISHED } = MachineProcessStatuses;
const { MACHINE, PROCESS } = EntitiesEnum;
const { MACHINES, PROCESSES } = CollectionEnum;

export const getStatusOptions = (language: string) =>
  [PUBLISHED, OUT_OF_ORDER].map((value) => ({
    label: getMachineProcessStatusTranslation(language, value),
    value,
  }));

export const getCollection = (entity: Entity) => {
  if (entity === MACHINE) {
    return MACHINES;
  }

  if (entity === PROCESS) {
    return PROCESSES;
  }
};
