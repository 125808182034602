import { instance } from "axios-instance/axios-instance";

import {
  CreateTaskPayload,
  EditTaskPayload,
  EditTaskResponse,
  GetCreatedTasksResponse,
} from "types/tasks.types";

export const getTasks = (token: string) => async () => {
  let path = "/items/Tasks?limit=1000&filter[status][_neq]=archived";

  const { data } = await instance.get<GetCreatedTasksResponse>(path, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data.data;
};

export const createTask = async <T>(payload: CreateTaskPayload) => {
  const { token, ...body } = payload;

  const { data } = await instance.post<T | null>("/items/Tasks", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const editTask = async (payload: EditTaskPayload) => {
  const { token, id, ...body } = payload;

  const { data } = await instance.patch<EditTaskResponse>(`/items/Tasks/${id}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};
