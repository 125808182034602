import { FieldsEnum } from "types/permissions.types";

// ***** COMMON *****

const commonPermittedInit = {
  ALL: false,
  [FieldsEnum.ID]: false,
  [FieldsEnum.STATUS]: false,
  [FieldsEnum.USER_CREATED]: false,
  [FieldsEnum.USER_UPDATED]: false,
  [FieldsEnum.DATE_CREATED]: false,
  [FieldsEnum.DATE_UPDATED]: false,
};

const machProcPermittedInit = {
  [FieldsEnum.SERIAL_NUMBER]: false,
  [FieldsEnum.TAGS]: false,
  [FieldsEnum.DEPARTMENT]: false,
  [FieldsEnum.LAST_ASSESSMENT]: false,
  [FieldsEnum.SERVICE_EXPIRY_DATE]: false,
  [FieldsEnum.MISC]: false,
  [FieldsEnum.ASSESSMENTS]: false,
};

// ***************

const tasksPermittedInit = {
  ...commonPermittedInit,
  [FieldsEnum.ASSIGN_TO]: false,
  [FieldsEnum.DESCRIPTION]: false,
  [FieldsEnum.FILES]: false,
  [FieldsEnum.RELATED]: false,
  [FieldsEnum.SORT]: false,
  [FieldsEnum.TITLE]: false,
};

const machinesPermittedInit = {
  ...commonPermittedInit,
  ...machProcPermittedInit,
  [FieldsEnum.MACHINE_MODEL]: false,
  [FieldsEnum.MACHINE_TYPE]: false,
  [FieldsEnum.ACCORDION]: false,
  [FieldsEnum.SOME_RAW_GROUP]: false,
  [FieldsEnum.MAKE]: false,
  [FieldsEnum.MAKE_YEAR]: false,
  [FieldsEnum.HOUR_METER]: false,
  [FieldsEnum.DOCUMENTS]: false,
};

const processesPermittedInit = {
  ...commonPermittedInit,
  ...machProcPermittedInit,
  [FieldsEnum.PROCESS_NAME]: false,
  [FieldsEnum.PROCESS_TYPE]: false,
  [FieldsEnum.ADDRESS]: false,
  [FieldsEnum.GEOLOCATION]: false,
};

const tasksPermissionsInit = {
  PERMITTED_TO_CREATE: tasksPermittedInit,
  PERMITTED_TO_READ: tasksPermittedInit,
  PERMITTED_TO_UPDATE: tasksPermittedInit,
  PERMITTED_TO_DELETE: tasksPermittedInit,
  PERMITTED_TO_SHARE: tasksPermittedInit,
};

const machinesPermissionsInit = {
  PERMITTED_TO_CREATE: machinesPermittedInit,
  PERMITTED_TO_READ: machinesPermittedInit,
  PERMITTED_TO_UPDATE: machinesPermittedInit,
  PERMITTED_TO_DELETE: machinesPermittedInit,
  PERMITTED_TO_SHARE: machinesPermittedInit,
};

const processesPermissionsInit = {
  PERMITTED_TO_CREATE: processesPermittedInit,
  PERMITTED_TO_READ: processesPermittedInit,
  PERMITTED_TO_UPDATE: processesPermittedInit,
  PERMITTED_TO_DELETE: processesPermittedInit,
  PERMITTED_TO_SHARE: processesPermittedInit,
};

export const PERMISSIONS_INIT = {
  TASKS_PERMISSIONS: tasksPermissionsInit,
  MACHINES_PERMISSIONS: machinesPermissionsInit,
  PROCESSES_PERMISSIONS: processesPermissionsInit,
};
