import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";
import { getCookie, setCookie } from "react-use-cookie";
import { useLocation } from "react-router-dom";
import { AxiosError } from "axios";

import { ACCESS_TOKEN, REFRESH_TOKEN, getInitial } from "utils/utils";
import { getUserInfo } from "api/user";
import { getCompany } from "api/companies";
import usePermissions from "hooks/permissions-hooks/usePermissions";
import {
  User,
  UserRoles,
  Company,
  UserInfoResponse,
  ComapaniesInfoResponse,
} from "types/types";

import AuthContext from "../auth-context/AuthContext";
import useTasksPermissions from "hooks/permissions-hooks/useTasksPermissions";
import useMachinesPermissions from "hooks/permissions-hooks/useMachinesPermissions";
import { Labels, UserContextType } from "./types/user-context.types";

import { PERMISSIONS_INIT } from "./utils/user-context.utils";
import useProcessesPermissions from "hooks/permissions-hooks/useProcessesPermissions";

const UserContext = createContext<UserContextType>({
  user: undefined,
  userInitials: "",
  isLoading: false,
  isError: false,
  isSuccess: false,
  setUser: () => {},
  toggleSideMenu: () => {},
  sideMenuOpen: false,
  removeUserInfoQuery: () => {},
  labels: { serialNumberTitle: null, gravityAlertInfo: null },
  setUserInfoEnabled: () => {},
  userPermissions: [],
  PERMISSIONS: PERMISSIONS_INIT,
});

const { ADMINISTRATOR, MANAGER, LEADER, OPERATOR } = UserRoles;

export function UserContextProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User>();
  const [labels, setLabels] = useState<Labels>({
    gravityAlertInfo: null,
    serialNumberTitle: null,
  });
  const [userInfoEnabled, setUserInfoEnabled] = useState(false);
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [companyId, setCompanyId] = useState("");

  const { setQrTokenStatus, setAuthenticated, isQrLogin } = useContext(AuthContext);
  const token = getCookie(ACCESS_TOKEN);

  const { pathname } = useLocation();

  const { userPermissions } = usePermissions();
  const { TASKS_PERMISSIONS } = useTasksPermissions(userPermissions);
  const { MACHINES_PERMISSIONS } = useMachinesPermissions(userPermissions);
  const { PROCESSES_PERMISSIONS } = useProcessesPermissions(userPermissions);

  const userInitials = user
    ? `${getInitial(user.first_name)}${getInitial(user.last_name)}`
    : "";

  const setLabelsBasedOnRole = useCallback(
    (data: Company) => {
      if (!user) return;

      const {
        SerialNo_Title,
        GravityAlert_Manager,
        GravityAlert_Operator,
        GravityAlert_Leader,
      } = data;
      const serialNumberTitle = SerialNo_Title;
      let gravityAlertInfo = null;

      switch (user.role.name) {
        case ADMINISTRATOR:
        case MANAGER:
          gravityAlertInfo = GravityAlert_Manager;
          break;
        case LEADER:
          gravityAlertInfo = GravityAlert_Leader;
          break;
        case OPERATOR:
          gravityAlertInfo = GravityAlert_Operator;
      }

      setLabels({ serialNumberTitle, gravityAlertInfo });
    },
    [user],
  );

  // --------------- On success handlers ---------------

  const onGetUserInfoSuccess = ({ data }: UserInfoResponse) => {
    setUser(data);

    if (isQrLogin) {
      setQrTokenStatus("valid");
      setAuthenticated(true);
    }
  };

  const onGetCompaniesInfoSuccess = ({ data }: ComapaniesInfoResponse) => {
    setLabelsBasedOnRole(data[0]);
  };

  // --------------- On error handlers ---------------

  const onGetUserInfoError = (error: AxiosError) => {
    if (error.response?.status === 401) {
      if (isQrLogin) {
        setQrTokenStatus("invalid");
        setAuthenticated(false);
        setCookie(ACCESS_TOKEN, "");
        setCookie(REFRESH_TOKEN, "");
      }
    }
  };

  const onGetCompaniesInfoError = () => {
    setLabels({ serialNumberTitle: "---", gravityAlertInfo: "---" });
  };

  // --------------- API handlers ---------------

  const {
    isLoading,
    isError,
    isSuccess,
    remove: removeUserInfoQuery,
  } = useQuery("userInfo", getUserInfo(token), {
    onSuccess: onGetUserInfoSuccess,
    onError: onGetUserInfoError,
    enabled: userInfoEnabled,
    keepPreviousData: false,
    retry: false,
  });

  useQuery("companyInfo", getCompany({ token, companyId }), {
    onSuccess: onGetCompaniesInfoSuccess,
    onError: onGetCompaniesInfoError,
    enabled: !!companyId,
    keepPreviousData: false,
    retry: false,
  });

  const toggleSideMenu = () => {
    setSideMenuOpen((prev) => !prev);
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (!isQrLogin) {
      const enabled =
        (!!token && pathname !== "/" && !pathname.includes("login")) ||
        (!!token && pathname.includes("qr-login"));

      setUserInfoEnabled(enabled);
    }
  }, [isQrLogin, pathname, token]);

  useEffect(() => {
    if (!user) return;

    if ("Company" in user) {
      setLabelsBasedOnRole(user.Company);
    } else if ("Department" in user) {
      const User = user as User;

      if (User.Department) {
        const company = User.Department.Company;

        if (company) {
          setCompanyId(company.id);
        }
      }
    }
  }, [user, setLabelsBasedOnRole]);

  return (
    <UserContext.Provider
      value={{
        user,
        userInitials,
        isLoading,
        isError,
        isSuccess,
        setUser,
        toggleSideMenu,
        sideMenuOpen,
        removeUserInfoQuery,
        labels,
        setUserInfoEnabled,
        userPermissions,
        PERMISSIONS: { TASKS_PERMISSIONS, MACHINES_PERMISSIONS, PROCESSES_PERMISSIONS },
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
