import styled from "styled-components/macro";

import { device, getCurrentYear } from "utils/utils";
import packageInfo from "../../../package.json";

const Container = styled.footer`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 1rem;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;

  .copy-right {
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }

  .year {
    font-size: inherit;
  }

  .version {
    font-size: inherit;
  }

  @media screen and (min-height: 616px) {
    bottom: 2.5rem;
  }

  @media ${device.iPad} {
    bottom: 5rem;
  }
`;

function AppFooter() {
  return (
    <Container>
      <div className='version'>v.{packageInfo.version}</div>
      <div className='copy-right'>
        <span>&#xa9;</span>
        <span className='year'>{getCurrentYear()}</span>
      </div>
    </Container>
  );
}

export default AppFooter;
