import { instance } from "axios-instance/axios-instance";
import { AppSettingsResponse } from "types/types";

export const getAppSettings = async () => {
  const { data } = await instance.get<AppSettingsResponse>("/settings");

  return data;
};

export const ping = async () => {
  await instance.get<any>("/server/ping");
};
