import {
  SyntheticEvent,
  MouseEvent,
  useEffect,
  useState,
  useRef,
  FocusEvent,
} from "react";
import { useNavigate } from "react-router-dom";
import type { Swiper as SwiperType } from "swiper";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { FilesGallery, FormType, QUESTION_TYPE_IDS } from "types/types";
import { isMobile, placeCursorAtTheEnd } from "utils/utils";
import useModal from "hooks/useModal";
import useQueryString from "hooks/useQueryString";
import usePhotoComment from "hooks/usePhotoComment";
import usePhotosObjectStore from "hooks/usePhotosObjectStore";
import useDictaphoneAccess from "hooks/useDictaphoneAccess";

import { ReactComponent as BinIcon } from "assets/icons/bin.svg";
import { ReactComponent as MoreIcon } from "assets/icons/more-vertical.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

import ConfirmationModal from "components/organisms/ConfirmationModal";
import Button from "components/atoms/Button";
import TopBar from "./components/TopBar";
import NoPhotos from "./components/NoPhotos";
import Dictaphone from "components/molecules/Dictaphone";

import { ContentContainer } from "styles/generalStyles";

import {
  Container,
  BinContainer,
  PopUpMenuContainer,
  PopUpMenuContent,
  MenuOption,
  CommentInput,
  CommentPlaceHolder,
  CommentReply,
  CommentTextarea,
  CommentButtons,
  ButtonContainer,
  CancelSaveButtons,
} from "./styles/photoGalleryStyles";

enum DeleteOptions {
  PHOTO = "photo",
  COMMENT = "comment",
  NONE = "",
}

enum MenuOptions {
  EDIT = "edit",
  DELETE = "delete",
}

type DeleteType = DeleteOptions.PHOTO | DeleteOptions.COMMENT | DeleteOptions.NONE;

const { FILE_PHOTO } = QUESTION_TYPE_IDS;

function PhotoGallery() {
  const [photos, setPhotos] = useState<FilesGallery>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [initialSlide, setInitialSlide] = useState<number | undefined>();
  const [commentTextAreaVisible, setCommentTextAreaVisible] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const { t } = useTranslation(["photo-gallery", "common"]);
  const navigate = useNavigate();

  const currentComment = useRef("");

  if (photos.length) {
    const currentPhoto = photos[currentIndex];

    if (currentPhoto) {
      currentComment.current = currentPhoto.comment;
    }
  }

  const { open, openModal, closeModal } = useModal();
  const query = useQueryString();

  const { saveComment, deleteComment } = usePhotoComment({
    photos,
    setPhotos,
    currentIndex,
    textareaValue,
  });

  const { isDictaphoneAccessible } = useDictaphoneAccess();

  // search params
  const formType = query.get("formType") as FormType;
  const currentQuestionId = query.get("currentQuestionId");
  const photoIndex = query.get("photoIndex");
  const isListening = !!query.get("listening");

  const { getPhotosFromIndexedDB, updatePhotosInIndexedDB } =
    usePhotosObjectStore(formType);

  const photosVisible = initialSlide !== undefined && !!photos && !!photos.length;
  const noPhotosVisible = !!photos && !photos.length;

  const { PHOTO, COMMENT, NONE } = DeleteOptions;
  const { EDIT, DELETE } = MenuOptions;

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const deleteTypeRef = useRef<DeleteType>(NONE);

  let message = "";

  if (deleteTypeRef.current === PHOTO) {
    message = t("modal.question.delete-photo");
  }

  if (deleteTypeRef.current === COMMENT) {
    message = t("modal.question.delete-comment");
  }

  let buttonsVisible = true;

  if (isMobile) {
    buttonsVisible = isTextareaFocused;
  } else {
    buttonsVisible = commentTextAreaVisible;
  }

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleCloseModal = () => {
    closeModal();
    deleteTypeRef.current = NONE;
  };

  const handleClickOutside = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (menuOpen) {
      closeMenu();
    }
  };

  // *************** Event handlers ***************

  const onSlideChange = ({ activeIndex }: SwiperType) => {
    const path = `/gallery?formType=${formType}&currentQuestionId=${currentQuestionId}&photoIndex=${activeIndex}`;
    setCurrentIndex(activeIndex);
    navigate(path, { replace: true });

    if (commentTextAreaVisible) {
      setCommentTextAreaVisible(false);
    }
    if (textareaValue) {
      setTextareaValue("");
    }
    if (editMode) {
      setEditMode(false);
    }
    if (isTextareaFocused) {
      setIsTextareaFocused(false);
    }
  };

  const onImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    const height = e.currentTarget.clientHeight / 10; // px to rem
    const width = e.currentTarget.clientWidth / 10; // px to rem

    setImageSize({ width, height });
  };

  const onTextareaFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
    const temp_value = e.target.value;
    // Setting cursor at the end of textarea value
    e.target.value = "";
    e.target.value = temp_value;

    setIsTextareaFocused(true);
  };

  const onTextTranscriptChange = (textTranscript: string) => {
    setTextareaValue(textTranscript);
  };

  // *************** Photo actions ***************

  const deletePhoto = () => {
    const updatedFilesGallery = photos;
    updatedFilesGallery.splice(currentIndex, 1);

    updatePhotosInIndexedDB({
      FilesGallery: updatedFilesGallery,
      Question: currentQuestionId as string,
    });

    setCurrentIndex(currentIndex);
    setInitialSlide(currentIndex);
    setPhotos(updatedFilesGallery);

    if (commentTextAreaVisible) {
      setCommentTextAreaVisible(false);
    }
  };

  // *************** On click handlers ***************

  const onBackArrowClick = () => {
    const path = localStorage.getItem("galleryReturnPath");

    if (path) {
      navigate(path, { replace: true });
      localStorage.removeItem("galleryReturnPath");
    }
  };

  const onBinIconClick = () => {
    deleteTypeRef.current = PHOTO;
    openModal();
  };

  const onMoreIconClick = () => {
    openMenu();
  };

  const onMenuOptionClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    const { id: option } = e.currentTarget;

    if (option === EDIT) {
      closeMenu();
      setTextareaValue(currentComment.current);
      setCommentTextAreaVisible(true);
      setEditMode(true);
    }

    if (option === DELETE) {
      deleteTypeRef.current = COMMENT;
      closeMenu();
      openModal();
    }
  };

  const onClosIconClick = (e: MouseEvent<SVGElement>) => {
    e.stopPropagation();
    closeMenu();
  };

  const onCommentPlaceholderClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setCommentTextAreaVisible(true);
  };

  const onSaveButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    saveComment();
    setIsTextareaFocused(false);
    setCommentTextAreaVisible(false);
    setTextareaValue("");

    if (editMode) {
      setEditMode(false);
    }
  };

  const onCancelButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setIsTextareaFocused(false);
    setCommentTextAreaVisible(false);

    if (!!textareaValue) {
      setTextareaValue("");
    }
    if (editMode) {
      setEditMode(false);
    }
  };

  const onConfirmButtonClick = () => {
    const { current: deleteType } = deleteTypeRef;

    if (deleteType === PHOTO) {
      deletePhoto();
    }

    if (deleteType === COMMENT) {
      deleteComment();
    }

    handleCloseModal();
  };

  // *************** Effect handlers ***************

  useEffect(() => {
    if (photoIndex) {
      setCurrentIndex(+photoIndex);
      setInitialSlide(+photoIndex);
    }

    if (currentQuestionId) {
      getPhotosFromIndexedDB(currentQuestionId).then((photosFromDB) => {
        setPhotos(photosFromDB);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionId, photoIndex]);

  useEffect(() => {
    if (textareaRef.current) {
      const { current: textArea } = textareaRef;
      const textLength = textArea.value.length;

      placeCursorAtTheEnd(textArea, textLength);
    }
  }, [editMode]);

  useEffect(() => {
    return () => {
      currentComment.current = "";
    };
  }, []);

  return (
    <ContentContainer onClick={handleClickOutside}>
      <Container
        imageWidth={imageSize.width}
        imageHeight={imageSize.height}
        isMobile={isMobile}
        isTextareaFocused={isTextareaFocused}
      >
        <TopBar
          photos={photos}
          photosVisible={photosVisible}
          currentIndex={currentIndex}
          onClick={onBackArrowClick}
        />

        {photosVisible && (
          <Swiper
            pagination={{
              type: "progressbar",
            }}
            navigation
            rewind
            modules={[Pagination, Navigation]}
            onSlideChange={onSlideChange}
            initialSlide={initialSlide}
            spaceBetween={10}
          >
            {photos.map((photo, index) => (
              <SwiperSlide key={`preview-photo-${index}`}>
                <img
                  src={photo.src}
                  alt=''
                  className='preview-image'
                  onLoad={onImageLoad}
                />

                {!!photo.comment && !editMode && (
                  <>
                    <div className='comment' id='edit'>
                      {photo.comment}
                    </div>
                    <MoreIcon className='more-icon' onClick={onMoreIconClick} />
                  </>
                )}

                <CommentInput>
                  {!photo.comment && !commentTextAreaVisible && (
                    <CommentPlaceHolder>
                      <CommentReply onClick={onCommentPlaceholderClick}>
                        <span className='label'>{t("add-comment")}...</span>
                      </CommentReply>
                    </CommentPlaceHolder>
                  )}
                  {((!photo.comment && commentTextAreaVisible) ||
                    (commentTextAreaVisible && editMode)) && (
                    <CommentTextarea
                      placeholder={`${t("add-comment")}...`}
                      value={textareaValue}
                      onChange={({ target: { value } }) => {
                        setTextareaValue(value);
                      }}
                      autoFocus={index === currentIndex}
                      onFocus={onTextareaFocus}
                    />
                  )}
                </CommentInput>
              </SwiperSlide>
            ))}

            <BinContainer
              imageWidth={imageSize.width}
              isMobile={isMobile}
              onClick={onBinIconClick}
            >
              <BinIcon className='bin' />
            </BinContainer>
          </Swiper>
        )}

        {buttonsVisible && (
          <CommentButtons isMobile={isMobile}>
            <ButtonContainer>
              {isDictaphoneAccessible && (
                <Dictaphone
                  questionType={FILE_PHOTO}
                  answerTextAreaValue={currentComment.current}
                  onTextTranscriptChange={onTextTranscriptChange}
                  mini
                />
              )}
            </ButtonContainer>

            <CancelSaveButtons>
              <ButtonContainer>
                <Button
                  customClass={`comment-btn ${!isListening ? "cancel" : ""} `}
                  label={t("common:button.cancel")}
                  noShaddow
                  onClick={onCancelButtonClick}
                  disabled={isListening}
                />
              </ButtonContainer>
              <ButtonContainer>
                <Button
                  customClass='comment-btn'
                  label={t("common:button.save")}
                  noShaddow
                  onClick={onSaveButtonClick}
                  disabled={isListening}
                />
              </ButtonContainer>
            </CancelSaveButtons>
          </CommentButtons>
        )}

        {noPhotosVisible && <NoPhotos onClick={onBackArrowClick} />}

        {menuOpen && (
          <PopUpMenuContainer imageHeight={imageSize.height}>
            <PopUpMenuContent>
              <CloseIcon className='close-icon' onClick={onClosIconClick} />
              <MenuOption id='edit' onClick={onMenuOptionClick}>
                {t("common:menu.edit")}
              </MenuOption>
              <MenuOption id='delete' onClick={onMenuOptionClick}>
                {t("common:menu.delete")}
              </MenuOption>
            </PopUpMenuContent>
          </PopUpMenuContainer>
        )}
      </Container>

      <ConfirmationModal
        message={message}
        onClick={onConfirmButtonClick}
        onClose={handleCloseModal}
        open={open}
        buttonLabel={t("common:menu.delete")}
      />
    </ContentContainer>
  );
}

export default PhotoGallery;
