import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const getCurrentPathFromLocalStorage = () => localStorage.getItem("currentPath") ?? "";

function useCurrentPath() {
  const [currentPath, setCurrentPath] = useState(getCurrentPathFromLocalStorage());
  const { pathname } = useLocation();

  useEffect(() => {
    if (currentPath) {
      if (
        pathname !== "/home" &&
        pathname !== "/enter-serial-number" &&
        pathname !== "/production-server-warning"
      ) {
        localStorage.setItem("currentPath", currentPath);
      }
    }
  }, [currentPath, pathname]);

  return { currentPath, setCurrentPath };
}

export default useCurrentPath;
