import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { CreatedTask } from "types/tasks.types";

import { isMobile } from "utils/utils";
import {
  NoTasks,
  StatusLabelConatiner,
  TaskItem,
  TaskItemDate,
  TaskItemFooter,
  TaskItemHeader,
  TasksList as Tasks,
} from "../../../../styles/tasks.styles";
import { getStatusOptions, getTaskStatusLabel } from "utils/tasks-utils";
import { getTranslations } from "../../../../translations/tasks.translations";

import Placeholders from "../../../Placeholders";
import ErrorAlert from "components/molecules/ErrorAlert";

type TasksListProps = {
  tasksList: CreatedTask[];
  onItemClick: (id: string) => () => void;
  placeholdersVisible: boolean;
  isError: boolean;
};

function TasksList({
  tasksList,
  onItemClick,
  placeholdersVisible,
  isError,
}: TasksListProps) {
  const {
    i18n: { language },
  } = useTranslation();
  const statusOptions = getStatusOptions(language, { iconOnly: true });
  const { alert } = getTranslations(language);

  let content = null;

  content = tasksList.length ? (
    <Tasks>
      {tasksList.map((task) => {
        const { id, Title, date_created } = task;
        const statusLabel = getTaskStatusLabel(task, statusOptions);

        return (
          <TaskItem key={id} isMobile={isMobile} onClick={onItemClick(id)}>
            <TaskItemHeader>{Title}</TaskItemHeader>
            <TaskItemFooter>
              <TaskItemDate>
                <span>{format(new Date(date_created), "dd-MM-yyyy HH:mm")}</span>
              </TaskItemDate>
              <StatusLabelConatiner>{statusLabel}</StatusLabelConatiner>
            </TaskItemFooter>
          </TaskItem>
        );
      })}
    </Tasks>
  ) : (
    <NoTasks>{alert.info.noTasks}</NoTasks>
  );

  if (placeholdersVisible) {
    content = <Placeholders />;
  }

  if (isError) {
    content = <ErrorAlert message={alert.error.unableToLoad} />;
  }

  return content;
}

export default TasksList;
