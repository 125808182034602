import shutter from "assets/audio/shutter.mp3";

import { isIOS } from "utils/utils";

function useShutterSound() {
  const audio = !isIOS ? new Audio(shutter) : null;
  if (audio) audio.volume = 0.1;

  const playShutterSound = () => {
    if (audio) audio.play();
  };

  return { playShutterSound };
}

export default useShutterSound;
