import { useTranslation } from "react-i18next";

// import { StatusType } from "../types";
import styled from "styled-components/macro";

import { CountryCodes, EntitiesEnum } from "types/types";

const Container = styled.div`
  .header {
    font-size: 1.7rem;
    font-weight: 700;
  }

  .question {
    font-size: 1.8rem;
    margin-top: 1rem;
  }
`;

const { MACHINE, PROCESS } = EntitiesEnum;

const getHeaderTranslation = (language: string, entity: EntitiesEnum | undefined) => {
  const isEnglish = language.includes(CountryCodes.EN);
  const isPolish = language.includes(CountryCodes.PL);

  let header = "";

  if (isEnglish) {
    if (entity === MACHINE) {
      header = "Machine is out of order.";
    }
    if (entity === PROCESS) {
      header = "Process is out of order.";
    }
  }

  if (isPolish) {
    if (entity === MACHINE) {
      header = "Urządzenie niezdatne do użytku.";
    }
    if (entity === PROCESS) {
      header = "Proces niezdatny do użytku.";
    }
  }

  return header;
};

function Message({ entity }: { entity: EntitiesEnum | undefined }) {
  const {
    t,
    i18n: { language },
  } = useTranslation("questionnaires");

  return (
    <Container>
      <div className='header'>{getHeaderTranslation(language, entity)}</div>
      <div className='question'>{t("modal.question.complete-questionnaire")}</div>
    </Container>
  );
}

export default Message;
