import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { isMobile } from "utils/utils";
import { getTranslations } from "./translations/stepper.translations";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

const StepContainer = styled.div<{ isOneStepMissing: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:not(:last-child) {
    flex-basis: ${({ isOneStepMissing }) => (isOneStepMissing ? "85%" : "40%")};
  }
`;

const CircleLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const Circle = styled.div<{ active: boolean; isMobile: boolean }>`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primary_200};
  z-index: ${({ theme }) => theme.level1};
  color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.primary};
    `}
`;

const Label = styled.div<{ number?: boolean }>`
  font-size: ${({ number }) => (number ? "1.6rem" : "1.4rem")};
`;

const Line = styled.div<{ active: boolean }>`
  height: 0.2rem;
  width: calc(100% - 1rem);
  background-color: ${({ theme }) => theme.primary_200};

  position: absolute;
  top: calc(50% - 3.5rem);
  left: 3rem;
  transform: translateY(calc(50% + 2.2rem));

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.primary};
    `}
`;

type StepperProps = {
  step: number;
  onStepperCircleClick: (step: string) => void;
  hasPermissionToUpdateStatus: boolean;
  hasPermissionToCreateTask: boolean;
};

function Stepper({
  step,
  onStepperCircleClick,
  hasPermissionToUpdateStatus,
  hasPermissionToCreateTask,
}: StepperProps) {
  const {
    i18n: { language },
  } = useTranslation();
  const { labels } = getTranslations(language);

  const isOneStepMissing = !hasPermissionToUpdateStatus || !hasPermissionToCreateTask;

  const onClick = (step: number) => () => {
    onStepperCircleClick(`${step}`);
  };

  return (
    <Container>
      {hasPermissionToUpdateStatus && (
        <StepContainer isOneStepMissing={isOneStepMissing}>
          <CircleLabelContainer>
            <Circle
              isMobile={isMobile}
              onClick={onClick(1)}
              active={step === 1 || step === 2 || step === 3}
            >
              <Label number>1</Label>
            </Circle>
            <Label>{labels.status}</Label>
          </CircleLabelContainer>
          <Line active={step === 2 || step === 3} />
        </StepContainer>
      )}

      {hasPermissionToCreateTask && (
        <StepContainer isOneStepMissing={isOneStepMissing}>
          <CircleLabelContainer>
            <Circle
              isMobile={isMobile}
              onClick={!hasPermissionToUpdateStatus ? onClick(1) : onClick(2)}
              active={
                !hasPermissionToUpdateStatus
                  ? step === 1 || step === 2
                  : step === 2 || step === 3
              }
            >
              <Label number>{!hasPermissionToUpdateStatus ? "1" : "2"}</Label>
            </Circle>
            <Label>{labels.task}</Label>
          </CircleLabelContainer>
          <Line active={!hasPermissionToUpdateStatus ? step === 2 : step === 3} />
        </StepContainer>
      )}

      <StepContainer isOneStepMissing={isOneStepMissing}>
        <CircleLabelContainer>
          <Circle
            isMobile={isMobile}
            onClick={
              !hasPermissionToUpdateStatus || !hasPermissionToCreateTask
                ? onClick(2)
                : onClick(3)
            }
            active={
              !hasPermissionToUpdateStatus || !hasPermissionToCreateTask
                ? step === 2
                : step === 3
            }
          >
            <Label number>
              {!hasPermissionToUpdateStatus || !hasPermissionToCreateTask ? "2" : "3"}
            </Label>
          </Circle>
          <Label>{labels.end}</Label>
        </CircleLabelContainer>
      </StepContainer>
    </Container>
  );
}

export default Stepper;
