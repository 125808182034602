import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let message = {
    top: "You have unsaved changes.",
    bottom: "Are you sure you want to quit editing task?",
  };

  if (isPolish) {
    message = {
      top: "Masz niezapisane zmiany.",
      bottom: "Czy na pewno chcesz zakończyć edycję zadania?",
    };
  }

  return { message };
};
