import { CountryCodes } from "types/types";

export const isProperLoginUrl = (url: string) =>
  ["/qr-login", "Personal_token"].every((item) => url.includes(item));

export const getPathFromUrl = (url: string) => {
  let path = "";

  if (url.includes("#")) path = url.split("#")[1];
  if (url.includes("£")) path = url.split("£")[1];

  return path;
};

export const getLabelTranslation = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let label = "Scan code";

  if (isPolish) {
    label = "Zeskanuj kod";
  }

  return { label };
};

export const convertToBoolean = (boolString: string | null) => {
  if (!boolString) return false;

  return /true/.test(boolString);
};
