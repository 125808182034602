import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import Modal from "components/templates/Modal";
import Button from "components/atoms/Button";

import { ReactComponent as UpdateIcon } from "assets/icons/update.svg";

import { ModalStyles } from "styles/generalStyles";

const { Content, Message, Actions } = ModalStyles;

const MessageContent = styled.div<{ updating: boolean }>`
  font-size: 1.8rem;
  font-family: GothamBold;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  .update-icon {
    width: 6rem;

    ${({ updating }) =>
      updating &&
      css`
        ${({ theme }) => theme.rotate};
      `}
  }

  .update-title {
    line-height: 1;
  }
`;

const Divider = styled.div`
  margin-bottom: 4rem;
`;

type UpdatePopUpProps = {
  open: boolean;
  updating: boolean;
  onClick: () => void;
  onClose?: () => void;
};

function UpdatePopUp({ open, updating, onClick, onClose }: UpdatePopUpProps) {
  const { t } = useTranslation("common");

  const label = updating
    ? t("update-popup.button.label.updating")
    : t("update-popup.button.label.update");

  return (
    <Modal onClose={onClose} open={open}>
      <Content>
        <Message>
          <MessageContent updating={updating}>
            <UpdateIcon className='update-icon' />
            <span className='update-title'>{t("update-popup.title")}</span>
          </MessageContent>
        </Message>

        <Divider />
        <Actions>
          <Button disabled={updating} onClick={onClick} label={label} />
        </Actions>
      </Content>
    </Modal>
  );
}

export default UpdatePopUp;
