import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let labels = {
    uploading: "Uploading",
    waitingForServerResponse: "Waiting for server response...",
  };

  if (isPolish) {
    labels = {
      uploading: "Wysyłam",
      waitingForServerResponse: "Czekam na odpowiedź serwera...",
    };
  }

  return { labels };
};
