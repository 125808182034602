import { Dispatch, SetStateAction } from "react";
import { FilesGallery, FormType } from "types/types";

import useQueryString from "./useQueryString";
import usePhotosObjectStore from "./usePhotosObjectStore";

type UsePhotoCommentProps = {
  photos: FilesGallery;
  setPhotos: Dispatch<SetStateAction<FilesGallery>>;
  currentIndex: number;
  textareaValue: string;
};

function usePhotoComment({
  photos,
  setPhotos,
  currentIndex,
  textareaValue,
}: UsePhotoCommentProps) {
  const query = useQueryString();
  const formType = query.get("formType") as FormType;
  const currentQuestionId = query.get("currentQuestionId");

  const { updatePhotosInIndexedDB } = usePhotosObjectStore(formType);

  // *************** Update comment ***************

  const updateComment = (comment: string) => {
    if (!currentQuestionId) return;

    const updatedFilesGallery = photos;

    const updatedPhotoObject = {
      ...updatedFilesGallery[currentIndex],
      comment,
    };
    updatedFilesGallery[currentIndex] = updatedPhotoObject;

    updatePhotosInIndexedDB({
      FilesGallery: updatedFilesGallery,
      Question: currentQuestionId,
    });

    setPhotos(updatedFilesGallery);
  };

  // *************** Save comment ***************

  const saveComment = () => {
    updateComment(textareaValue);
  };

  // *************** Delete comment ***************

  const deleteComment = () => {
    updateComment("");
  };

  return { saveComment, deleteComment };
}

export default usePhotoComment;
