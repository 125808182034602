import { FormTypes } from "types/types";
import usePhotosObjectStore from "./usePhotosObjectStore";

const { ACCIDENT } = FormTypes;

function useClearObjectStoreAccident() {
  const { deleteAllPhotosInIndexedDB: deleteAccidentPhotos } =
    usePhotosObjectStore(ACCIDENT);

  return { deleteAccidentPhotos };
}

export default useClearObjectStoreAccident;
