import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let labels = {
    status: "Status",
    task: "Task",
    end: "Exit",
  };

  if (isPolish) {
    labels = {
      status: "Status",
      task: "Zadanie",
      end: "Koniec",
    };
  }

  return { labels };
};
