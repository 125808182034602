import { useEffect, useState } from "react";

import { FilesGalleryRecord, FormType, PhotoObjectStoreItem } from "types/types";
import usePhotosObjectStore from "./usePhotosObjectStore";

function usePhotosForAnswers(formType: FormType) {
  const [photosForAnswers, setPhotosForAnswers] = useState<FilesGalleryRecord>();
  const { getAllPhotosFromIndexedDB } = usePhotosObjectStore(formType);

  useEffect(() => {
    (async () => {
      const allPhotosInIndexedDB =
        (await getAllPhotosFromIndexedDB()) as PhotoObjectStoreItem[];

      const filesGalleryRecords = allPhotosInIndexedDB.reduce(
        (acc: FilesGalleryRecord, { Question, FilesGallery }: PhotoObjectStoreItem) => {
          return { ...acc, [Question]: FilesGallery };
        },
        {},
      );

      setPhotosForAnswers(filesGalleryRecords);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { photosForAnswers };
}

export default usePhotosForAnswers;
