import { useNavigate } from "react-router";

function useSerialNumberPath() {
  const navigate = useNavigate();

  const handleSerialNumberPath = (serialNumber: string) => {
    const serialNumberPath = `/check-lists?SerialNo=${serialNumber}`;
    localStorage.setItem("serialNumberPath", serialNumberPath);

    navigate(serialNumberPath);
  };

  return handleSerialNumberPath;
}

export default useSerialNumberPath;
