import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { device } from "utils/utils";

import stopSign from "assets/icons/stop-sign-48.png";

import Button from "components/atoms/Button";

import { ModalStyles } from "styles/generalStyles";
import { ActionsWrapper } from "../styles/summaryStyles";
import { CountryCodes } from "types/types";

const Container = styled.div`
  margin-top: 1.5rem;
  margin-left: 1rem;
  ${({ theme }) => theme.fillUpRemainingSpace}

  .header {
    margin-bottom: 2rem;
    font-family: GothamBold;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.8rem;

    @media screen and (min-height: 616px) {
      font-size: 2rem;
    }

    @media ${device.iPad} {
      justify-content: center;
      font-size: 2.2rem;
    }
  }

  .info {
    padding-bottom: 2rem;
    border-bottom: 1px solid ${({ theme }) => theme.primary};

    @media screen and (min-height: 616px) {
      margin-bottom: 3rem;
    }

    &-top {
      font-family: GothamBold;
      margin-bottom: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      text-align: center;
      font-size: 2rem;
    }

    &-bottom {
      font-size: 1.9rem;
      margin-top: 1.5rem;
      text-align: center;
    }

    @media ${device.iPad} {
      font-size: 1.8rem;
    }
  }

  .warning-icon {
    width: 3rem;
    fill: ${({ theme }) => theme.warningColor};
  }
`;

const StopSign = styled.img`
  width: 6rem;
  height: 6rem;
`;

const { Actions, ButtonContainer } = ModalStyles;

type ReportIncompleteInfoProps = {
  onClick: () => void;
  openModal: () => void;
  visible: boolean;
};

function ReportIncompleteInfo({
  onClick,
  openModal,
  visible,
}: ReportIncompleteInfoProps) {
  const { t, i18n } = useTranslation("summary");

  const btnCompleteLabel = i18n.language.includes(CountryCodes.EN)
    ? "Complete"
    : "Uzupełnij";

  return visible ? (
    <Container>
      <div className='info'>
        <div className='info-top'>
          <StopSign src={stopSign} alt='' />
          <span>{t("incomplete-report-message.top")}</span>
        </div>
        <div className='info-bottom'>{t("incomplete-report-message.bottom")}</div>
      </div>

      <ActionsWrapper>
        <Actions spaceBetween>
          <ButtonContainer double>
            <Button label={t("button.discard")} question onClick={openModal} />
          </ButtonContainer>

          <ButtonContainer middle>
            <Button
              onClick={onClick}
              label={btnCompleteLabel}
              //  label={t("button.complete")}
            />
          </ButtonContainer>
        </Actions>
      </ActionsWrapper>
    </Container>
  ) : null;
}

export default ReportIncompleteInfo;
