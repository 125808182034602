import {
  Af,
  Qr,
  AccidentForm,
  Answer,
  MachineProcess,
  QuestionnaireObject,
} from "types/types";
import { ActionType, InitialState } from "../types";
import { getLocalData } from "contexts/utils/utils";

enum Keys {
  ANSWERS = "answers",
  ACCIDENT_ANSWERS = "accidentAnswers",
}

const { ANSWERS, ACCIDENT_ANSWERS } = Keys;

const getAnswersFromLocalStorage = (key: Keys) => {
  const localData = localStorage.getItem(key);
  return localData ? JSON.parse(localData) : [];
};

export const initialState: InitialState = {
  questionnaires: [],
  serialNumber: getLocalData("serialNumber"),
  machineProcess: getLocalData("machineProcess"),
  selectedQuestionnaire: getLocalData("selectedQuestionnaire"),
  startTime: new Date(),
  accidentForms: undefined,
  selectedAccidentForm: getLocalData("selectedAccidentForm"),
  accidentStartTime: new Date(),
  answers: getAnswersFromLocalStorage(ANSWERS),
  accidentAnswers: getAnswersFromLocalStorage(ACCIDENT_ANSWERS),
};

// ----- Action types -----

type ClearWholeStateAction = {
  type: ActionType.CLEAR_WHOLE_STATE;
};

type ClearQuestionnaireStateAction = {
  type: ActionType.CLEAR_QUESTIONNAIRE_STATE;
};

type SetQuestionnairesAction = {
  type: ActionType.SET_QUESTIONNAIRES;
  payload: QuestionnaireObject[];
};

type SetSerialNumberAction = {
  type: ActionType.SET_SERIAL_NUMBER;
  payload: string;
};

type SetMachineProcess = {
  type: ActionType.SET_MACHINE_PROCESS;
  payload: MachineProcess;
};

type SetSelectedQuestionnaire = {
  type: ActionType.SET_SELECTED_QUESTIONNAIRE;
  payload: Qr;
};

type SetStartTime = {
  type: ActionType.SET_START_TIME;
  payload: Date;
};

type SetAccidentForms = {
  type: ActionType.SET_ACCIDENT_FORMS;
  payload: AccidentForm[] | undefined;
};

type SetSelectedAccidentForm = {
  type: ActionType.SET_SELECTED_ACCIDENT_FORM;
  payload: Af;
};

type SetAccidentStartTime = {
  type: ActionType.SET_ACCIDENT_START_TIME;
  payload: Date;
};

type SetAnswers = {
  type: ActionType.SET_ANSWERS;
  payload: Answer[];
};

type SetAccidentAnswers = {
  type: ActionType.SET_ACCIDENT_ANSWERS;
  payload: Answer[];
};

// ----- All actions -----

type Actions =
  | ClearWholeStateAction
  | ClearQuestionnaireStateAction
  | SetQuestionnairesAction
  | SetSerialNumberAction
  | SetMachineProcess
  | SetSelectedQuestionnaire
  | SetStartTime
  | SetAccidentForms
  | SetSelectedAccidentForm
  | SetAccidentStartTime
  | SetAnswers
  | SetAccidentAnswers;

export const reducer = (state: InitialState, action: Actions) => {
  switch (action.type) {
    case ActionType.CLEAR_WHOLE_STATE:
      return {
        questionnaires: [],
        serialNumber: "",
        machineProcess: { Machine: null, Process: null },
        selectedQuestionnaire: undefined,
        startTime: new Date(),
        accidentForms: undefined,
        selectedAccidentForm: undefined,
        accidentStartTime: new Date(),
        answers: [],
        accidentAnswers: [],
      };
    case ActionType.CLEAR_QUESTIONNAIRE_STATE:
      return {
        questionnaires: [],
        serialNumber: "",
        machineProcess: { Machine: null, Process: null },
        selectedQuestionnaire: undefined,
        startTime: new Date(),
        accidentForms: state.accidentForms,
        selectedAccidentForm: undefined,
        accidentStartTime: new Date(),
        answers: [],
        accidentAnswers: [],
      };
    case ActionType.SET_QUESTIONNAIRES:
      return {
        ...state,
        questionnaires: action.payload,
      };
    case ActionType.SET_SERIAL_NUMBER:
      return {
        ...state,
        serialNumber: action.payload,
      };
    case ActionType.SET_MACHINE_PROCESS:
      return {
        ...state,
        machineProcess: action.payload,
      };
    case ActionType.SET_SELECTED_QUESTIONNAIRE:
      return {
        ...state,
        selectedQuestionnaire: action.payload,
      };
    case ActionType.SET_START_TIME:
      return {
        ...state,
        startTime: action.payload,
      };
    case ActionType.SET_ACCIDENT_FORMS:
      return {
        ...state,
        accidentForms: action.payload,
      };
    case ActionType.SET_SELECTED_ACCIDENT_FORM:
      return {
        ...state,
        selectedAccidentForm: action.payload,
      };
    case ActionType.SET_ACCIDENT_START_TIME:
      return {
        ...state,
        accidentStartTime: action.payload,
      };
    case ActionType.SET_ANSWERS:
      return {
        ...state,
        answers: action.payload,
      };
    case ActionType.SET_ACCIDENT_ANSWERS:
      return {
        ...state,
        accidentAnswers: action.payload,
      };

    default:
      return state;
  }
};
