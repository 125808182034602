import { useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import UserContext from "contexts/user-context/UserContext";
import useCurrentPath from "hooks/useCurrentPath";
import useModal from "hooks/useModal";
import { clearLocalStorage } from "utils/utils";
import { CountryCodes } from "types/types";

import {
  ContentContainer,
  EnterSnBtnContainer,
  OptionsContainer,
  OptionLabel,
  Option,
} from "styles/generalStyles";

import Placeholders from "pages/check-lists/components/Placeholders";
import ErrorAlert from "components/molecules/ErrorAlert";
import AccidentsList from "components/molecules/AccidentsList";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import Message from "./components/Message";

function Home() {
  const {
    state: { accidentForms },
    setAnswersAction,
    setAccidentAnswersAction,
    setAccidentStartTimeAction,
    setQuestionnairesAction,
    setAccidentFormsAction,
    setSelectedQuestionnaireAction,
    setSelectedAccidentFormAction,
    accidentFormsLoading,
    accidentFormsError: isError,
    fetchAccidentForms,
  } = useContext(QuestionnairesContext);
  const {
    user,
    isLoading: userDataLoading,
    labels: { serialNumberTitle },
  } = useContext(UserContext);

  const navigate = useNavigate();
  const { currentPath, setCurrentPath } = useCurrentPath();
  const { open, openModal, closeModal } = useModal();
  const { t, i18n } = useTranslation(["home", "common"]);

  const headerLabel = i18n.language?.includes(CountryCodes.EN) ? "Enter" : "Wprowadź";

  const userIdInLocalStorage = localStorage.getItem("userId");

  const isLoading = accidentFormsLoading || userDataLoading;

  let content = null;

  const setUserIdInLocalStorage = (id: string) => {
    localStorage.setItem("userId", id);
  };

  const redirectToCurrentPath = (path: string) => () => {
    navigate(path);
  };

  // --------------- Callbacks ---------------

  const isUserInLocalStorage = useCallback(
    () => !!userIdInLocalStorage,
    [userIdInLocalStorage],
  );

  const isTheSameUser = useCallback(
    () => (user && userIdInLocalStorage ? user.id === userIdInLocalStorage : false),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  const clearStateAndLocalStorage = useCallback(
    (leaveAccidentForms?: boolean) => {
      setAnswersAction([]);
      setAccidentAnswersAction([]);
      setQuestionnairesAction([]);
      setSelectedQuestionnaireAction(undefined);
      if (!leaveAccidentForms) {
        setAccidentFormsAction([]);
      }
      clearLocalStorage();

      return Promise.resolve();
    },
    [
      setAnswersAction,
      setAccidentAnswersAction,
      setQuestionnairesAction,
      setAccidentFormsAction,
      setSelectedQuestionnaireAction,
    ],
  );

  const handleUserData = useCallback(
    (userId: string, leaveAccidentForms?: boolean) => {
      clearStateAndLocalStorage(leaveAccidentForms).then(() => {
        setUserIdInLocalStorage(userId);
      });
    },
    [clearStateAndLocalStorage],
  );

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (!user) {
      return;
    }

    if (isUserInLocalStorage()) {
      if (currentPath && currentPath.includes("/questionnaire")) {
        if (isTheSameUser()) {
          openModal();
        } else {
          handleUserData(user.id);
        }
      } else {
        setUserIdInLocalStorage(user.id);
      }
    } else {
      setUserIdInLocalStorage(user.id);
    }
  }, [user, currentPath, isTheSameUser, isUserInLocalStorage, handleUserData, openModal]);

  // --------------- On click handlers ---------------

  const onOptionClick = () => {
    navigate("/serial-number-method-selection");
  };

  const onAlarmClick = (id: string) => () => {
    const chosenForm = accidentForms?.find((form) => form.id === id);

    setSelectedAccidentFormAction(chosenForm);
    setAccidentStartTimeAction(new Date());
    navigate("/accident-form/1");

    localStorage.setItem("selectedAccidentForm", JSON.stringify(chosenForm));
    localStorage.setItem("returnPath", "/home");
  };

  const onNoClick = () => {
    if (user) {
      handleUserData(user.id, true);
    }
    setCurrentPath("/home");
    closeModal();
  };

  // --------------- Content ---------------

  if (accidentForms) {
    content = <AccidentsList accidentForms={accidentForms} onClick={onAlarmClick} />;
  }

  if (isLoading) {
    content = <Placeholders />;
  }

  if (isError) {
    content = (
      <ErrorAlert
        onClick={fetchAccidentForms}
        message={t("error.message", { ns: "home" })}
      />
    );
  }

  return (
    <ContentContainer>
      <EnterSnBtnContainer>
        {isLoading ? (
          <Placeholders single />
        ) : (
          <Option onClick={onOptionClick} large>
            <OptionLabel>
              {headerLabel}&nbsp;{serialNumberTitle}
            </OptionLabel>
          </Option>
        )}
      </EnterSnBtnContainer>

      <OptionsContainer marginTop>{content}</OptionsContainer>

      <ConfirmationModal
        message={<Message />}
        onClick={redirectToCurrentPath(currentPath)}
        onNoClick={onNoClick}
        open={open}
        buttonLabel={t("button.yes", { ns: "common" })}
        noButtonLabel={t("button.no", { ns: "common" })}
        double
      />
    </ContentContainer>
  );
}

export default Home;
