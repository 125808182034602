import styled from "styled-components/macro";

import { MachineProcessStatus, MachineProcessStatuses } from "types/types";
import { statusColors } from "utils/utils";

const { PUBLISHED, OUT_OF_ORDER } = MachineProcessStatuses;

export const Container = styled.div`
  margin-top: 0.5rem;

  @media screen and (min-height: 568px) {
    margin-top: 1rem;
  }
`;

export const dot = (status: MachineProcessStatus) => {
  let backgroundColor;

  if (status === PUBLISHED) {
    backgroundColor = statusColors[PUBLISHED];
  }

  if (status === OUT_OF_ORDER) {
    backgroundColor = statusColors[OUT_OF_ORDER];
  }

  return {
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 14,
      width: 14,
    },
  };
};
