import { createGlobalStyle } from "styled-components/macro";
import { normalize } from "styled-normalize";
import AuraAspect from "assets/fonts/AuraAspect/AuraAspectBold.otf";
import GothamBook from "assets/fonts/Gotham/Gotham-Book.otf";
import GothamBold from "assets/fonts/Gotham/Gotham-Bold.otf";
import GothamThin from "assets/fonts/Gotham/Gotham-Thin.otf";

export default createGlobalStyle`
  ${normalize}

  @font-face {
    font-family: AuraAspect;
    font-style: normal;
    src: url(${AuraAspect});
  }

  @font-face {
    font-family: GothamBook;
    font-style: normal;
    src: url(${GothamBook});
  }

  @font-face {
    font-family: GothamBold;
    font-style: normal;
    src: url(${GothamBold});
  }

  @font-face {
    font-family: GothamThin;
    font-style: normal;
    src: url(${GothamThin});
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    scroll-behavior: smooth;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
    
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    font-family: 'Gotham', serif;
    overflow: hidden;
    font-family: GothamBook;
    background-color: #C8D6E5;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: 'Aura', sans-serif;
  }

  p {
    margin: 0;
  }
  
  button {
    padding: 0;
    cursor: pointer;
    font-family: 'Gotham', serif;
  }
  
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  fieldset {
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-block-start: 0;
    padding-block-end: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    border: none;
    min-inline-size: min-content;
}


  ::-webkit-scrollbar {
    width: 7px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #212F5B;
    border-radius: 0.4rem;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

`;
