import styled from "styled-components/macro";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { device } from "utils/utils";
import useLogout from "hooks/useLogout";
import useHandleBackToHomePage from "hooks/useHandleBackToHomePage";
import { getTranslations } from "./translations/indexedDBError.translations";

import { ReactComponent as ErrorIcon } from "assets/icons/error-2.svg";
import Button from "components/atoms/Button";

import { ContentContainer } from "styles/generalStyles";

const Container = styled.div`
  padding-top: 2rem;
  ${({ theme }) => theme.fillUpRemainingSpace}
`;

const InfoForUser = styled.div`
  margin-bottom: 3rem;
`;

const Top = styled.div`
  text-align: center;
  font-family: GothamBold;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.errorColor};
`;

const Bottom = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-family: GothamBold;
`;

const ErrorMessageContainer = styled.div`
  background-color: #202020;
  color: ${({ theme }) => theme.textColor1};
  border-radius: 0.4rem;
  border: 4px solid ${({ theme }) => theme.errorColor};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${({ theme }) => theme.textColor1};
  background-color: ${({ theme }) => theme.errorColor};
  padding-bottom: 0.25rem;
  font-size: 1.3rem;

  .icon {
    width: 1.8rem;
    height: 1.8rem;
    fill: ${({ theme }) => theme.textColor1};
    transform: translateY(-0.1rem);
  }
`;

const ErrorMessage = styled.div`
  padding: 1rem;
  font-size: 1.2rem;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding-bottom: 1rem;

  @media ${device.iPad} {
    flex-direction: row-reverse;
    gap: 1rem;
  }
`;

const ButtonContainer = styled.div`
  &:not(:nth-of-type(2)) {
    margin-bottom: 1rem;
  }

  @media ${device.iPad} {
    flex-basis: 50%;
  }
`;

function IndexedDbError() {
  const [searchParams] = useSearchParams();
  const { handleLogOut } = useLogout();
  const { handleBackToHomePage } = useHandleBackToHomePage();
  const {
    i18n: { language },
  } = useTranslation();
  const { infoForUser, buttonLabels } = getTranslations(language);
  const errorMessage = searchParams.get("message");

  const onBackToHomePageBtnClick = () => {
    handleBackToHomePage();
  };

  const onSignOutBtnClick = () => {
    handleLogOut();
  };

  return (
    <ContentContainer>
      <Container>
        <InfoForUser>
          <Top>{infoForUser.top}</Top>
          <Bottom>{infoForUser.bottom}</Bottom>
        </InfoForUser>

        <ErrorMessageContainer>
          <Header>
            <ErrorIcon className='icon' />
            {infoForUser.header}
          </Header>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </ErrorMessageContainer>

        <Actions>
          <ButtonContainer>
            <Button
              label={buttonLabels.backToHomePage}
              onClick={onBackToHomePageBtnClick}
              question
            />
          </ButtonContainer>
          <ButtonContainer>
            <Button label={buttonLabels.singOut} onClick={onSignOutBtnClick} />
          </ButtonContainer>
        </Actions>
      </Container>
    </ContentContainer>
  );
}

export default IndexedDbError;
