import { useContext, useState } from "react";
import { getCookie } from "react-use-cookie";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { useLocation } from "react-router-dom";

import { getUserPermissions } from "api/user";
import { ACCESS_TOKEN } from "utils/utils";
import { UserPermissionsResponse, Permission } from "types/permissions.types";

import useNotification from "hooks/useNotification";
import AuthContext from "contexts/auth-context/AuthContext";

function usePermissions() {
  const { authenticated, shouldReAuth } = useContext(AuthContext);
  const [userPermissions, setUserPermissionsPermissions] = useState<Permission[]>([]);
  const token = getCookie(ACCESS_TOKEN);
  const { pathname } = useLocation();
  const enabled = authenticated && !pathname.includes("login") && !shouldReAuth;

  const notify = useNotification();

  const onSuccess = ({ data }: UserPermissionsResponse) => {
    setUserPermissionsPermissions(data);
  };

  const onError = ({ message }: AxiosError) => {
    notify(`Permissions: ${message}`, "error");
  };

  const { remove: removePermissionsQuery } = useQuery(
    "userPermissions",
    getUserPermissions(token),
    {
      onSuccess,
      onError,
      enabled,
    },
  );

  return { userPermissions, removePermissionsQuery };
}

export default usePermissions;
