import { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";

import useLogout from "hooks/useLogout";
import useRefreshSession from "hooks/useRefreshSession";
import AuthContext from "contexts/auth-context/AuthContext";

import Modal from "components/templates/Modal";
import Button from "components/atoms/Button";

import { ReactComponent as ExpiredIcon } from "assets/icons/expired.svg";

import { ModalStyles } from "styles/generalStyles";

const { Content, Actions, ButtonContainer } = ModalStyles;

const Container = styled.div`
  .expired-icon {
    width: 6rem;
    height: 4rem;
  }

  .button {
    font-size: 1.4rem;
  }

  .refresh {
    background-color: ${({ theme }) => theme.cobaltBlue};
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-family: GothamBold;
  margin-bottom: 3rem;
`;

function ReAuthModal() {
  const [open, setOpen] = useState(false);
  const { shouldReAuth, setShouldReAuth } = useContext(AuthContext);
  const {
    handleRefreshSession,
    isLoading,
    labels: { header, signOut, refreshSession },
  } = useRefreshSession();
  const { clearAllStates } = useLogout();

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const onSignOutBtnClick = async () => {
    setShouldReAuth(false);
    await clearAllStates();
    closeModal();
  };

  useEffect(() => {
    if (shouldReAuth) {
      openModal();
    } else {
      if (open) {
        closeModal();
      }
    }
  }, [shouldReAuth, open]);

  return (
    <Modal open={open}>
      <Container>
        <Content>
          <Title>
            <ExpiredIcon className='expired-icon' />
            <div>{header}</div>
          </Title>
          <Actions spaceBetween>
            <ButtonContainer middle>
              <Button
                onClick={handleRefreshSession}
                label={refreshSession}
                customClass='button refresh'
                disabled={isLoading}
              />
            </ButtonContainer>
            <ButtonContainer>
              <Button onClick={onSignOutBtnClick} label={signOut} customClass='button' />
            </ButtonContainer>
          </Actions>
        </Content>
      </Container>
    </Modal>
  );
}

export default ReAuthModal;
