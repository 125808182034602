import { useSpeechRecognition } from "react-speech-recognition";

import { isIOS, isAPK } from "utils/utils";

function useDictaphoneAccess() {
  const { browserSupportsSpeechRecognition } = useSpeechRecognition();
  const isDictaphoneAccessible = browserSupportsSpeechRecognition && !isIOS && !isAPK;

  return { isDictaphoneAccessible };
}

export default useDictaphoneAccess;
