import styled, { css } from "styled-components/macro";

export const Tabs = styled.div`
  margin-top: 1.5rem;
  display: flex;
`;

export const StyledTab = styled.div`
  flex-basis: 50%;
  padding-top: 1rem;
  padding-bottom: 1.4rem;
  text-align: center;
  font-family: GothamBold;
  font-size: 1.8rem;
  color: rgba(74, 90, 127, 0.6);
  background-color: rgba(185, 203, 222, 0.5);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`;

export const Tab = styled(StyledTab)<{
  active: boolean;
  isMobile: boolean;
  isBlocked?: boolean;
}>`
  transition: all 0.3s ease-in-out;
  cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};
  background-color: rgba(255, 255, 255, 0.4);

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.white};
      ${({ theme }) => theme.cardShadow};
    `}

  ${({ isBlocked }) =>
    isBlocked &&
    css`
      cursor: not-allowed;
    `}
`;

export const TabLabel = styled.span`
  display: inline-block;
  transform: translateY(0.2rem);
  user-select: none;
`;

export const SliderContainer = styled.div``;

export const Slider = styled.div<{ position: number }>`
  height: 0.4rem;
  background-color: ${({ theme }) => theme.white};
  width: 50%;
  transform: ${({ position }) => `translate(${position}%, -0.2rem)`};
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
`;
