import { getTime } from "date-fns";

import { CreatedTask } from "types/tasks.types";

export const sortByDateDesc = (data: CreatedTask[]) =>
  data
    .map((item) => ({
      ...item,
      date_created: getTime(new Date(item.date_created)),
    }))
    .sort((a, b) => b.date_created - a.date_created);
