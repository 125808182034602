import { CountryCodes } from "types/types";

export const getForgotPasswordTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  //   ----- ENGLISH -----

  let header = "Password reset";
  let label = "Enter your email";
  let button = { label: "Reset password" };

  let notify = {
    success: "An email with a password reset link has been sent to your account.",
    error: "Password reset failed...",
  };

  //   ----- POLISH -----

  if (isPolish) {
    header = "Reset hasła";
    label = "Podaj swój email";
    button = { label: "Resetuj hasło" };

    notify = {
      success: "Email z linkiem do resetu hasła został wysłany na Twoje konto.",
      error: "Nie udało się zresetować hasła...",
    };
  }

  return { header, label, button, notify };
};
