import { useEffect, useState } from "react";

type Device = {
  deviceId: string;
  kind: MediaDeviceKind;
  label: string;
  groupId: string;
};

function useDevices() {
  const [devices, setDevices] = useState<Device[]>([]);
  const [videoDevices, setVideoDevices] = useState<Device[]>();
  const [isVideoInput, setIsVideoInput] = useState(false);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((data) => {
      const devicesArray = data.map(({ deviceId, kind, label, groupId }) => ({
        deviceId,
        kind,
        label,
        groupId,
      }));
      const videoInputs = devicesArray.filter(({ kind }) => kind.includes("video"));

      setDevices(devicesArray);
      setVideoDevices(videoInputs);
    });
  }, []);

  useEffect(() => {
    if (videoDevices) {
      setIsVideoInput(!!videoDevices.length);
    }
  }, [videoDevices]);

  return {
    devices,
    videoDevices,
    isVideoInput,
  };
}

export default useDevices;
