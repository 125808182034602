import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components/macro";

import { ContentContainer, Header } from "styles/generalStyles";

import Button from "components/atoms/Button";
import BackArrow from "components/atoms/BackArrow";

const Title = styled.div``;

const CamerasList = styled.ul`
  margin-top: 1.5rem;
`;

const CameraItem = styled.li<{ border?: boolean }>`
  padding: 2rem;
  background-color: ${({ theme }) => theme.glacier};
  color: ${({ theme }) => theme.aliceBlue};
  border-radius: 0.4rem;
  transition: opacity 0.2s ease-out;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &:hover {
    opacity: 0.9;
  }

  ${({ border }) =>
    border &&
    css`
      border: 6px solid ${({ theme }) => theme.successColor};
    `}
`;

const ButtonContainer = styled.div`
  margin-top: 4.5rem;
`;

function CameraTest() {
  const navigate = useNavigate();

  return (
    <ContentContainer>
      <Header standardFont>
        <BackArrow
          onClick={() => {
            navigate(-1);
          }}
        />
        <span>TEST KAMERY</span>
      </Header>

      <Title>Wybierz bibliotekę:</Title>

      <CamerasList>
        <CameraItem
          onClick={() => {
            navigate("/camera-test/react-webcam");
          }}
        >
          react-webacm
        </CameraItem>
        <CameraItem
          onClick={() => {
            navigate("/camera-test/react-camera-ios");
          }}
        >
          react-camera-ios
        </CameraItem>
        <CameraItem
          onClick={() => {
            navigate("/camera-test/react-html5-camera-photo");
          }}
          border
        >
          react-html5-camera-photo
        </CameraItem>
      </CamerasList>

      <ButtonContainer>
        <Button
          label='Odśwież stronę'
          question
          noShaddow
          onClick={() => {
            window.location.reload();
          }}
        />
      </ButtonContainer>
    </ContentContainer>
  );
}

export default CameraTest;
