import { useState, useEffect } from "react";

function useNetworkStatus() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleNetworkStatusChange = (status: boolean) => {
      setIsOnline(status);
    };

    window.addEventListener("online", (e) => {
      handleNetworkStatusChange(true);
    });
    window.addEventListener("offline", (e) => {
      handleNetworkStatusChange(false);
    });

    return () => {
      window.removeEventListener("online", (e) => {
        handleNetworkStatusChange(true);
      });
      window.removeEventListener("offline", (e) => {
        handleNetworkStatusChange(false);
      });
    };
  }, []);

  return { isOnline };
}

export default useNetworkStatus;
