import { instance } from "axios-instance/axios-instance";
import { AccidentFormResponse } from "types/types";

export const getAccidentForms = (token: string) => async () => {
  const {
    data: { data },
  } = await instance.get<AccidentFormResponse>(
    "/items/AccidentForm?sort=Department.DepartmentName,Title&fields=id,RandomOrder,Title,Subtitle,Colour,Department.DepartmentName,Department.id,Questions.*",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return data;
};
