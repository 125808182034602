import styled, { css } from "styled-components/macro";
import { useLocation } from "react-router-dom";

import { Domains, Environments } from "types/environment.types";
import {
  domain,
  environment,
  server,
  target,
  serverWarnLabel,
} from "utils/environment.utils";
import { fallbackURL, baseURL } from "axios-instance/axios-instance";

import { ReactComponent as Logo } from "assets/logos/SVG/SnapCheck-02.svg";
import { ReactComponent as BugIcon } from "assets/icons/bug.svg";
import { ReactComponent as EmergencyIcon } from "assets/icons/emergency.svg";

const testColor = css`
  path {
    fill: ${({ theme }) => theme.azure};
  }
`;

const Conatiner = styled.div<{ isTest: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  .icon {
    position: absolute;
  }

  .bug {
    width: 3rem;
    opacity: 0.9;
    left: -1.8rem;
    top: -2.2rem;

    ${({ isTest }) =>
      isTest &&
      css`
        ${testColor}
      `}
  }

  .emergency {
    height: 1.8rem;
    width: 1.8rem;
    left: -1.4rem;
    top: 0.2rem;
  }
`;

const LogoContainer = styled.div<{ isTest: boolean }>`
  position: absolute;
  top: 0.2rem;
  left: 0.6rem;
  width: 2.2rem;

  @media screen and (min-height: 616px) {
    width: 2.7rem;
  }

  .logo-icon {
    width: 100%;
    height: 100%;

    ${({ isTest }) =>
      isTest &&
      css`
        ${testColor}
      `}
  }
`;

const WarningContainer = styled.div<{ wide: boolean }>`
  width: 86%;
  height: 3.5rem;
  transform: translateX(3.7rem);

  ${({ wide }) =>
    wide &&
    css`
      width: 88%;
    `}
`;

const WarningInfo = styled.div<{ isDangerous: boolean }>`
  font-size: 1.4rem;
  ${({ theme }) => theme.ellipsisMultiline}
  color: ${({ theme }) => theme.primary_700};

  ${({ isDangerous }) =>
    isDangerous &&
    css`
      color: ${({ theme }) => theme.errorColor};
    `}
`;

const { TEST, DEVELOPMENT, PRODUCTION } = Environments;
const { SNAP_BUG, SNAP_CHECK, SENSOTEC } = Domains;

function SnapCheckLogo({ onClick }: { onClick?: () => void }) {
  const { pathname } = useLocation();
  const wide = !pathname.includes("login");
  const isTest = environment[TEST] || target[SENSOTEC] || domain[SENSOTEC];
  let isDangerous = false;
  let isBugIconVisible = false;

  // console.log(">> fallbackURL:", fallbackURL);
  // console.log(">> baseURL:", baseURL);

  if (environment[DEVELOPMENT]) {
    isDangerous = target[SNAP_CHECK];
    isBugIconVisible = target[SNAP_BUG] && !isDangerous;
  }

  if (environment[PRODUCTION]) {
    isDangerous = domain[SNAP_BUG] && server[SNAP_CHECK];
    isBugIconVisible = domain[SNAP_BUG] && !isDangerous;
  }

  return (
    <Conatiner isTest={isTest}>
      <LogoContainer isTest={isTest} onClick={onClick}>
        <Logo className='logo-icon' />
      </LogoContainer>
      {isBugIconVisible && <BugIcon className='icon bug' />}
      {isDangerous && <EmergencyIcon className='icon emergency' />}

      <WarningContainer wide={wide}>
        <WarningInfo isDangerous={isDangerous}>
          {!!serverWarnLabel && serverWarnLabel}
        </WarningInfo>
      </WarningContainer>
    </Conatiner>
  );
}

export default SnapCheckLogo;
