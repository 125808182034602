import axios from "axios";

export const fallbackURL =
  process.env.NODE_ENV === "development"
    ? `http://localhost:${window.location.port}/`
    : "https://snapcheck.eu/";

export const baseURL = process.env.REACT_APP_BASE_URL || fallbackURL;

export const instance = axios.create({ baseURL });
