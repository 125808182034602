import {
  ChangeEvent,
  useState,
  MouseEvent,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

import { login } from "api/auth";
import { checkIfEmailValid, isIOS, removeWhiteSpaces } from "utils/utils";
import { domain, environment, server, target } from "utils/environment.utils";
import { DeviceInfo, LoginFormPayload, LoginResponse } from "types/types";
import { Domains, Environments } from "types/environment.types";
import AuthContext from "contexts/auth-context/AuthContext";
import useTokens from "hooks/useTokens";
import useScreen from "hooks/useScreen";
import useDevices from "hooks/useDevices";
import useNotification from "hooks/useNotification";
import useDeviceAndAppInfo from "hooks/useDeviceAndAppInfo";
import useAppSettings from "hooks/useAppSettings";
import { getLoginTranslations } from "./translations/login.translations";
import { ScanMethod } from "./types/login.types";

import { ReactComponent as ClearIcon } from "assets/icons/close.svg";
import { ReactComponent as EyeOpen } from "assets/icons/eye-open.svg";
import { ReactComponent as EyeClosed } from "assets/icons/eye-closed.svg";
import { ReactComponent as QrCodeIcon } from "assets/icons/qr-code-4.svg";

import SnapCheckTitle from "components/atoms/logos/SnapCheckTitle";
import AppFooter from "components/atoms/AppFooter";
import SpinningLoader from "components/atoms/loaders/SpinningLoader";
import Input from "components/atoms/Input";
import Button from "components/atoms/Button";

import { ContentContainer, Form, InputContainer } from "styles/generalStyles";

const ForgotPassword = styled.div`
  margin-top: 1.5rem;
  text-align: right;
  font-size: 1.6rem;

  @media screen and (min-height: 508px) {
    font-size: ${({ theme }) => theme.labelFonstSize};
  }

  > span {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const QrCodeLoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  @media screen and (min-height: 420px) {
    margin-top: 2rem;
  }

  @media screen and (min-height: 508px) {
    margin-top: 3rem;
  }
`;

const QrCodeLogin = styled.div`
  height: 6rem;
  width: 6rem;
  position: relative;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  .qr-icon {
    width: 5rem;
    fill: ${({ theme }) => theme.primary};

    @media screen and (min-height: 420px) {
      width: 6.7rem;
    }
  }
`;

const Divider = styled.div`
  height: 0.7rem;

  @media screen and (min-height: 616px) {
    display: none;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const { SNAP_BUG, SNAP_CHECK } = Domains;
const { DEVELOPMENT } = Environments;
const { CAMERA, LIGHT } = ScanMethod;

function Login() {
  const { accessToken, refreshToken, setTokens } = useTokens();
  const { setAuthenticated, redirectPath, inviteEmail, setInviteEmail } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const {
    i18n: { language },
  } = useTranslation("login");
  const { labels, placeholders, button, errors } = getLoginTranslations(language);

  const [emailInputValue, setEmailInputValue] = useState("");
  const [passwordInputValue, setPasswordInputValue] = useState("");
  const [error, setError] = useState({ email: "" });

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const { deviceInfo } = useDeviceAndAppInfo();
  const devInfo = deviceInfo as DeviceInfo;

  const { isSmallScreen } = useScreen();
  const { isVideoInput } = useDevices();
  const { settingsLoaded, failureCount } = useAppSettings();

  const emailLabel = !isSmallScreen ? "Email" : "";
  const passwordLabel = !isSmallScreen ? labels.password : "";

  let footerVisible = true;

  if (devInfo.platform.type === "mobile") {
    footerVisible = !isFocused;
  }

  const notify = useNotification();

  const clearIconVisible = !!emailInputValue;
  const errorMessage = error.email;

  // --------------- Login handlers ---------------

  const handleRedirectAfetrLogin = useCallback(() => {
    if (!!redirectPath) {
      navigate(redirectPath);
      return;
    }

    if (environment[DEVELOPMENT]) {
      if (target[SNAP_CHECK]) {
        navigate("/production-server-warning", { replace: true });
        return;
      }
    }

    if (domain[SNAP_BUG]) {
      if (server[SNAP_CHECK]) {
        navigate("/production-server-warning", { replace: true });
        return;
      }
    }

    navigate("/home", { replace: true });
  }, [navigate, redirectPath]);

  const handleLogin = useCallback(() => {
    setAuthenticated(true);

    if (inviteEmail) {
      setInviteEmail("");
    }
  }, [inviteEmail, setAuthenticated, setInviteEmail]);

  // --------------- Success handler ---------------

  const onSuccess = ({ data: { expires, ...tokens } }: LoginResponse) => {
    setTokens(tokens);
  };

  // --------------- Error handler ---------------

  const onError = (error: AxiosError) => {
    const { response } = error;

    if (response) {
      const { status } = response;
      let message = "";

      if (status === 401) {
        message = errors.invalidLoginDetails;
      } else {
        message = errors.unableToSignIn;
      }

      notify(message, "error");
    }
  };

  // --------------- API ---------------

  const { mutate: loginAction, isLoading } = useMutation<
    LoginResponse,
    AxiosError,
    LoginFormPayload
  >(login, {
    onSuccess,
    onError,
  });

  const disabled = isLoading || !passwordInputValue || !!error.email || !emailInputValue;

  // --------------- Form handlers ---------------

  const handleSignIn = (e: MouseEvent) => {
    e.preventDefault();

    const email = removeWhiteSpaces(emailInputValue.toLowerCase());
    const password = passwordInputValue;
    const payload = { email, password };

    loginAction(payload);
  };

  const clearError = () => {
    setError({ email: "" });
  };

  const clearInput = () => {
    setEmailInputValue("");
  };

  const sanitizeInput = (value: string) => {
    if (!checkIfEmailValid(value)) {
      setError({ email: errors.invalidEmail });
    }
  };

  const onEmailInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    clearError();
    sanitizeInput(value);
    setEmailInputValue(value);
  };

  const onPasswordInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setPasswordInputValue(value);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  // --------------- On click handlers ---------------

  const handleClearIconClick = () => {
    clearInput();
    clearError();
  };

  const onQrIconClick = () => {
    const videoInput = isVideoInput.toString();
    const methodStored = localStorage.getItem("scan-method") as ScanMethod;
    let method = CAMERA;

    if (!isVideoInput) {
      method = LIGHT;
    } else if (methodStored) {
      method = methodStored;
    }

    let path = `/login/scanner?video-input=${videoInput}&method=${method}`;

    if (isIOS) {
      if (method === CAMERA) {
        path = "/camera-detection";
        sessionStorage.setItem(
          "returnPath",
          `/login/scanner?video-input=false&method=${LIGHT}`,
        );
        sessionStorage.setItem(
          "goToPath",
          `/login/scanner?video-input=${videoInput}&method=${method}`,
        );
      }
    }

    navigate(path);
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (accessToken && refreshToken) {
      handleLogin();
      handleRedirectAfetrLogin();
    }
  }, [accessToken, refreshToken, handleLogin, handleRedirectAfetrLogin]);

  useEffect(() => {
    if (inviteEmail) {
      setEmailInputValue(inviteEmail);
    }
  }, [inviteEmail]);

  useEffect(() => {
    if (failureCount === 3) {
      navigate("/server-off-alert", { replace: true });
    }
  }, [failureCount, navigate]);

  return (
    <ContentContainer>
      <SnapCheckTitle />

      {!!settingsLoaded && (
        <Form login>
          <InputContainer>
            <Input
              label={emailLabel}
              name='email'
              onChange={onEmailInputChange}
              value={emailInputValue}
              error={errorMessage}
              placeholder={placeholders.email}
              paddingRight
              onFocus={onFocus}
              onBlur={onBlur}
            />

            {clearIconVisible && (
              <ClearIcon className='clear-icon' onClick={handleClearIconClick} />
            )}
          </InputContainer>

          <Divider />

          <InputContainer password marginBottom>
            <Input
              label={passwordLabel}
              onChange={onPasswordInputChange}
              type={isPasswordVisible ? "text" : "password"}
              value={passwordInputValue}
              placeholder={placeholders.password}
              paddingRight
              onFocus={onFocus}
              onBlur={onBlur}
            />

            {isPasswordVisible ? (
              <EyeOpen className='eye-icon' onClick={togglePasswordVisibility} />
            ) : (
              <EyeClosed className='eye-icon' onClick={togglePasswordVisibility} />
            )}
          </InputContainer>

          <Button
            label={button.label}
            onClick={handleSignIn}
            loading={isLoading}
            disabled={disabled}
          />

          <ForgotPassword
            onClick={() => {
              navigate("/forgot-password");
            }}
          >
            <span>{labels.forgotPassword}</span>
          </ForgotPassword>

          <QrCodeLoginContainer>
            <QrCodeLogin onClick={onQrIconClick}>
              <QrCodeIcon className='qr-icon' />
            </QrCodeLogin>
          </QrCodeLoginContainer>
        </Form>
      )}

      {!!failureCount && (
        <LoaderContainer>
          <SpinningLoader />
        </LoaderContainer>
      )}

      {footerVisible && <AppFooter />}
    </ContentContainer>
  );
}

export default Login;
