import { useState } from "react";
import { useQuery } from "react-query";
import { getCookie } from "react-use-cookie";
import styled from "styled-components/macro";

import { UserOption } from "types/assign-to-user.types";
import { getUsers } from "api/user";
import { ACCESS_TOKEN, colorMap, device, getInitial } from "utils/utils";
import { User } from "types/types";

import { ReactComponent as UserIcon } from "assets/icons/profile-user.svg";

import AvatarImage from "components/molecules/avatar-image/AvatarImage";

const UserItem = styled.div`
  padding: 0 1rem;
  background-color: ${({ theme }) => theme.white};
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.primary_50};
`;

const AvatarContainer = styled.div`
  width: 5rem;

  @media ${device.iPad} {
    width: 6.4rem;
  }
`;

const UserDetails = styled.div`
  width: 72%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media ${device.iPad} {
    width: 84%;
  }
`;

const UserName = styled.span`
  font-family: GothamBold;
  max-width: 100%;
  ${({ theme }) => theme.ellipsis}
`;

const UserEmail = styled.span`
  max-width: 100%;
  ${({ theme }) => theme.ellipsis}
  font-size: 1.4rem;
`;

const Initials = styled.div<{ initial: string }>`
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ initial }) => colorMap[`${initial}`]};
  color: ${({ theme }) => theme.textColor1};
  font-family: GothamBold;

  @media ${device.iPad} {
    width: 6.4rem;
    height: 6.4rem;
    font-size: 2.2rem;
  }
`;

const UserIconContainer = styled.div`
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
  background-color: rgba(196, 205, 222, 0.5);
  border: 2px solid ${({ theme }) => theme.primary};

  @media ${device.iPad} {
    width: 6.4rem;
    height: 6.4rem;
  }

  .user-icon {
    fill: ${({ theme }) => theme.primary};
    width: 100%;
    height: 100%;
  }
`;

const UserIconFallback = () => (
  <UserIconContainer>
    <UserIcon className='user-icon' />
  </UserIconContainer>
);

function useUsersForSelect() {
  const [userOptions, setUserOptions] = useState<UserOption[] | null>(null);
  const token = getCookie(ACCESS_TOKEN);

  // --------------- On success handler ---------------

  const onGetUsersSuccess = (data: User[]) => {
    const allOptions: UserOption[] = data.map((user) => {
      const { id, avatar, email, first_name, last_name } = user;

      const userInitials =
        first_name || last_name
          ? `${getInitial(first_name)}${getInitial(last_name)}`
          : "";

      let initial = "";

      if (userInitials.charAt(1)) {
        initial = userInitials.charAt(1);
      } else if (userInitials.charAt(0)) {
        initial = userInitials.charAt(0);
      }

      let avatarConent = null;

      if (avatar) {
        const avatarId = typeof avatar === "string" ? avatar : avatar.id;

        avatarConent = <AvatarImage avatarId={avatarId} large />;
      } else if (userInitials) {
        avatarConent = <Initials initial={initial}>{userInitials}</Initials>;
      } else {
        avatarConent = <UserIconFallback />;
      }

      return {
        id,
        label: (
          <UserItem key={id}>
            <UserWrapper>
              <AvatarContainer>{avatarConent}</AvatarContainer>
              <UserDetails>
                <UserName>
                  {first_name}&nbsp;{last_name}
                </UserName>
                <UserEmail>{email}</UserEmail>
              </UserDetails>
            </UserWrapper>
          </UserItem>
        ),
        value: `${first_name} ${last_name}`,
      };
    });

    setUserOptions(allOptions);
  };

  // --------------- API request ---------------

  const { isLoading, isError: isGetUsersError } = useQuery("allUsers", getUsers(token), {
    onSuccess: onGetUsersSuccess,
    keepPreviousData: false,
  });

  return { userOptions, isLoading, isGetUsersError };
}

export default useUsersForSelect;
