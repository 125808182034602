import { ChangeEvent, useState, MouseEvent } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useNotification from "hooks/useNotification";

import { ContentContainer, Form, Header, InputContainer } from "styles/generalStyles";

import { requestResetPassword } from "api/auth";
import { checkIfEmailValid } from "utils/utils";
import { getForgotPasswordTranslations } from "./translations/forgotPassword.translations";
import { getLoginTranslations } from "pages/login/translations/login.translations";

import Input from "components/atoms/Input";
import Button from "components/atoms/Button";
import { ReactComponent as ClearIcon } from "assets/icons/close.svg";
import BackArrow from "components/atoms/BackArrow";

function ForgotPassword() {
  const [emailInputValue, setEmailInputValue] = useState("");
  const [error, setError] = useState({ email: "" });
  const navigate = useNavigate();
  const {
    i18n: { language },
  } = useTranslation();
  const notify = useNotification();

  const {
    header,
    label,
    button,
    notify: messages,
  } = getForgotPasswordTranslations(language);
  const { errors } = getLoginTranslations(language);

  const clearIconVisible = !!emailInputValue;

  const errorMessage = error.email;

  const { mutate, isLoading } = useMutation(requestResetPassword, {
    onSuccess: () => {
      notify(messages.success, "success");
      setEmailInputValue("");
    },
    onError: () => {
      notify(messages.error, "error");
    },
  });

  const disabled = isLoading || !emailInputValue || !!error.email;

  const handleResetPassword = (e: MouseEvent) => {
    e.preventDefault();
    const email = emailInputValue.toLowerCase();
    const payload = {
      email,
    };

    mutate(payload);
  };

  const clearError = () => {
    setError({ email: "" });
  };

  const clearInput = () => {
    setEmailInputValue("");
  };

  const sanitizeInput = (value: string) => {
    if (!checkIfEmailValid(value)) {
      setError({ email: errors.invalidEmail });
    }
  };

  const onEmailInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    clearError();
    sanitizeInput(value);
    setEmailInputValue(value);
  };

  const handleClearIconClick = () => {
    clearInput();
    clearError();
  };

  const redirectToLogin = () => {
    navigate("/login");
  };

  return (
    <ContentContainer>
      <Header>
        <BackArrow onClick={redirectToLogin} />
        <span>{header}</span>
      </Header>
      <Form>
        <InputContainer marginBottom>
          <Input
            label={label}
            name='email'
            onChange={onEmailInputChange}
            value={emailInputValue}
            error={errorMessage}
            placeholder='Email'
            paddingRight
          />

          {clearIconVisible && (
            <ClearIcon className='clear-icon' onClick={handleClearIconClick} />
          )}
        </InputContainer>

        <Button
          label={button.label}
          onClick={handleResetPassword}
          loading={isLoading}
          disabled={disabled}
          large
        />
      </Form>
    </ContentContainer>
  );
}

export default ForgotPassword;
