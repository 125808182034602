import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let header = {
    title: "Tasks",
  };

  let tabs = {
    other: "Other",
    assigned: "Mine",
  };

  let labels = {
    dateCreated: "Created",
    btn: {
      add: "Add",
    },
  };

  let alert = {
    error: {
      unableToLoad: "Unable to load tasks",
    },
    info: {
      noAssigned: "No assigned tasks...",
      noTasks: "No tasks...",
    },
  };

  let status = {
    in_progress: "In progress",
  };

  if (isPolish) {
    header = {
      title: "Zadania",
    };

    tabs = {
      other: "Pozostałe",
      assigned: "Moje",
    };

    alert = {
      error: {
        unableToLoad: "Nie można pobrać zadań",
      },
      info: {
        noAssigned: "Brak przypisanych zadań...",
        noTasks: "Brak zadań...",
      },
    };

    labels = {
      dateCreated: "Utworzone",
      btn: {
        add: "Dodaj",
      },
    };

    status = {
      in_progress: "W trakcie",
    };
  }

  return { header, tabs, alert, labels, status };
};
