import CookieConsent from "react-cookie-consent";
import styled, { CSSProperties, css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { isMobile, removeEscapeCharacters, device } from "utils/utils";
import { CountryCodes } from "types/types";
import useAppSettings from "hooks/useAppSettings";

const Container = styled.div<{ isMobile: boolean }>`
  a {
    color: #b7192a;
  }

  .container {
    padding-bottom: 1rem;
    animation: fadeInSlideUp 1.5s ease;

    @keyframes fadeInSlideUp {
      from {
        opacity: 0;
        bottom: -11.1rem;
      }
      to {
        opacity: 1;
        bottom: 0;
      }
    }
  }

  .btn {
    transition: opacity 0.2s ease-out;
    font-family: GothamBook;
    padding: 1rem 0.5rem;

    &:hover {
      opacity: 0.7;
    }

    &-container {
      transform: translate(-1.5rem, 0.9rem);

      ${({ isMobile }) =>
        isMobile &&
        css`
          width: 100%;
          padding-left: 1rem;
          padding-right: 1rem;
          transform: none;
        `}

      @media ${device.iPad} {
        width: 25%;
        margin-left: 73%;
        margin-bottom: 1rem;
      }
    }
  }
`;

const styleDesktop: CSSProperties = {
  backgroundColor: "#fff",
  color: "#000",
  gap: "5rem",
};

const styleMobile: CSSProperties = {
  ...styleDesktop,
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0",
  paddingTop: "1.5rem",
};

const contentStyle: CSSProperties = { lineHeight: "2.4rem", flex: "1 0" };

const buttonStyleDesktop: CSSProperties = {
  color: "#fff",
  backgroundColor: "#b7192a",
  borderRadius: "4px",
  padding: "1rem 2rem",
  width: "100%",
  margin: "0",
};

const buttonStyleMobile: CSSProperties = {
  ...buttonStyleDesktop,
  padding: "1.5rem 2rem",
};

const { PL, EN, US, GB } = CountryCodes;

function CookieConsentBar() {
  const { data, error } = useAppSettings();
  const {
    t,
    i18n: { language },
  } = useTranslation("common");

  let cookieConsentMessage = "";

  if (!error) {
    if (language === PL) {
      cookieConsentMessage = removeEscapeCharacters(data?.data.CookieConsent_pl);
    }

    if (language === EN || language === US || language === GB) {
      cookieConsentMessage = removeEscapeCharacters(data?.data.CookieConsent_en);
    }
  }

  const style = isMobile ? styleMobile : styleDesktop;
  const buttonStyle = isMobile ? buttonStyleMobile : buttonStyleDesktop;

  return !!cookieConsentMessage ? (
    <Container isMobile={isMobile}>
      <CookieConsent
        // debug
        containerClasses='container'
        buttonWrapperClasses='btn-container'
        buttonClasses='btn'
        cookieName='snapcheck_cookie_consent'
        expires={30}
        style={style}
        contentStyle={contentStyle}
        buttonStyle={buttonStyle}
        buttonText={t("button.close")}
        overlay
      >
        <div dangerouslySetInnerHTML={{ __html: cookieConsentMessage }} />
      </CookieConsent>
    </Container>
  ) : null;
}

export default CookieConsentBar;
