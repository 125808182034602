import styled from "styled-components/macro";

import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0.5rem;
  border: 2px solid ${({ theme }) => theme.warningColor};
  border-radius: 0.6rem;
`;

const Label = styled.span`
  color: ${({ theme }) => theme.warningColor};
  font-size: 1.7rem;
  margin-left: 0.2rem;
`;

function NoTypeWarning() {
  return (
    <Container>
      <WarningIcon width={35} height={25} fill='#FFC107' />
      <Label>Nie określono typu pytania</Label>
    </Container>
  );
}

export default NoTypeWarning;
