import { isMobile } from "utils/utils";

export const selectControlStyles = (isError: boolean) => ({
  minHeight: 60,
  borderColor: isError ? "#b00020" : "hsl(0, 0%, 80%)",
  borderWidth: isError ? "2px" : "1px",
  ":hover": {
    cursor: "pointer",
  },
  cursor: !isMobile ? "pointer" : "none",
});
