import { MouseEvent, useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";

import useTimeout from "hooks/useTimeout";

import CircularLoader from "./loaders/CircularLoader";

type ButtonProps = {
  label: string | JSX.Element;
  onClick?: (() => void) | ((e: MouseEvent<HTMLButtonElement>) => void);
  loading?: boolean;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  customClass?: string;
  cancel?: boolean;
  large?: boolean;
  marginRight?: boolean;
  marginLeft?: boolean;
  question?: boolean;
  yesButton?: boolean;
  noButton?: boolean;
  noShaddow?: boolean;
  answered?: boolean;
  showProgress?: boolean;
};

export const StyledBtn = styled.button<{
  cancel?: boolean;
  large?: boolean;
  marginRight?: boolean;
  marginLeft?: boolean;
  question?: boolean;
  yesButton?: boolean;
  noButton?: boolean;
  noShaddow?: boolean;
  answered?: boolean;
}>`
  position: relative;
  width: 100%;
  border-radius: 0.3rem;
  background-color: ${({ theme, cancel, question }) =>
    cancel ? theme.cancelColor : question ? theme.buttonColorNeutral : theme.primary};
  border: none;
  outline: none;
  color: ${({ theme }) => theme.textColor1};
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  user-select: none;
  margin-left: ${({ marginLeft }) => (marginLeft ? "1rem" : "0")};
  margin-right: ${({ marginRight }) => (marginRight ? "1rem" : "0")};
  font-family: GothamBook;
  font-size: ${({ large }) => (large ? "2.2rem" : "1.8rem")};
  ${({ theme }) => theme.buttonShadow};

  height: 4rem;

  ${({ large }) =>
    large &&
    css`
      height: 5rem;
    `}

  @media screen and (min-height: 508px) {
    height: 5rem;

    ${({ large }) =>
      large &&
      css`
        height: 6rem;
      `}
  }

  ${({ noShaddow }) =>
    noShaddow &&
    css`
      box-shadow: none;
    `}

  &:hover {
    filter: brightness(95%);
  }

  &:disabled {
    background-color: ${({ theme, question }) =>
      question ? theme.mainBackground : theme.disabledColor};
    cursor: not-allowed;
  }

  ${({ yesButton }) =>
    yesButton &&
    css<{ answered?: boolean }>`
      background-color: ${({ theme }) => theme.yesButton};
      border-radius: 0.6rem;

      &:disabled {
        &:hover {
          filter: brightness(100%);
        }
      }

      ${({ answered }) =>
        answered &&
        css`
          border: 4px solid;
          font-family: GothamBold;
        `}
    `}

  ${({ noButton }) =>
    noButton &&
    css<{ answered?: boolean }>`
      background-color: ${({ theme }) => theme.noButton};
      border-radius: 0.6rem;

      &:disabled {
        &:hover {
          filter: brightness(100%);
        }
      }

      ${({ answered }) =>
        answered &&
        css`
          border: 4px solid;
          font-family: GothamBold;
        `}
    `}
`;

const LabelContainer = styled.span`
  position: relative;
  z-index: ${({ theme }) => theme.level9};
  transform: translateY(0.2rem);
`;

const Progress = styled.div<{ yesBtn?: boolean; noBtn?: boolean; active: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0.6rem;
  pointer-events: none;
  opacity: 0;
  width: 0%;
  z-index: -1;

  ${({ yesBtn }) =>
    yesBtn &&
    css`
      background-color: #129027;
    `}

  ${({ noBtn }) =>
    noBtn &&
    css`
      background-color: #c00525;
    `}

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      animation: progress-anim 0.6s ease;

      @keyframes progress-anim {
        0% {
          width: 0%;
        }

        100% {
          width: 100%;
        }
      }
    `}
`;

export default function Button({
  label,
  onClick,
  loading = false,
  disabled = false,
  type,
  customClass = "",
  cancel = false,
  large = false,
  marginRight = false,
  marginLeft = false,
  question = false,
  yesButton = false,
  noButton = false,
  noShaddow = false,
  answered = false,
  showProgress = false,
}: ButtonProps) {
  const [progressActive, setProgressActive] = useState(false);
  const setTimeOut = useTimeout();

  useEffect(() => {
    if (showProgress) {
      setTimeOut(() => {
        setProgressActive(true);
      }, 50);
    } else {
      setProgressActive(false);
    }
  }, [showProgress, setTimeOut]);

  return (
    <StyledBtn
      className={customClass}
      disabled={disabled}
      onClick={onClick}
      type={type}
      cancel={cancel}
      large={large}
      marginRight={marginRight}
      marginLeft={marginLeft}
      question={question}
      noButton={noButton}
      yesButton={yesButton}
      noShaddow={noShaddow}
      answered={answered}
    >
      {loading ? <CircularLoader /> : <LabelContainer>{label}</LabelContainer>}
      <Progress noBtn={noButton} yesBtn={yesButton} active={progressActive} />
    </StyledBtn>
  );
}
