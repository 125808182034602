import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { getTranslations } from "../../translations/tasks.translations";
import { TabsEnum } from "../../types/user-tasks.types";
import { isMobile } from "utils/utils";

const Container = styled.div<{ selected: boolean; isMobile: boolean }>`
  margin-right: 1rem;
  cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};
  user-select: none;

  ${({ selected }) =>
    selected &&
    css`
      border-bottom: 2px solid ${({ theme }) => theme.primary};
    `}
`;

type NavTabProps = {
  name: TabsEnum;
  selected: boolean;
  onTabClick: (tab: TabsEnum) => void;
};

const { OTHER, ASSIGNED } = TabsEnum;

function NavTab({ name, selected, onTabClick }: NavTabProps) {
  const {
    i18n: { language },
  } = useTranslation();
  const { tabs } = getTranslations(language);

  let label = "";

  if (name === OTHER) {
    label = tabs.other;
  }

  if (name === ASSIGNED) {
    label = tabs.assigned;
  }

  const onClick = () => {
    onTabClick(name);
  };

  return (
    <Container selected={selected} isMobile={isMobile} onClick={onClick}>
      {label}
    </Container>
  );
}

export default NavTab;
