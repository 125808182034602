import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { isMobile } from "utils/utils";
import { EditModalTypes } from "types/tasks.types";
import { getTranslations } from "../../translations/selected-task.translations";

import { TextArea } from "styles/generalStyles";

export const Container = styled.div<{ isMobile: boolean }>`
  min-height: 4.2rem;
  overflow-y: hidden;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.white};
  ${({ theme }) => theme.scrollbarFirefox}
  cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};

  @media screen and (min-height: 500px) {
    max-height: 12rem;
  }

  @media screen and (min-height: 700px) {
    max-height: 16rem;
  }

  .text-area {
    cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};
    overflow-y: auto;
  }
`;

export const Placeholder = styled.span`
  font-style: italic;
  opacity: 0.5;
  display: inline-block;
  padding-left: 0.25rem;
  padding-top: 0.65rem;
`;

type TaskDecriptionProps = {
  description: string;
  onTextFieldClick: (modalType: EditModalTypes) => () => void;
  create?: boolean;
};

const { DESCRIPTION } = EditModalTypes;

function TaskDescription({ description, onTextFieldClick, create }: TaskDecriptionProps) {
  const {
    i18n: { language },
  } = useTranslation();
  const { placeholders } = getTranslations(language);

  let placeholder = create
    ? placeholders.description.create
    : placeholders.description.edit;

  let content = <Placeholder>{placeholder}...</Placeholder>;

  if (description) {
    content = (
      <TextArea smallFont rows={3} value={description} readOnly className='text-area' />
    );
  }

  return (
    <Container onClick={onTextFieldClick(DESCRIPTION)} isMobile={isMobile}>
      {content}
    </Container>
  );
}

export default TaskDescription;
