import styled, { css } from "styled-components/macro";

export const Content = styled.div<{ animate?: boolean; isContentOverlow?: boolean }>`
  border-radius: 0.4rem;
  animation: ${({ animate }) => (animate ? "fadeInZoom 0.25s ease-out" : "none")};

  @keyframes fadeInZoom {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  ${({ isContentOverlow }) =>
    !isContentOverlow &&
    css`
      ${({ theme }) => theme.fillUpRemainingSpace};
    `}

  @media screen and (min-height: 616px) {
    padding-top: 1rem;
  }
`;

export const CloseIconContainer = styled.div`
  position: relative;
  height: 2rem;
`;

export const Wrapper = styled.div<{ isContentOverlow?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  ${({ theme }) => theme.buttonShadow};
  margin-bottom: 1rem;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.primary_50};
  padding-bottom: 1rem;

  ${({ isContentOverlow }) =>
    !isContentOverlow &&
    css`
      ${({ theme }) => theme.fillUpRemainingSpace};
      height: 95%;
      margin-bottom: 0;
    `}

  @media screen and (min-height: 616px) {
    padding-top: 1rem;
  }
`;

export const WarningBar = styled.div<{ animate?: boolean }>`
  width: 100%;
  height: 0.6rem;
  margin-top: 1rem;
  background-color: ${({ theme }) => theme.primary};
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 20px,
    #ffb656 20px,
    #ffb656 40px
  );

  z-index: 100;

  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;

  animation: ${({ animate }) => (animate ? "fadeInZoom 0.25s ease-out" : "none")};

  @media screen and (min-height: 616px) {
    height: 1rem;
  }
`;

export const Question = styled.div`
  font-size: 1.8rem;
  font-family: GothamBold;

  @media screen and (min-height: 616px) {
    font-size: 2rem;
  }
`;

export const SubText = styled.div`
  font-size: 1.6rem;
  margin-top: 0.5rem;
  -webkit-line-clamp: 1;
`;
