import styled, { css } from "styled-components/macro";

import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";

const Container = styled.div<{ marginTop: boolean }>`
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 1rem;
    `}
`;

const Info = styled.div`
  border-radius: 0.4rem;
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.white};
  border-left: 0.7rem solid ${({ theme }) => theme.successColor};

  .check-icon {
    width: 3rem;
    height: 3rem;
    fill: ${({ theme }) => theme.successColor};
  }
`;

type SuccessAlertProps = {
  message?: string;
  marginTop?: boolean;
};

function SuccessAlert({ message, marginTop = false }: SuccessAlertProps) {
  return (
    <Container marginTop={marginTop}>
      <Info>
        <CheckIcon className='check-icon' />
        <span>{message}</span>
      </Info>
    </Container>
  );
}

export default SuccessAlert;
