import styled, { css } from "styled-components/macro";
import { device } from "utils/utils";

export const Container = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.level10};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
`;

export const CameraContainer = styled.div<{
  flash: boolean;
}>`
  margin: 0 auto;
  background-color: #000;
  height: 100%;
  max-width: 45rem;
  position: relative;

  @media ${device.iPad} {
    position: unset;
    max-width: 100%;
  }

  /* video container, class generated by react-webcam */
  .kvfysmfp {
    position: relative;

    ::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      ${({ flash }) =>
        flash &&
        css`
          background-color: #000;
          opacity: 0;

          animation: flashFadeInOut 0.2s ease-in-out;

          @keyframes flashFadeInOut {
            0% {
              opacity: 0;
            }
            25% {
              opacity: 0.8;
            }
            100% {
              opacity: 0;
            }
          }
        `}
    }
  }
`;

export const ToolBar = styled.div`
  position: relative;
  height: 6%;
  z-index: ${({ theme }) => theme.level20};

  @media screen and (min-height: 701px) {
    height: 12%;
  }

  @media ${device.iPad} {
    height: unset;
  }

  .icon {
    cursor: pointer;

    path {
      stroke: ${({ theme }) => theme.white};
    }
  }
`;

export const RotateView = styled.div`
  position: absolute;
  width: 3.5rem;
  height: 3.5rem;
  bottom: -4rem;
  right: 0.6rem;
  z-index: ${({ theme }) => theme.level2};

  @media ${device.iPad} {
    width: 5rem;
    height: 5rem;
    bottom: -5.7rem;
  }
`;

export const Scale = styled.div<{ visible: boolean }>`
  position: absolute;
  bottom: -3.6rem;
  left: 50%;
  transform: translateX(-50%);
  color: ${({ theme }) => theme.white};
  z-index: ${({ theme }) => theme.level2};
  font-size: 2rem;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.2s ease-out;

  .x-sign {
    font-size: 1.5rem;
  }
`;

export const TorchToggle = styled.div<{ isTorchOn: boolean }>`
  position: absolute;
  left: 0rem;
  bottom: -3.9rem;
  z-index: 20;
  color: ${({ theme }) => theme.white};
  display: grid;
  place-content: center;
  padding: 0 1rem;

  &::after {
    content: "";
    position: absolute;
    top: 1.4rem;
    left: 1.2rem;
    width: 2.5rem;
    height: 0.2rem;
    background-color: ${({ theme }) => theme.greyLight};
    transform: rotate(45deg);
    visibility: ${({ isTorchOn }) => (!isTorchOn ? "visible" : "hidden")};
  }

  .torch-icon {
    width: 3rem;
    height: 3rem;
    fill: #fff;
  }
`;

export const ErrorContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  color: #fff;
  z-index: ${({ theme }) => theme.level2};
`;

export const BottomPanel = styled.div<{ isMobile: boolean }>`
  position: absolute;
  bottom: 0rem;
  left: 0;
  width: 100%;
  height: 7rem;
  padding: 0 1rem;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-height: 616px) {
    height: 10rem;
  }

  ${({ isMobile }) =>
    !isMobile &&
    css`
      max-width: 72rem;
      left: 50%;
      transform: translateX(-50%);
    `}

  @media ${device.iPad} {
    min-width: 100%;
  }
`;

export const PhotoPreview = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.25);
  z-index: ${({ theme }) => theme.level1};
  cursor: pointer;

  @media screen and (min-height: 616px) {
    width: 6rem;
    height: 6rem;
  }
`;

export const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PhotoCounter = styled.div`
  position: absolute;
  left: 6.3rem;
  top: 2.7rem;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.white};
  user-select: none;

  @media screen and (min-height: 616px) {
    left: 7.8rem;
    top: 4rem;
    font-size: 1.6rem;
  }
`;

export const ButtonContainer = styled.div`
  .return-button {
    padding: 0 1.5rem;
    font-size: 1.4rem;
    height: 4rem;
    background-color: ${({ theme }) => theme.primary_600};
  }
`;

export const PhotoCircles = styled.div`
  position: absolute;
  left: 50%;
  bottom: 6.5rem;

  @media screen and (min-height: 616px) {
    bottom: 9rem;
  }
`;

export const OuterCircle = styled.div`
  position: absolute;
  border-radius: 50%;
  left: -3.7rem;
  height: 6rem;
  width: 6rem;
  background-color: hsla(0, 0%, 100%, 0.4);
  z-index: ${({ theme }) => theme.level1};

  @media screen and (min-height: 616px) {
    height: 7.5rem;
    width: 7.5rem;
  }
`;

export const InnerCircle = styled.div`
  position: absolute;
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  left: 57.8%;
  top: 3.45rem;
  background: #fff;
  margin: -22px 0 0 -22px;
  z-index: ${({ theme }) => theme.level2};

  @media screen and (min-height: 616px) {
    height: 4.4rem;
    width: 4.4rem;
    left: 50%;
    top: 3.8rem;
  }
`;
