import {
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  FormEvent,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { getCookie } from "react-use-cookie";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import { editTask } from "api/tasks";
import { useAppSelector } from "redux/hooks/hooks";
import useModal from "hooks/useModal";

import {
  CreatedTask,
  EditTaskPayload,
  EditTaskResponse,
  TaskStatusOption,
  TaskStatuses,
} from "types/tasks.types";
import { EditModalTypes, ReturnPathKeys } from "types/tasks.types";
import { UserOption } from "types/assign-to-user.types";
import { FieldsEnum } from "types/permissions.types";
import { ACCESS_TOKEN, formatDate, isMobile } from "utils/utils";
import { getStatusOptions } from "utils/tasks-utils";
import { getTranslations as selectedTaskTranslations } from "./translations/selected-task.translations";
import { getTranslations as taskCreatorTranslations } from "pages/task-creator/translations/task-creator.translations";
import { getTranslations } from "translations/select.translations";
import useNotification from "hooks/useNotification";
import useUsersForSelect from "hooks/useUsersForSelect";
import UserContext from "contexts/user-context/UserContext";

import Button from "components/atoms/Button";
import ShimmerLoader from "components/atoms/loaders/ShimmerLoader";
import TaskDescription from "./components/task-description/TaskDescription";
import UserCreated from "./components/user-created/UserCreated";
import ErrorAlert from "components/molecules/ErrorAlert";
import ReactionModal from "components/molecules/reaction-modal/ReactionModal";
import ConfirmationModal from "components/organisms/ConfirmationModal";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

import {
  Card,
  Form,
  FormField,
  StyledLabel,
  ContentContainer,
  ButtonContainer,
} from "styles/generalStyles";
import {
  Container,
  Header,
  Title,
  TaskTitle,
  InfoContainer,
  Actions,
  TaskTitleContainer,
  Divider,
} from "styles/selected-task.styles";
import { selectControlStyles } from "styles/select.styles";
import ConfirmMessage from "./components/confirm-message/ConfirmMessage";

const getDataFromStorage = () => {
  const localData = sessionStorage.getItem("selectedTask");
  return localData ? JSON.parse(localData) : undefined;
};

const { TITLE: TITLE_MODAL, DESCRIPTION: DESCRIPTION_MODAL } = EditModalTypes;
const { SELECTED_TASK } = ReturnPathKeys;
const { TITLE, DESCRIPTION, STATUS, ASSIGN_TO } = FieldsEnum;

function SelectedTask() {
  const { allTasks } = useAppSelector(({ tasks }) => tasks);
  const { userOptions, isLoading: usersLoading, isGetUsersError } = useUsersForSelect();

  const [selectedTask, setSelectedTask] = useState<CreatedTask>(getDataFromStorage());
  const [selectedStatus, setSelectedStatus] = useState<TaskStatusOption | null>(null);
  const [selectedUser, setSelectedUser] = useState<UserOption | null>(null);
  const [userCreated, setUserCreated] = useState<UserOption | null>(null);

  const [editiTitleValue, setEditTitleValue] = useState("");
  const [editDescriptionValue, setEditDescriptionValue] = useState("");
  const [dirty, setDirty] = useState(false);
  const { open, openModal, closeModal, secondOpen, openSecondModal, closeSecondModal } =
    useModal();
  const {
    PERMISSIONS: { TASKS_PERMISSIONS },
  } = useContext(UserContext);
  const { PERMITTED_TO_READ, PERMITTED_TO_UPDATE } = TASKS_PERMISSIONS;

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const notify = useNotification();

  const {
    i18n: { language },
  } = useTranslation();
  const { label, placeholder, selectNoOptionsMessage, getUsersError } =
    getTranslations(language);
  const { header, labels, notificationMessage, titles } =
    selectedTaskTranslations(language);
  const {
    labels: { title, description },
    modal,
  } = taskCreatorTranslations(language);

  // *************** PERMISSIONS ***************
  //
  // ----- READ -----
  const permittedToReadAll = PERMITTED_TO_READ.ALL;
  const permittedToReadTitle = permittedToReadAll || PERMITTED_TO_READ.Title;
  const permittedToReadDescription = permittedToReadAll || PERMITTED_TO_READ.Description;
  const permittedToReadStatus = permittedToReadAll || PERMITTED_TO_READ.status;
  const permittedToReadAssignedTo = permittedToReadAll || PERMITTED_TO_READ.AssignTo;
  const permittedToReadUserCreated = permittedToReadAll || PERMITTED_TO_READ.user_created;

  // ----- UPDATE -----
  const permittedToUpdateAll = PERMITTED_TO_UPDATE.ALL;
  const permittedToEditTitle = permittedToUpdateAll || PERMITTED_TO_UPDATE[TITLE];
  const permittedToEditDescription =
    permittedToUpdateAll || PERMITTED_TO_UPDATE[DESCRIPTION];
  const permittedToEditStatus = permittedToUpdateAll || PERMITTED_TO_UPDATE[STATUS];
  const permittedToEditAssignedTo =
    permittedToUpdateAll || PERMITTED_TO_UPDATE[ASSIGN_TO];

  // ******************************

  const statusOptions = useMemo(() => getStatusOptions(language), [language]);

  const initialStatusRef = useRef<TaskStatuses | null>();
  const initialUserAssignedRef = useRef<string | null>();
  const initialTitle = useRef<string | null>();
  const initialDescription = useRef<string | null>();
  const currentEditModalRef = useRef<EditModalTypes>();

  const token = getCookie(ACCESS_TOKEN);
  const taskId = searchParams.get("id") as string;

  const modalType = currentEditModalRef.current;

  let editValue = "";

  if (modalType === TITLE_MODAL) {
    editValue = editiTitleValue;
  }

  if (modalType === DESCRIPTION_MODAL) {
    editValue = editDescriptionValue;
  }

  let modalHeader = modalType ? modal.header[modalType] : "";

  let content = null;

  // --------------- Clear state handlers ---------------

  const clearTextValues = () => {
    if (editiTitleValue) {
      setEditTitleValue("");
    }
    if (editDescriptionValue) {
      setEditDescriptionValue("");
    }
  };

  const clearInitialValues = () => {
    [initialStatusRef, initialUserAssignedRef, initialTitle, initialDescription].forEach(
      (item) => {
        item.current = null;
      },
    );
  };

  // --------------- Navigate handler ---------------

  const navigateToTasksList = () => {
    const path = sessionStorage.getItem(SELECTED_TASK) ?? "";
    navigate(path, { replace: true });
    sessionStorage.removeItem(SELECTED_TASK);
  };

  // --------------- API handler ---------------

  const onSuccess = () => {
    notify(notificationMessage.success, "success");
    navigateToTasksList();
  };

  const onError = () => {
    notify(notificationMessage.error, "error");
  };

  const { mutate, isLoading } = useMutation<
    EditTaskResponse,
    AxiosError,
    EditTaskPayload
  >(editTask, {
    onSuccess,
    onError,
  });

  // --------------- On click handlers ---------------

  const onCloseIconClick = () => {
    if (!dirty) {
      navigateToTasksList();
    } else {
      openSecondModal();
    }
  };

  const onTextFieldClick = (modalType: EditModalTypes) => () => {
    currentEditModalRef.current = modalType;

    if (modalType === TITLE_MODAL) {
      if (permittedToEditTitle) {
        setEditTitleValue(selectedTask.Title);
        openModal();
      }
    }

    if (modalType === DESCRIPTION_MODAL) {
      const description = selectedTask.Description ?? "";
      if (permittedToEditDescription) {
        setEditDescriptionValue(description);
        openModal();
      }
    }
  };

  const onEditSaveBtnClick = () => {
    const updateSelectedTask = () => {
      const key = currentEditModalRef.current;

      if (key) {
        setSelectedTask((prev) => ({ ...prev, [key]: editValue }));
      }
    };

    updateSelectedTask();
    closeModal();
  };

  const onCloseReactionModalIconClick = () => {
    clearTextValues();
    closeModal();
  };

  const onQuitButtonClick = () => {
    navigateToTasksList();
  };

  // --------------- On submit handler ---------------

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let status = selectedStatus?.id as TaskStatuses;
    const id = taskId;

    let payload: EditTaskPayload = {
      id,
      status,
      token,
    };

    if (editiTitleValue) {
      const Title = editiTitleValue;

      payload = { ...payload, Title };
    }

    const Description = editDescriptionValue;

    payload = { ...payload, Description };

    if (selectedUser && selectedUser.id !== initialUserAssignedRef.current) {
      const AssignTo = selectedUser.id;

      payload = { ...payload, AssignTo };
    }

    mutate(payload);
  };

  // --------------- On change handlers ---------------

  const onStatusChange = (option: TaskStatusOption | null) => {
    const updateSelectedTask = () => {
      if (option) {
        setSelectedTask((prev) => ({ ...prev, status: option.id }));
      }
    };

    updateSelectedTask();
    setSelectedStatus(option);
  };

  const onEdititTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { current: modalType } = currentEditModalRef;

    if (modalType === TITLE_MODAL) {
      setEditTitleValue(e.target.value);
    }

    if (modalType === DESCRIPTION_MODAL) {
      setEditDescriptionValue(e.target.value);
    }
  };

  const onAssignUserChange = (option: UserOption | null) => {
    if (option) {
      setSelectedTask((prev) => ({ ...prev, AssignTo: option.id }));
    }
  };

  const onTextTranscriptChange = (textTranscript: string) => {
    const { current: modalType } = currentEditModalRef;

    if (modalType === TITLE_MODAL) {
      setEditTitleValue(textTranscript);
    }

    if (modalType === DESCRIPTION_MODAL) {
      setEditDescriptionValue(textTranscript);
    }
  };

  // --------------- Dirty form handler ---------------

  const handleDirtyForm = useCallback(() => {
    const differentStatus = selectedStatus?.id !== initialStatusRef.current;
    const differentUserAssigned = selectedUser?.id
      ? selectedUser?.id !== initialUserAssignedRef.current
      : false;
    const differentTitle = selectedTask?.Title !== initialTitle.current;
    const differentDescription = selectedTask?.Description !== initialDescription.current;
    const flag =
      differentStatus || differentUserAssigned || differentTitle || differentDescription;

    setDirty(flag);
  }, [
    selectedStatus?.id,
    selectedTask?.Description,
    selectedTask?.Title,
    selectedUser?.id,
  ]);

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (!allTasks.length) {
      return;
    }

    const selected = allTasks.find((task) => task.id === taskId) as CreatedTask;

    if (selected) {
      const { status, AssignTo, Title, Description } = selected;
      initialStatusRef.current = status;
      initialUserAssignedRef.current = AssignTo;
      initialTitle.current = Title;
      initialDescription.current = Description;
    }

    setSelectedTask(selected);
  }, [allTasks, searchParams, taskId]);

  useEffect(() => {
    if (!selectedTask) return;

    sessionStorage.setItem("selectedTask", JSON.stringify(selectedTask));

    const { status, AssignTo, user_created } = selectedTask;

    const initialStatus = statusOptions.find(
      ({ id }) => id === status,
    ) as TaskStatusOption;

    setSelectedStatus(initialStatus);

    if (userOptions && userOptions.length) {
      const userAssigned = userOptions.find(({ id }) => id === AssignTo);
      const createdBy = userOptions.find(({ id }) => id === user_created);

      if (userAssigned) {
        setSelectedUser(userAssigned);
      }

      if (createdBy) {
        setUserCreated(createdBy);
      }
    }

    handleDirtyForm();
  }, [selectedTask, userOptions, statusOptions, handleDirtyForm]);

  useEffect(() => {
    return () => {
      clearInitialValues();
    };
  }, []);

  // --------------- Content ---------------

  if (selectedTask) {
    content = (
      <InfoContainer>
        <Form onSubmit={onSubmit}>
          {permittedToReadTitle && (
            <FormField noMargin>
              <StyledLabel>{title}</StyledLabel>
              <TaskTitleContainer
                onClick={onTextFieldClick(TITLE_MODAL)}
                cursorVisible={!isMobile}
                title={!isMobile ? titles.editTitle : ""}
                disabled={!permittedToEditTitle}
              >
                <TaskTitle>{selectedTask.Title}</TaskTitle>
              </TaskTitleContainer>
            </FormField>
          )}

          {permittedToReadDescription && (
            <FormField noMargin>
              <StyledLabel>{description}</StyledLabel>
              <TaskDescription
                description={selectedTask.Description}
                onTextFieldClick={onTextFieldClick}
              />
            </FormField>
          )}

          {permittedToReadStatus && (
            <FormField>
              <StyledLabel>Status</StyledLabel>
              <Select
                onChange={onStatusChange}
                options={statusOptions}
                value={selectedStatus}
                isSearchable={false}
                placeholder={placeholder.status}
                isDisabled={!permittedToEditStatus}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    cursor: !isMobile ? "pointer" : "none",
                  }),
                  singleValue: (styles, params) => ({
                    ...styles,
                    color: "#212f5a",
                  }),
                }}
              />
            </FormField>
          )}

          {permittedToReadAssignedTo && (
            <FormField>
              <StyledLabel>{label.assigned}:</StyledLabel>

              {userOptions && !isGetUsersError && (
                <Select
                  value={selectedUser}
                  onChange={onAssignUserChange}
                  options={userOptions}
                  isSearchable
                  isDisabled={!permittedToEditAssignedTo}
                  // isClearable
                  placeholder={placeholder.user}
                  noOptionsMessage={() => <span>{selectNoOptionsMessage}</span>}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      ...selectControlStyles(isGetUsersError),
                      color: "red",
                    }),
                    singleValue: (styles, params) => ({
                      ...styles,
                      color: "#212f5a",
                    }),
                  }}
                />
              )}

              {usersLoading && <ShimmerLoader />}
              {isGetUsersError && <ErrorAlert message={getUsersError} smallMargin />}
            </FormField>
          )}

          {permittedToReadUserCreated && (
            <UserCreated userCreated={userCreated} isLoading={usersLoading} />
          )}

          <Actions>
            <ButtonContainer>
              <Button
                label={labels.btn.save}
                loading={isLoading}
                disabled={!dirty || usersLoading || isLoading}
                type='submit'
              />
            </ButtonContainer>
          </Actions>
        </Form>
      </InfoContainer>
    );
  }

  return (
    <ContentContainer>
      <Divider />
      <Card noBottomSpace>
        <Header isMobile={isMobile} noUnderline noPadding>
          <Title>
            {!!selectedTask && (
              <span>
                {header.title}&nbsp;::&nbsp;
                {formatDate(selectedTask.date_created as Date)}
              </span>
            )}
          </Title>
          <CloseIcon className='close-icon' onClick={onCloseIconClick} />
        </Header>

        <Container>{content}</Container>

        <ReactionModal
          header={modalHeader}
          open={open}
          onClose={onCloseReactionModalIconClick}
          reactionTextAreaValue={editValue}
          onReactionTextAreaChange={onEdititTextareaChange}
          onTextTranscriptChange={onTextTranscriptChange}
          onSaveReactionBtnClick={onEditSaveBtnClick}
          optional={modalType === DESCRIPTION_MODAL}
        />

        <ConfirmationModal
          message={<ConfirmMessage />}
          onClick={onQuitButtonClick}
          onClose={closeSecondModal}
          open={secondOpen}
          buttonLabel={labels.btn.quit}
          buttonMiddle
        />
      </Card>
    </ContentContainer>
  );
}

export default SelectedTask;
