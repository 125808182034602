import styled from "styled-components/macro";

import Button from "components/atoms/Button";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  margin-top: 1rem;
  border-radius: 0.4rem;

  @media screen and (min-height: 616px) {
    margin-top: 2rem;
  }
`;

const Label = styled.div`
  font-size: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.primary};
  padding: 1rem;
  font-style: italic;
  opacity: 0.8;
`;

const Actions = styled.div`
  width: 100%;
  position: absolute;
  bottom: 2rem;
  left: 0;
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;

  .return-btn {
    height: 4.5rem;
    font-size: 1.6rem;
  }
`;

const ButtonContainer = styled.div`
  width: 12rem;
`;

function NoPhotos({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation(["photo-gallery", "common"]);

  return (
    <Container>
      <Label>{t("alert.no-photos")}...</Label>

      <Actions>
        <ButtonContainer>
          <Button
            label={t("common:button.back")}
            customClass='return-btn'
            onClick={onClick}
          />
        </ButtonContainer>
      </Actions>
    </Container>
  );
}

export default NoPhotos;
