import styled, { css } from "styled-components/macro";
import { useLocation } from "react-router-dom";

import { ReactComponent as ThumbUpIcon } from "assets/icons/thumb-up.svg";
import { ReactComponent as ThumbUpFilledIcon } from "assets/icons/thumb-up-filled.svg";
import { ReactComponent as ThumbDownIcon } from "assets/icons/thumb-down.svg";
import { ReactComponent as ThumbDownFilledIcon } from "assets/icons/thumb-down-filled.svg";

const Container = styled.div<{
  filled?: boolean;
  down: boolean;
  customClass?: string;
  shouldNotBeMoved?: boolean;
}>`
  display: grid;

  .icon,
  .icon-filled {
    place-self: center;
  }

  .icon {
    height: 2rem;
    place-self: center;

    @media screen and (min-height: 616px) {
      height: 2.5rem;
    }

    path {
      stroke: ${({ theme }) => theme.white};
    }
  }

  .icon-filled {
    height: 2rem;

    @media screen and (min-height: 616px) {
      height: 2.5rem;
    }

    path {
      fill: ${({ theme }) => theme.white};
    }

    ${({ down }) =>
      down &&
      css<{ shouldNotBeMoved?: boolean }>`
        transform: ${({ shouldNotBeMoved }) =>
          shouldNotBeMoved ? "translateY(0)" : "translateY(0.1rem)"};

        @media screen and (min-height: 571px) {
          transform: ${({ shouldNotBeMoved }) =>
            shouldNotBeMoved ? "translateY(0)" : "translateY(0.3rem)"};
        }
      `}
  }

  .invert {
    transform: scaleX(-1) translateY(0.1rem);

    @media screen and (min-height: 571px) {
      transform: scaleX(-1) translateY(0.3rem);
    }
  }
`;

type ThumbProps = {
  up?: boolean;
  down?: boolean;
  filled?: boolean;
  customClass?: string;
};

function Thumb({
  up = false,
  down = false,
  filled = false,
  customClass = "",
}: ThumbProps) {
  const { pathname } = useLocation();
  const shouldNotBeMoved = pathname.includes("table-of-contents");

  let thumb = null;

  if (up) {
    thumb = !filled ? (
      <ThumbUpIcon className={`icon ${customClass}`} />
    ) : (
      <ThumbUpFilledIcon className={`icon-filled ${customClass}`} />
    );
  }

  if (down) {
    thumb = !filled ? (
      <ThumbDownIcon className={`icon invert ${customClass}`} />
    ) : (
      <ThumbDownFilledIcon className={`icon-filled ${customClass}`} />
    );
  }

  return (
    <Container filled={filled} down={down} shouldNotBeMoved={shouldNotBeMoved}>
      {thumb}
    </Container>
  );
}

export default Thumb;
