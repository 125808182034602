import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: {},
};

export const serviceWorkerSlice = createSlice({
  name: "serviceWorkerSlice",
  initialState,
  reducers: {
    setServiceWorkerInitialized(state, action: PayloadAction<boolean>) {
      return { ...state, serviceWorkerInitialized: action.payload };
    },
    setServiceWorkerUpdated(state, action: PayloadAction<boolean>) {
      return { ...state, serviceWorkerUpdated: action.payload };
    },
    setServiceWorkerRegistration(
      state,
      action: PayloadAction<ServiceWorkerRegistration>,
    ) {
      return { ...state, serviceWorkerRegistration: action.payload };
    },
  },
});

export const {
  setServiceWorkerInitialized,
  setServiceWorkerUpdated,
  setServiceWorkerRegistration,
} = serviceWorkerSlice.actions;
