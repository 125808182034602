import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { QuestionnaireObject } from "types/types";

const Container = styled.div`
  .top {
    font-size: 1.7rem;
    font-weight: 700;
  }

  .name {
    margin-top: 1.2rem;
    font-style: italic;
    font-size: 1.7rem;
  }

  .bottom {
    margin-top: 1rem;
    margin-bottom: 3rem;
    font-size: 1.9rem;
  }
`;

function Message() {
  const { t } = useTranslation("home");

  const selectedQuestionnaire = localStorage.getItem("selectedQuestionnaire");
  let questionnaireName = "";

  if (selectedQuestionnaire) {
    const { QuestionnaireTitle }: QuestionnaireObject = JSON.parse(selectedQuestionnaire);
    questionnaireName = QuestionnaireTitle;
  }

  return (
    <Container>
      <div className='top'>{t("modal.top")}</div>
      <div className='name'>&#34;{questionnaireName}&#34;</div>
      <div className='bottom'>{t("modal.bottom")}</div>
    </Container>
  );
}

export default Message;
