import { ReactNode } from "react";
import { Provider } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ErrorBoundary } from "react-error-boundary";
import { ThemeProvider } from "styled-components/macro";
import { QueryClient, QueryClientProvider } from "react-query";

import { AuthContextProvider } from "contexts/auth-context/AuthContext";
import { UserContextProvider } from "contexts/user-context/UserContext";
import { QuestionnairesContextProvider } from "contexts/questionnaire-context/QuestionnairesContext";
import GlobalStyle from "styles/global.css";
import theme from "styles/theme";
import store from "redux/store";

import ErrorFallback from "components/molecules/ErrorFallback";

function Providers({ children }: { children: ReactNode }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <AuthContextProvider>
            <QuestionnairesContextProvider>
              <UserContextProvider>
                <HelmetProvider>
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onReset={() => navigate("/home")}
                  >
                    {children}
                  </ErrorBoundary>
                </HelmetProvider>
              </UserContextProvider>
            </QuestionnairesContextProvider>
          </AuthContextProvider>
        </Provider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default Providers;
