import styled from "styled-components/macro";

const Container = styled.div`
  height: 6rem;
  position: relative;
  border: 0.08rem solid rgb(204, 204, 204);
  border-radius: 0.4rem;
  overflow: hidden;
`;

const Shimmer = styled.div`
  ${({ theme }) => theme.shimmerEffect}
`;

function ShimmerLoader() {
  return (
    <Container>
      <Shimmer />
    </Container>
  );
}

export default ShimmerLoader;
