import { useEffect, useState } from "react";

import useTimeout from "hooks/useTimeout";
import useInterval from "hooks/useInterval";
import useNotification from "hooks/useNotification";

type SWReg = ServiceWorkerRegistration | undefined;

const getSWRegistration = () => {
  return navigator.serviceWorker.getRegistration();
};

function useServiceWorker() {
  const [swRegistration, setSwRegistration] = useState<SWReg>();
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [updating, setUpdating] = useState(false);
  const notify = useNotification();

  const setTimeOut = useTimeout();

  const handleUpdate = () => {
    if (swRegistration && swRegistration.waiting) {
      try {
        swRegistration.waiting.postMessage({ type: "SKIP_WAITING" });
      } catch (e) {
        notify("Błąd aktualizacji", "error");
      }

      // setTimeOut(() => {
      //   if (isAPK) {
      //     navigate("/apk-update-info", { replace: true });
      //   } else {
      //     window.location.reload();
      //   }
      // }, 1000);
    }

    setTimeOut(() => {
      window.location.reload();
    }, 1000);
  };

  const checkIfUpdateAvailable = () => {
    getSWRegistration().then((reg) => {
      setSwRegistration(reg);

      if (reg?.waiting) {
        setNewVersionAvailable(true);
      }
    });
  };

  useInterval(() => {
    checkIfUpdateAvailable();
  }, 1000 * 60 * 1);

  useEffect(() => {
    setTimeOut(() => {
      checkIfUpdateAvailable();
    }, 6000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { newVersionAvailable, updating, setUpdating, handleUpdate };
}

export default useServiceWorker;
