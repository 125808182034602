import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { CountryCodes } from "types/types";

import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";

import { ContentContainer } from "styles/generalStyles";

const Container = styled.div`
  ${({ theme }) => theme.fillUpRemainingSpace}/* border: 2px solid hotpink; */
`;

const Wrapper = styled.div`
  border: 6px solid ${({ theme }) => theme.primary_100};
  border-radius: 0.6rem;
  margin-top: 5rem;
  background-color: rgba(196, 205, 222, 0.3);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  padding-top: 1rem;

  .success-icon {
    width: 4rem;
    height: 4rem;

    fill: ${({ theme }) => theme.successColor};
  }
`;

const Info = styled.div`
  text-align: center;
  margin-top: 2rem;
  padding-bottom: 1.5rem;
`;

const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let header = "Update has been successful!";
  let info = "Close the application and restart it.";

  if (isPolish) {
    header = "Aktualizacja przebiegła pomyślnie!";
    info = "Zamknij aplikację i uruchom ponownie.";
  }

  return { header, info };
};

function ApkUpdateInfo() {
  const {
    i18n: { language },
  } = useTranslation();

  const { header, info } = getTranslations(language);

  return (
    <ContentContainer>
      <Container>
        <Wrapper>
          <Header>
            <CheckIcon className='success-icon' />
            <h3>{header}</h3>
          </Header>
          <Info>{info}</Info>
        </Wrapper>
      </Container>
    </ContentContainer>
  );
}

export default ApkUpdateInfo;
