import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { getSuccessfullySentTranslations } from "./translations/successfullySent.translations";
import useHandleBackToHomePage from "hooks/useHandleBackToHomePage";
import useTimeout from "hooks/useTimeout";

import SuccessAnimated from "components/molecules/success-animated/SuccessAnimated";
import EntityInfo from "components/molecules/EntityInfo";

import { ContentContainer, Wrapper } from "styles/generalStyles";

function SuccessfullySent() {
  const { handleBackToHomePage } = useHandleBackToHomePage();
  const setTimeOut = useTimeout();
  const {
    i18n: { language },
  } = useTranslation();

  const { alert } = getSuccessfullySentTranslations(language);

  useEffect(() => {
    setTimeOut(() => {
      handleBackToHomePage();
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentContainer>
      <Wrapper>
        <EntityInfo />
        <SuccessAnimated message={alert.success} />
      </Wrapper>
    </ContentContainer>
  );
}

export default SuccessfullySent;
