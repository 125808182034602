import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { formatDistance } from "date-fns";
import pl from "date-fns/locale/pl/index";
import en from "date-fns/locale/en-GB/index";
import { getCookie } from "react-use-cookie";

import { useTranslation } from "react-i18next";

import { ContentContainer, Header, CheckListsContainer } from "styles/generalStyles";
import {
  QuestionnaireObject,
  CountryCodes,
  EntitiesEnum,
  Entity,
  MachineProcessStatuses,
  MachineProcessStatus,
} from "types/types";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import UserContext from "contexts/user-context/UserContext";
import { shuffle } from "pages/questionnaire/utils/utils";
import useModal from "hooks/useModal";
import { ACCESS_TOKEN, clearLocalStorage, isMobile } from "utils/utils";
import { baseURL } from "axios-instance/axios-instance";

import Placeholders from "./components/Placeholders";
import ErrorAlert from "components/molecules/ErrorAlert";
import BackArrow from "components/atoms/BackArrow";
import NoQuestionnairesFound from "./components/NoQuestionnairesFound";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import Message from "./components/Message";
import LastFilledOut from "./components/LastFilledOut";
import SubText from "./components/SubText";

const CheckListItem = styled.li<{ isMobile: boolean; open: boolean }>`
  ${({ theme }) => theme.checkListItem}
  position: relative;
  user-select: none;
  transition: filter 0.2s ease-out;
  cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};

  ${({ open }) =>
    open &&
    css`
      height: auto;
    `}

  &:hover {
    filter: brightness(0.97);
  }
`;

const Title = styled.div<{ isMobile: boolean }>`
  height: 4.5rem;
  margin-bottom: 0.6rem;
  font-family: GothamBold;
  font-size: 1.6rem;
  ${({ theme }) => theme.ellipsisMultiline}
  -webkit-line-clamp: 2;

  @media screen and (min-height: 616px) {
    font-size: 1.8rem;
  }
`;

type Status = {
  entity: Entity;
  value: MachineProcessStatus;
};

const { MACHINE, PROCESS } = EntitiesEnum;
const { OUT_OF_ORDER } = MachineProcessStatuses;

function CheckLists() {
  const {
    state: { questionnaires, answers, selectedQuestionnaire, machineProcess },
    setAnswersAction,
    setStartTimeAction,
    setSelectedQuestionnaireAction,
    questionnairesLoading: isLoading,
    questionnairesError: isError,
    fetchQuestionnaires,
    clearQuestionnaireStateAction,
  } = useContext(QuestionnairesContext);
  const { user } = useContext(UserContext);
  const [status, setStatus] = useState<Status>();
  const [openElementId, setOpenElementId] = useState("");

  const { open, openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("questionnaires");
  const token = getCookie(ACCESS_TOKEN);
  const questionnaireIdRef = useRef("");

  const noQuestionnairesFound = !isLoading && !isError && !questionnaires.length;
  const isLoggedIn = !!localStorage.getItem("machineProcess");

  let content = null;

  // This is to avoid being redirected to currentPath after scanning QR code
  // with phone scanner.
  // Redirect path is used to redirect user to the point in the questionnaire
  // where they left off. This behaviour is not desired after scanning qr code.
  // localStorage.removeItem("currentPath");

  // Reason of setting user id in local storage here:
  //    user logged in after scanning QR code with phone scanner
  //    (session was inactive, user id was not set in local storage).
  // In the normal flow, user id is set on the Home page.
  // When user scans QR code with phone scanner, the Home page is omitted,
  // that is why user id has to be set in local storage on this page.
  useEffect(() => {
    const userIdInLocalStorage = localStorage.getItem("userId");
    if (user) {
      if (!userIdInLocalStorage) {
        localStorage.setItem("userId", user.id);
      }
    }
  }, [user]);

  useEffect(() => {
    if (machineProcess) {
      const { Machine, Process } = machineProcess;

      if (Machine) {
        setStatus({ entity: MACHINE, value: Machine.status });
      }

      if (Process) {
        setStatus({ entity: PROCESS, value: Process.status });
      }
    }
  }, [machineProcess]);

  useEffect(() => {
    const clearState = () => {
      clearQuestionnaireStateAction();
      clearLocalStorage(["selectedQuestionnaire", "currentPath", "answers"]);
    };

    if (selectedQuestionnaire) {
      clearState();
    }

    if (!!sessionStorage.getItem("scrollPosition")) {
      sessionStorage.removeItem("scrollPosition");
    }
  }, [selectedQuestionnaire, clearQuestionnaireStateAction]);

  useEffect(() => {
    // If there is ONLY ONE questionnaire on the list
    // and user has not yet logged in, launch it.
    if (questionnaires.length === 1 && !isLoggedIn) {
      onCheckListItemClick(questionnaires[0].id)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaires]);

  // --------------- *** ---------------

  const starQuestionnaire = (id: string) => {
    const chosenQuestionnaire = questionnaires.find(
      (item) => item.id === id,
    ) as QuestionnaireObject;

    if (!selectedQuestionnaire) {
      let questionnaireToBeSetInState = chosenQuestionnaire;

      if (chosenQuestionnaire.RandomOrder) {
        const Questions = shuffle(chosenQuestionnaire.Questions);

        questionnaireToBeSetInState = {
          ...chosenQuestionnaire,
          Questions,
        };
      }

      setSelectedQuestionnaireAction(questionnaireToBeSetInState);
    }

    if (answers.length) {
      setAnswersAction([]);
    }

    if (questionnaireIdRef.current) {
      questionnaireIdRef.current = "";
    }

    localStorage.setItem("currentPath", `/questionnaire/${id}/1`);
    navigate(`/questionnaire/${id}/1`);

    setStartTimeAction(new Date());
  };

  const clearState = () => {
    clearQuestionnaireStateAction();
    clearLocalStorage([
      "selectedQuestionnaire",
      "serialNumberPath",
      "machineProcess",
      "serialNumber",
      "currentPath",
      "answers",
    ]);
  };

  const redirectToEnterSerialNumber = () => {
    navigate("/serial-number-method-selection", { replace: true });
  };

  // --------------- On click handlers ---------------

  const onBackArrowClick = () => {
    clearState();
    redirectToEnterSerialNumber();
  };

  const onCheckListItemClick = (id: string) => () => {
    if (status?.value === OUT_OF_ORDER) {
      questionnaireIdRef.current = id;
      openModal();
    } else {
      starQuestionnaire(id);
    }
  };

  const onErrorClick = () => {
    fetchQuestionnaires();
  };

  const toggleChevron = (id: string) => {
    if (openElementId !== id) {
      setOpenElementId(id);
    } else {
      setOpenElementId("");
    }
  };

  // --------------- Content ---------------

  if ((questionnaires.length === 1 && isLoggedIn) || questionnaires.length > 1) {
    content = (
      <CheckListsContainer>
        {questionnaires.map((item: QuestionnaireObject, index) => {
          const {
            id,
            QuestionnaireSubtitle,
            QuestionnaireTitle,
            last_assessment_date_created,
          } = item;
          const open = openElementId === id;

          let last = null;
          let href = "";

          if (last_assessment_date_created) {
            last = last_assessment_date_created;
          }

          if ("last_assessment" in item) {
            const {
              last_assessment: { date_created, File },
            } = item;

            last = date_created;

            if (File) {
              href = `${baseURL}assets/${File}?access_token=${token}`;
            }
          }

          let lastFilledOut = "";

          if (last) {
            if (i18n.language.includes(CountryCodes.EN)) {
              lastFilledOut = `${formatDistance(new Date(), new Date(last), {
                locale: en,
              })} ago`;
            }

            if (i18n.language.includes(CountryCodes.PL)) {
              lastFilledOut = `${formatDistance(new Date(), new Date(last), {
                locale: pl,
              })} temu`;
            }
          }

          return (
            <CheckListItem
              onClick={onCheckListItemClick(id)}
              isMobile={isMobile}
              key={id}
              open={open}
            >
              <Title isMobile={isMobile}>{QuestionnaireTitle}</Title>
              <SubText
                id={id}
                html={QuestionnaireSubtitle}
                toggleChevron={toggleChevron}
                open={open}
              />

              <LastFilledOut href={href} lastFilledOut={lastFilledOut} />
            </CheckListItem>
          );
        })}
      </CheckListsContainer>
    );
  }

  if (isLoading) {
    content = <Placeholders questionnaires />;
  }

  if (isError) {
    content = <ErrorAlert onClick={onErrorClick} message={t("error.message")} />;
  }

  if (noQuestionnairesFound) {
    content = <NoQuestionnairesFound />;
  }

  return (
    <ContentContainer>
      <Header upperCase>
        <BackArrow onClick={onBackArrowClick} />
        <span>{t("header")}</span>
      </Header>
      {content}

      <ConfirmationModal
        message={<Message entity={status?.entity} />}
        onClick={() => starQuestionnaire(questionnaireIdRef.current)}
        onClose={closeModal}
        open={open}
        buttonLabel={t("button.complete")}
        buttonMiddle
        warning
      />
    </ContentContainer>
  );
}

export default CheckLists;
