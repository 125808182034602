import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let infoForUser = {
    header: "Error",
    top: "A data base error has occured.",
    bottom: "It is recommended to close the application and sign in again.",
  };

  let buttonLabels = {
    backToHomePage: "Back to home page",
    singOut: "Sign out",
  };

  if (isPolish) {
    infoForUser = {
      header: "Błąd",
      top: "Wystąpił błąd bazy danych.",
      bottom: "Zalecane jest zamknięcie aplikacji i ponowne zalogowanie się.",
    };

    buttonLabels = {
      backToHomePage: "Wróć do strony głównej",
      singOut: "Wyloguj się",
    };
  }

  return {
    infoForUser,
    buttonLabels,
  };
};
