import styled from "styled-components/macro";

import serverFailure from "assets/images/server-off.png";

import Button from "components/atoms/Button";

import { ContentContainer } from "styles/generalStyles";

const Container = styled.div`
  margin-bottom: 1rem;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  border-bottom-left-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
`;

const Warning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0.5rem 2rem 0.5rem;
  text-align: center;
  font-family: GothamBold;

  @media screen and (min-width: 360px) {
    font-size: 1.8rem;
  }
`;

const WarningIconContainer = styled.div`
  width: 18rem;

  .image {
    width: 100%;
  }

  .warning-icon {
    width: 6rem;
    height: 6rem;
  }
`;

const Actions = styled.div`
  width: 50%;
  margin: 0 auto;
`;

function ServerOffAlert() {
  const onClick = () => {
    window.location.reload();
  };

  return (
    <ContentContainer>
      <Container>
        <Warning>
          <h2>&#8211;&nbsp;Przerwa techniczna&nbsp;&#8211;</h2>
          <WarningIconContainer>
            <img src={serverFailure} alt='' className='image' />
          </WarningIconContainer>
          <div>Przeprowadzamy obecnie planowane prace konserwatorskie.</div>
          <div>Zapraszamy ponownie za chwilę... lub dwie. 😉</div>
        </Warning>

        <Actions>
          <Button label='Odśwież' onClick={onClick} />
        </Actions>
      </Container>
    </ContentContainer>
  );
}

export default ServerOffAlert;
