import styled, { css } from "styled-components/macro";
import { useLocation } from "react-router-dom";

import logo from "assets/logos/SnapCheck-03.png";

const LogoContainer = styled.div<{ marginBottom: boolean }>`
  width: 16rem;
  height: 4rem;
  margin: 0 auto;
  margin-bottom: 1rem;

  @media screen and (min-height: 508px) {
    width: 20rem;
    height: 5rem;
    margin-bottom: 0;
  }

  @media screen and (min-height: 570px) {
    width: 22rem;
    margin-top: 1rem;

    ${({ marginBottom }) =>
      marginBottom &&
      css`
        margin-bottom: 1rem;
      `}
  }
`;

const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

function SnapCheckTitle() {
  const { pathname } = useLocation();
  const marginBottom = pathname.includes("/login");

  return (
    <LogoContainer marginBottom={marginBottom}>
      <LogoImage src={logo} alt='' />
    </LogoContainer>
  );
}

export default SnapCheckTitle;
