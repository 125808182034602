import { useContext } from "react";
import UserContext from "contexts/user-context/UserContext";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";

function useAdditionalOptionsAccess() {
  const {
    state: { machineProcess },
  } = useContext(QuestionnairesContext);
  const {
    PERMISSIONS: { TASKS_PERMISSIONS, MACHINES_PERMISSIONS, PROCESSES_PERMISSIONS },
  } = useContext(UserContext);

  const hasPermissionToCreateTask = TASKS_PERMISSIONS.PERMITTED_TO_CREATE.ALL;

  let hasPermissionToUpdateStatus = false;
  let hasAccessToAdditionalOptions = false;

  if (machineProcess) {
    const { Machine, Process } = machineProcess;

    if (Machine) {
      const { PERMITTED_TO_UPDATE } = MACHINES_PERMISSIONS;
      hasPermissionToUpdateStatus = PERMITTED_TO_UPDATE.ALL || PERMITTED_TO_UPDATE.status;
    }

    if (Process) {
      const { PERMITTED_TO_UPDATE } = PROCESSES_PERMISSIONS;
      hasPermissionToUpdateStatus = PERMITTED_TO_UPDATE.ALL || PERMITTED_TO_UPDATE.status;
    }
  }

  hasAccessToAdditionalOptions = hasPermissionToCreateTask || hasPermissionToUpdateStatus;

  return {
    hasAccessToAdditionalOptions,
    hasPermissionToUpdateStatus,
    hasPermissionToCreateTask,
  };
}

export default useAdditionalOptionsAccess;
