import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { AccidentForm } from "types/types";

import { OptionLabel, Option } from "styles/generalStyles";
import { useEffect, useState } from "react";

const Container = styled.div``;

const ListContainer = styled.ul`
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

const Department = styled.div`
  text-align: center;
  padding-bottom: 0.4rem;
  font-family: GothamBold;
  color: ${({ theme }) => theme.primary_600};
`;

const EmptyForm = styled.span`
  font-family: "GothamBook";
  font-style: italic;
  color: ${({ theme }) => theme.greyLight};
  font-size: 1.3rem;
`;

type AccidentsListProps = {
  accidentForms: AccidentForm[];
  onClick: (id: string) => () => void;
};

type AccidentsSorted = { departmentName: string; forms: AccidentForm[] };

function AccidentsList({ accidentForms, onClick }: AccidentsListProps) {
  const [accidentsSorted, setAccidentsSorted] = useState<AccidentsSorted[]>([]);
  const { t } = useTranslation("alarms");

  let content = null;

  if (accidentsSorted.length) {
    content = accidentsSorted.map((item, index) => (
      // <ListContainer key={item.forms[index].id}>
      <ListContainer key={index}>
        <Department>&#8212;&nbsp;{item.departmentName}&nbsp;&#8212;</Department>
        {item.forms.map(({ id, Title, Colour }) => (
          <Option key={id} alarm onClick={onClick(id)} backgroundColour={Colour}>
            <OptionLabel>
              {Title ? Title : <EmptyForm>{t("empty-form")}</EmptyForm>}
            </OptionLabel>
          </Option>
        ))}
      </ListContainer>
    ));
  }

  useEffect(() => {
    const departments = [
      ...new Set(
        accidentForms.map(({ Department }) => {
          if (Department) {
            return Department.DepartmentName;
          } else {
            return "";
          }
        }),
      ),
    ];

    if (!departments.some((item) => item)) return;

    const sortedByDepartment = departments.map((name) => ({
      departmentName: name,
      forms: accidentForms
        .map((item) => (item.Department.DepartmentName === name ? item : undefined))
        .filter((item) => !!item),
    }));

    setAccidentsSorted(sortedByDepartment as unknown as AccidentsSorted[]);
  }, [accidentForms]);

  return <Container>{content}</Container>;
}

export default AccidentsList;
