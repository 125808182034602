import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import useMachineProcessInfo from "hooks/useMachineProcessInfo";
import useHandleBackToHomePage from "hooks/useHandleBackToHomePage";
import { getTranslations } from "./translations/additional-options.translations";

import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";

import Button from "components/atoms/Button";
import Stepper from "./components/stepper/Stepper";

import { Card, ContentContainer } from "styles/generalStyles";
import useAdditionalOptionsAccess from "hooks/useAdditionalOptionsAccess";

const Container = styled.div`
  ${({ theme }) => theme.fillUpRemainingSpace};
`;

const Header = styled.header`
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  font-size: 2rem;
`;

const Title = styled.span`
  font-family: AuraAspect;
`;

const Main = styled.main`
  ${({ theme }) => theme.fillUpRemainingSpace};
  align-items: center;
  padding-top: 15%;
`;

const QuestionContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

const Question = styled.div`
  font-size: 2rem;
  padding-bottom: 1.5rem;
`;

const ButtonContainer = styled.div<{ wide?: boolean }>`
  width: ${({ wide }) => (wide ? "12rem" : "10rem")};

  .skip-button {
    background-color: ${({ theme }) => theme.primary_500};
  }
`;

const Actions = styled.footer<{ alignRight?: boolean }>`
  margin-top: auto;
  padding-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: ${({ alignRight }) => (alignRight ? "flex-end" : "space-between")};
`;

const ChangeStatusDisabled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .top {
    font-family: GothamBold;
    padding-bottom: 0.5rem;
    font-size: 1.8rem;
  }

  .w-icon {
    width: 3rem;
    margin-bottom: 1rem;
    fill: ${({ theme }) => theme.orange};
  }
`;

function AdditionalOptions() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    i18n: { language },
  } = useTranslation();
  const { entity } = useMachineProcessInfo();
  const { handleBackToHomePage } = useHandleBackToHomePage();
  const { hasPermissionToCreateTask, hasPermissionToUpdateStatus } =
    useAdditionalOptionsAccess();

  const { data, header, button, warning } = getTranslations(
    language,
    entity,
    hasPermissionToUpdateStatus,
    hasPermissionToCreateTask,
  );

  const step = Number(searchParams.get("step"));

  let isLastStep = step === 1;

  if (hasPermissionToCreateTask || hasPermissionToUpdateStatus) {
    isLastStep = step === 2;
  }

  if (hasPermissionToCreateTask && hasPermissionToUpdateStatus) {
    isLastStep = step === 3;
  }

  // Prevents app from crashing in case user types /additional-options?step=1
  // directly into browser search bar.
  const changeStatusDisabled = step === 1 && !entity;

  const getQuestion = (step: number) => {
    // Prevents app from crashing on refresh at step 3.
    try {
      return data[step - 1].question;
    } catch {}
  };

  // --------------- NAVIGATE HANDLER ---------------

  const navigateToProperPage = (step: number) => {
    const { path } = data[step - 1];

    navigate(path);
  };

  // --------------- RETURN PATH HANDLER ---------------

  const handleReturnPath = (step: number) => {
    const {
      returnPath: { key, value },
    } = data[step - 1];

    if (key && value) {
      sessionStorage.setItem(key, value);
    }
  };

  // --------------- ON CLICK HANDLERS ---------------

  const onYesClickButton = (step: number) => () => {
    handleReturnPath(step);
    navigateToProperPage(step);
  };

  const onNextButtonClick = () => {
    setSearchParams({ step: `${step + 1}` });
  };

  const onStepperCircleClick = (step: string) => {
    setSearchParams({ step });
  };

  const onExitButtonClick = () => {
    handleBackToHomePage();
  };

  return (
    <ContentContainer>
      <Container>
        <Header>
          <Title>{header.title}</Title>
        </Header>

        <Stepper
          step={step}
          onStepperCircleClick={onStepperCircleClick}
          hasPermissionToUpdateStatus={hasPermissionToUpdateStatus}
          hasPermissionToCreateTask={hasPermissionToCreateTask}
        />

        <Main>
          {changeStatusDisabled ? (
            <ChangeStatusDisabled>
              <WarningIcon className='w-icon' />
              <span className='top'>{warning.top}</span>
              <span>{warning.bottom}</span>
            </ChangeStatusDisabled>
          ) : (
            <Card noBottomSpace width={90}>
              <QuestionContainer>
                <Question>{getQuestion(step)}</Question>
              </QuestionContainer>
            </Card>
          )}
        </Main>

        <Actions alignRight={isLastStep}>
          {!isLastStep && (
            <ButtonContainer wide>
              <Button
                label={button.yes}
                onClick={onYesClickButton(step)}
                disabled={changeStatusDisabled}
              />
            </ButtonContainer>
          )}

          <ButtonContainer wide>
            <Button
              label={isLastStep ? button.exit : button.skip}
              onClick={isLastStep ? onExitButtonClick : onNextButtonClick}
              customClass={isLastStep ? "" : "skip-button"}
            />
          </ButtonContainer>
        </Actions>
      </Container>
    </ContentContainer>
  );
}

export default AdditionalOptions;
