import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getCookie } from "react-use-cookie";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { baseURL } from "axios-instance/axios-instance";
import { ACCESS_TOKEN, device } from "utils/utils";
import { getTasksInProgress } from "api/questionnaires";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";

import ErrorAlert from "components/molecules/ErrorAlert";
import ThumbIcon from "components/molecules/ThumbIcon";
import PhotosPanel from "./components/PhotosPanel";
import ChevronToggle from "./components/ChevronToggle";
import ReactionValuePanel from "./components/ReactionValuePanel";

const Container = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 31rem;

  @media screen and (min-height: 616px) {
    max-height: 40rem;
  }

  @media screen and (min-height: 702px) {
    max-height: 58rem;
  }

  @media ${device.iPad} {
    max-height: 94rem;
  }
`;

const TasksList = styled.ul`
  padding-bottom: 0.2rem;
`;

const TaskItem = styled.li`
  padding-top: 1rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  border-radius: 0.4rem;
  background-color: rgba(196, 205, 222, 0.5);

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .icon-yes {
    width: 100%;

    path {
      fill: ${({ theme }) => theme.ufoGreen};
    }
  }

  .icon-no {
    width: 100%;
    transform: translateY(-0.1rem);

    path {
      fill: ${({ theme }) => theme.errorColor};
    }
  }
`;

const TaskItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 0;
`;

const TaskTitle = styled.p`
  font-family: GothamBold;
  flex-basis: 90%;
  font-size: 2rem;
`;

const TaskDetailsWrapper = styled.div<{ expanded: boolean }>`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.7s ease;

  ${({ expanded }) =>
    expanded &&
    css`
      grid-template-rows: 1fr;
    `}
`;

const TaskDetailsList = styled.ul`
  overflow: hidden;
`;

const TaskDetailsItem = styled.li`
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 0.6rem;
`;

const PhotoContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Photo = styled.img`
  width: 100%;
  border-radius: 0.4rem;
`;

const ReactionValueContainer = styled.div`
  overflow: hidden;
`;

const ReactionValueText = styled.div`
  padding-top: 1rem;
  font-size: 1.8rem;
`;

const Description = styled.div`
  font-style: italic;
  border-radius: 0.4rem;
  padding: 0.7rem;
  background-color: ${({ theme }) => theme.white};
`;

const NoTasksInProgress = styled.div`
  margin-top: 4rem;
  padding-bottom: 1rem;
  font-style: italic;
  text-align: center;
  color: ${({ theme }) => theme.primary_600};
  font-size: 1.8rem;
`;

type TasksInProgressProps = {
  setTasksLoading: Dispatch<SetStateAction<boolean>>;
  setTasksNumber: Dispatch<SetStateAction<number>>;
};

function TasksInProgress({ setTasksLoading, setTasksNumber }: TasksInProgressProps) {
  const {
    state: {
      machineProcess: { Machine, Process },
    },
  } = useContext(QuestionnairesContext);
  const token = getCookie(ACCESS_TOKEN);
  const { t } = useTranslation("machine-process-info");
  const [expandedItem, setExpandedItem] = useState("");

  const payload = {
    machineId: Machine?.id,
    processId: Process?.id,
  };

  const { data, isLoading, isError, refetch, isSuccess } = useQuery(
    "tasksInProgress",
    getTasksInProgress(token, payload),
  );

  useEffect(() => {
    setTasksLoading(isLoading);
  }, [isLoading, setTasksLoading]);

  useEffect(() => {
    if (!data) return;

    setTasksNumber(data.data.length);
  }, [data, setTasksNumber]);

  const toggleAccordion = (id: string) => () => {
    if (expandedItem === id) {
      setExpandedItem("");
    } else {
      setExpandedItem(id);
    }
  };

  return (
    <Container>
      {isSuccess && (
        <>
          {data && !!data.data.length ? (
            <TasksList>
              {data.data.map(
                ({
                  id: taskId,
                  OriginalText,
                  FilesGalleryOurs,
                  Value,
                  ReactionValue,
                }) => {
                  const containsPhotos = !!FilesGalleryOurs.length;
                  const expanded = expandedItem === taskId;
                  const photosNumber = FilesGalleryOurs.length;

                  return (
                    <TaskItem key={taskId}>
                      <TaskItemHeader>
                        <TaskTitle>{OriginalText}</TaskTitle>
                        <ThumbIcon value={Value} />
                      </TaskItemHeader>

                      {containsPhotos && (
                        <>
                          <PhotosPanel
                            id={taskId}
                            expanded={expanded}
                            photosNumber={photosNumber}
                            toggleAccordion={toggleAccordion}
                          />

                          <TaskDetailsWrapper expanded={expanded}>
                            <TaskDetailsList>
                              {FilesGalleryOurs.map(({ id, directus_files_id }) => {
                                const { description, id: photoId } = directus_files_id;
                                const src = `${baseURL}assets/${photoId}?access_token=${token}`;

                                return (
                                  <TaskDetailsItem key={id}>
                                    <PhotoContainer>
                                      <Photo src={src} alt='' />
                                    </PhotoContainer>
                                    {!!description && (
                                      <Description>{description}</Description>
                                    )}
                                  </TaskDetailsItem>
                                );
                              })}

                              <ChevronToggle
                                id={taskId}
                                expanded={expanded}
                                toggleAccordion={toggleAccordion}
                                bottom
                              />
                            </TaskDetailsList>
                          </TaskDetailsWrapper>
                        </>
                      )}

                      {!containsPhotos && !!ReactionValue && (
                        <>
                          <ReactionValuePanel
                            id={taskId}
                            expanded={expanded}
                            toggleAccordion={toggleAccordion}
                          />

                          <TaskDetailsWrapper expanded={expanded}>
                            <ReactionValueContainer>
                              <ReactionValueText>{ReactionValue}</ReactionValueText>
                            </ReactionValueContainer>
                          </TaskDetailsWrapper>
                        </>
                      )}
                    </TaskItem>
                  );
                },
              )}
            </TasksList>
          ) : (
            <NoTasksInProgress>{t("no-tasks-in-progress")}</NoTasksInProgress>
          )}
        </>
      )}

      {isError && (
        <ErrorAlert onClick={refetch} message={t("error.unable-to-load-tasks")} />
      )}
    </Container>
  );
}

export default TasksInProgress;
