import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import useAccidentForm from "hooks/useAccidentForm";
import useScreen from "hooks/useScreen";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";

import chevron from "assets/icons/navigation-chevron.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";

import {
  TriangleContainer,
  Triangle,
  QuestionNumber,
  Header,
  NoQuestions,
} from "../../questionnaire/styles/questionnaireStyles";

type NavHeaderProps = {
  onBackClick: () => void;
  onForwardClick: () => void;
};

function NavHeader({ onBackClick, onForwardClick }: NavHeaderProps) {
  const {
    state: { selectedAccidentForm },
  } = useContext(QuestionnairesContext);
  const { currentQuestionIndex, numberOfQuestions, previewMode } = useAccidentForm();
  const { t } = useTranslation("alarms");
  const [searchParams] = useSearchParams();
  const isListening = !!searchParams.get("listening");

  const { smallView } = useScreen();

  const handleForwardClick = () => {
    onForwardClick();
  };

  const handleBackClick = () => {
    if (isListening) return;
    onBackClick();
  };

  /* currentQuestionIndex !== -1 prevents header from flickering
     when index changes from 0 to value from summary  */
  return selectedAccidentForm && currentQuestionIndex !== -1 ? (
    <Header smallView={smallView}>
      <TriangleContainer>
        <Triangle
          src={chevron}
          alt=''
          onClick={handleBackClick}
          className='back'
          isHidden={currentQuestionIndex === 0}
          isBlocked={isListening}
        />
      </TriangleContainer>
      {!!numberOfQuestions ? (
        <QuestionNumber>
          {currentQuestionIndex + 1}&#x2f;{numberOfQuestions}
        </QuestionNumber>
      ) : (
        <NoQuestions>
          <WarningIcon className='warning-icon' />
          <span>{t("empty-form")}</span>
        </NoQuestions>
      )}

      <TriangleContainer forward>
        {previewMode && (
          <Triangle
            src={chevron}
            alt=''
            onClick={handleForwardClick}
            className='forward'
          />
        )}
      </TriangleContainer>
    </Header>
  ) : null;
}

export default NavHeader;
