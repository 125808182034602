import { FormTypes } from "types/types";
import usePhotosObjectStore from "./usePhotosObjectStore";

const { QUESTIONNAIRE } = FormTypes;

function useClearObjectStoreQuestionnaire() {
  const { deleteAllPhotosInIndexedDB: deleteQuestionnairePhotos } =
    usePhotosObjectStore(QUESTIONNAIRE);

  return { deleteQuestionnairePhotos };
}

export default useClearObjectStoreQuestionnaire;
