import { ReactNode } from "react";
import styled from "styled-components/macro";

import Title from "components/layout/title/Title";
import TopBar from "./top-bar/TopBar";
import SideNavbar from "./top-bar/components/SideNavbar";

const PageContainer = styled.div`
  margin-top: 5rem;
  width: 100%;
  background-color: ${({ theme }) => theme.primary_100};

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media screen and (min-height: 616px) {
    margin-top: ${({ theme }) => theme.topBarHeight};
  }
`;

function Layout({ children }: { children: ReactNode }) {
  return (
    <>
      <Title />
      <TopBar />
      <SideNavbar />
      <PageContainer>{children}</PageContainer>
    </>
  );
}

export default Layout;
