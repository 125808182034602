import styled from "styled-components/macro";

import { CreatedTask, TaskStatuses, TaskStatusOption } from "types/tasks.types";
import { getTaskStatusTranslation } from "translations/tasks.translations";

import { ReactComponent as ToDoIcon } from "assets/icons/task-status/pending_actions.svg";
import { ReactComponent as DoneIcon } from "assets/icons/task-status/done_outline.svg";
import { ReactComponent as InProgressIcon } from "assets/icons/task-status/chip_extraction.svg";
import { ReactComponent as ArchivedIcon } from "assets/icons/task-status/recycling.svg";

const { TO_DO, IN_PROGRESS, DONE, ARCHIVED } = TaskStatuses;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .status-icon {
    width: 2rem;
    height: 2rem;
    transform: translateY(0.2rem);
  }

  .to-do {
    fill: ${({ theme }) => theme.todo};
  }

  .in-progress {
    fill: ${({ theme }) => theme.inprogress};
  }

  .done {
    fill: ${({ theme }) => theme.done};
  }

  .archived {
    fill: ${({ theme }) => theme.archived};
  }
`;

type Options = {
  iconOnly?: boolean;
};

export const getStatusOptions = (
  language: string,
  options?: Options,
): TaskStatusOption[] =>
  [TO_DO, IN_PROGRESS, DONE, ARCHIVED].map((status) => {
    let icon = null;

    switch (status) {
      case TO_DO:
        icon = <ToDoIcon className='status-icon to-do' />;
        break;
      case IN_PROGRESS:
        icon = <InProgressIcon className='status-icon in-progress' />;
        break;
      case DONE:
        icon = <DoneIcon className='status-icon done' />;
        break;
      case ARCHIVED:
        icon = <ArchivedIcon className='status-icon archived' />;
    }

    return {
      id: status,
      label: (
        <Label>
          <span>{icon}</span>
          {!options?.iconOnly && (
            <span>{getTaskStatusTranslation(language, status)}</span>
          )}
        </Label>
      ),
      value: status,
    };
  });

export const getTaskStatusLabel = (
  selectedTask: CreatedTask | undefined,
  statusOptions: TaskStatusOption[],
) => {
  if (!selectedTask) {
    return null;
  }

  return statusOptions.find(({ id }) => id === selectedTask.status)?.label;
};
