import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import styled from "styled-components/macro";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";

import useNotification from "hooks/useNotification";

import { ContentContainer, Header, InputContainer } from "styles/generalStyles";

import { resetPassword } from "api/auth";
import useQueryString from "hooks/useQueryString";
import useTimeout from "hooks/useTimeout";

import { ReactComponent as EyeOpen } from "assets/icons/eye-open.svg";
import { ReactComponent as EyeClosed } from "assets/icons/eye-closed.svg";

import Input from "components/atoms/Input";
import Button from "components/atoms/Button";
import ErrorAlert from "components/molecules/ErrorAlert";
import BackArrow from "components/atoms/BackArrow";

type Token = {
  email: string;
  exp: number;
  hash: string;
  iat: number;
  iss: string;
  scope: string;
};

const Info = styled.div`
  margin-bottom: 2rem;
`;

const Label = styled.div`
  margin-bottom: 0.2rem;
`;

const Email = styled.div`
  font-size: 1.8rem;
  ${({ theme }) => theme.ellipsis};
  font-family: GothamBold;
  opacity: 0.75;
`;

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [tokenInvalid, setTokenInvalid] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [error, setError] = useState("");
  const setTimeOut = useTimeout();
  const navigate = useNavigate();
  const { t } = useTranslation(["reset-password", "common"]);
  const notify = useNotification();

  const query = useQueryString();
  const token = query.get("token") as string;

  const inputRef = useRef<HTMLInputElement>(null);

  const { mutate, isLoading } = useMutation(resetPassword, {
    onSuccess: () => {
      notify(t("notify.success.password-changed"), "success");
      setPassword("");
      setTimeOut(() => {
        navigate("/login");
      }, 3500);
    },
    onError: () => {
      notify(t("notify.error.failed-to-change-password"), "error");
    },
  });

  useEffect(() => {
    if (token) {
      const decodedJwt: Token = jwt_decode(token);
      const tokenExpired = decodedJwt.exp * 1000 < Date.now();

      setEmail(decodedJwt.email);

      if (tokenExpired) {
        setTokenInvalid(true);
      }

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [token]);

  const clearError = () => {
    if (!!error) {
      setError("");
    }
  };

  const onPasswordInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    clearError();
    setPassword(value);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const sendRequest = () => {
    const payload = {
      token,
      password,
    };

    mutate(payload);
  };

  const onButtonClick = () => {
    if (!password.length) {
      setError(t("common:error.empty-field"));
    } else if (password.length < 8) {
      setError(t("input.error.min-8-characters"));
    } else if (password.length > 50) {
      setError(t("input.error.too-long"));
    } else {
      sendRequest();
    }
  };

  const redirectToLogin = () => {
    navigate("/login");
  };

  return (
    <ContentContainer>
      <Header>
        {tokenInvalid && <BackArrow onClick={redirectToLogin} />}
        <span>{t("header.new-password")}</span>
      </Header>

      {!tokenInvalid ? (
        <>
          <Info>
            <Label>{t("info-label.reset-password-for")}</Label>
            <Email>{email}</Email>
          </Info>

          <InputContainer password marginBottom>
            <Input
              label={t("input.label.enter-new-password")}
              onChange={onPasswordInputChange}
              type={isPasswordVisible ? "text" : "password"}
              value={password}
              placeholder={t("input.placeholder")}
              paddingRight
              ref={inputRef}
              error={error}
            />

            {isPasswordVisible ? (
              <EyeOpen className='eye-icon' onClick={togglePasswordVisibility} />
            ) : (
              <EyeClosed className='eye-icon' onClick={togglePasswordVisibility} />
            )}
          </InputContainer>

          <Button
            label={t("button-label")}
            onClick={onButtonClick}
            loading={isLoading}
            disabled={isLoading}
          />
        </>
      ) : (
        <ErrorAlert message={t("alert.message")} smallMargin />
      )}
    </ContentContainer>
  );
}

export default ResetPassword;
