import { useEffect, useRef, useState } from "react";
import { useNavigate, To } from "react-router-dom";
import styled from "styled-components/macro";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

import useTimeout from "hooks/useTimeout";
import { device } from "utils/utils";

import { ReactComponent as ErrorIcon } from "assets/icons/warning-colored.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as CameraOffIcon } from "assets/icons/camera-video-off.svg";

import Button from "components/atoms/Button";

import { ContentContainer } from "styles/generalStyles";
import SpinningLoader from "components/atoms/loaders/SpinningLoader";
import { CountryCodes } from "types/types";

const Video = styled.video`
  display: inline-block;
  height: 1rem;
  background-color: #000;
  visibility: hidden;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

const ButtonContainer = styled.div`
  width: 15rem;
`;

const ErrorMessage = styled.div`
  padding: 1rem 1rem 4rem 1rem;
  margin-top: 2rem;
  color: ${({ theme }) => theme.aliceBlue};
  background-color: #000;
  border: 6px solid #e6b457;
  text-align: center;
  border-radius: 0.6rem;

  .top {
    font-size: 1.4rem;

    @media screen and (min-height: 506px) {
      font-size: 2rem;
    }
  }

  .bottom {
    font-size: 1.3rem;
    opacity: 0.9;

    @media screen and (min-height: 506px) {
      font-size: 1.8rem;
    }
  }

  .error-icon {
    width: 4rem;
    height: 4rem;
    fill: ${({ theme }) => theme.aliceBlue};
    opacity: 0.9;

    @media screen and (min-height: 506px) {
      width: 8rem;
      height: 8rem;
    }
  }
`;

const IconContainer = styled.div`
  margin: 1rem 0;
`;

const Line = styled.div`
  height: 0.1rem;
  width: 80%;
  margin: 1.5rem auto;
  background-color: ${({ theme }) => theme.graphiteGreyLight};
`;

const AppUrl = styled.div<{ copied: boolean }>`
  margin-top: 2rem;
  opacity: 0.9;
  height: 2.7rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  color: ${({ copied, theme }) => (copied ? theme.ufoGreen : "#e6b457")};

  @media screen and (min-width: 360px) {
    font-size: 2rem;
  }
`;

const CopyIconContainer = styled.div`
  height: 2.7rem;
  cursor: pointer;

  .copy-icon {
    height: 100%;
    width: 2.7rem;

    path {
      stroke: #e6b457;
    }
  }
`;

const CheckInfoContainer = styled.div`
  height: 17rem;
  margin-top: 2rem;
  padding-bottom: 1rem;
  ${({ theme }) => theme.fillUpRemainingSpace};
  justify-content: space-between;

  .header {
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;
  }
`;

const LoaderContainer = styled.div`
  ${({ theme }) => theme.fillUpRemainingSpace};
  align-items: center;
  justify-content: flex-start;
  padding-top: 3rem;
`;

const CameraPermissionDenied = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.3rem;

  .camera-icon {
    width: 3rem;
    height: 3rem;
  }

  @media screen and (min-width: 360px) {
    flex-direction: row;
  }

  @media screen and (min-width: 401px) {
    font-size: 1.6rem;
  }

  @media ${device.iPad} {
    font-size: 2rem;
  }
`;

const constraints = {
  audio: false,
  video: {
    facingMode: "environment",
  },
};

const appUrl = "https://app.snapcheck.eu";

enum CanPlayStatuses {
  FIRED = "fired",
  FAILED = "failed",
}

const { FIRED, FAILED } = CanPlayStatuses;

type CanPlayEvent = typeof FIRED | typeof FAILED | undefined;

function CameraDetection() {
  const [canPlayEvent, setCanPlayEvent] = useState<CanPlayEvent>();
  const [copied, setCopied] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [noPermissionInfoVisible, setNoPermissionInfoVisible] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(false);
  const navigate = useNavigate();
  const setTimeOut = useTimeout();

  const {
    t,
    i18n: { language },
  } = useTranslation(["camera-detection", "common"]);

  let cameraAccessDenied = "Camera access denied";

  const isPolish = language.includes(CountryCodes.PL);

  if (isPolish) {
    cameraAccessDenied = "Zablokowano dostęp do kamery";
  }

  const videoRef = useRef<HTMLVideoElement>(null);
  const timeoutId = useRef<NodeJS.Timeout>(setTimeout(() => {}, 0));

  const returnPath = sessionStorage.getItem("returnPath") as To;

  const handlePermissionDeniedError = () => {
    setNoPermissionInfoVisible(true);
    setActionsVisible(true);
    setLoaderVisible(false);
  };

  const checkIfCameraWillLaunch = async () => {
    if (!videoRef.current) return;

    const { current: cameraView } = videoRef;

    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);

      const [track] = mediaStream.getTracks();

      cameraView.srcObject = mediaStream;

      timeoutId.current = setTimeout(() => {
        track.stop();
        setCanPlayEvent(FAILED);
      }, 2000);

      cameraView.oncanplay = () => {
        track.stop();
        clearTimeout(timeoutId.current);
        setCanPlayEvent(FIRED);
      };
    } catch {
      handlePermissionDeniedError();
    }
  };

  const onCopy = () => {
    setCopied(true);
  };

  const onReturnButtonClick = () => {
    navigate(returnPath, { replace: true });
    sessionStorage.removeItem("returnPath");
    sessionStorage.removeItem("goToPath");
  };

  useEffect(() => {
    try {
      checkIfCameraWillLaunch();
    } catch {}

    return () => {
      clearTimeout(timeoutId.current);
    };
  }, []);

  useEffect(() => {
    if (copied) {
      setTimeOut(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied, setTimeOut]);

  useEffect(() => {
    setLoaderVisible(!canPlayEvent);

    if (canPlayEvent === FIRED) {
      const path = sessionStorage.getItem("goToPath") as To;

      navigate(path, { replace: true });
    }

    if (canPlayEvent === FAILED) {
      setActionsVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canPlayEvent]);

  return (
    <ContentContainer>
      <Video ref={videoRef} />

      {canPlayEvent === FAILED && (
        <ErrorMessage>
          <IconContainer>
            <ErrorIcon className='error-icon' />
          </IconContainer>
          <div className='top'>{t("alert.top")}</div>
          <Line />
          <div className='bottom'>{t("alert.bottom")}</div>

          <CopyToClipboard text={appUrl} onCopy={onCopy}>
            <AppUrl copied={copied}>
              <span>{!copied ? appUrl : t("link-copied")}</span>
              {!copied && (
                <CopyIconContainer>
                  <CopyIcon className='copy-icon' />
                </CopyIconContainer>
              )}
            </AppUrl>
          </CopyToClipboard>
        </ErrorMessage>
      )}

      <CheckInfoContainer>
        {!noPermissionInfoVisible ? (
          <div className='header'>{t("loader-header")}...</div>
        ) : (
          <CameraPermissionDenied>
            <CameraOffIcon className='camera-icon' />
            <span>{cameraAccessDenied}</span>
          </CameraPermissionDenied>
        )}
        {loaderVisible && (
          <LoaderContainer>
            <SpinningLoader large />
          </LoaderContainer>
        )}

        {actionsVisible && (
          <Actions>
            <ButtonContainer>
              <Button
                label={t("common:button.back")}
                onClick={onReturnButtonClick}
                noShaddow
              />
            </ButtonContainer>
          </Actions>
        )}
      </CheckInfoContainer>
    </ContentContainer>
  );
}

export default CameraDetection;
