import { ChangeEvent, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import {
  QUESTION_TYPE_IDS,
  QuestionType,
  Answers,
  Answer,
  YesNoAnswer,
  FormType,
  ErrorPosition,
} from "types/types";
import useTimeout from "hooks/useTimeout";
import useScreen from "hooks/useScreen";
import useModal from "hooks/useModal";
import usePhotosObjectStore from "hooks/usePhotosObjectStore";
import useDictaphoneAccess from "hooks/useDictaphoneAccess";
import {
  device,
  isMobile,
  checkIfAnswerContainsTextReaction,
  MAX_INT_VALUE,
  scrollToBottom,
} from "utils/utils";

import Input from "components/atoms/Input";
import Button from "components/atoms/Button";
import ButtonLabel from "./components/ButtonLabel";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import WarningMessage from "./components/WarningMessage";
import Dictaphone from "../Dictaphone";

import { TextArea } from "styles/generalStyles";
import { getTranslations } from "./translations/question-actions.translations";

const Container = styled.div<{
  isFocused: boolean;
  isMobile: boolean;
  isHeightSet: boolean;
}>`
  width: 100%;
  padding-bottom: 1rem;

  ${({ isHeightSet }) =>
    isHeightSet &&
    css`
      height: 100%;
    `}

  display: ${({ isMobile, isFocused }) =>
    !isMobile ? "grid" : isMobile && isFocused ? "block" : "grid"};

  @media ${device.iPad} {
    display: ${({ isFocused }) => (isFocused ? "block" : "grid")};
  }
`;

const Actions = styled.div<{ spaceBetween?: boolean }>`
  display: flex;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? "space-between" : "flex-end")};
  align-items: center;
  padding-top: 0.7rem;
  width: 100%;
  place-self: end;

  .btn {
    flex-basis: 50%;
  }

  .enlarged {
    flex-basis: 80%;
  }

  .shrinked {
    flex-basis: 20%;
    height: 4.7rem;
    opacity: 0.5;
    filter: grayscale(40%);

    @media screen and (min-height: 508px) {
      height: 5.7rem;
    }
  }
`;

const ButtonContainer = styled.div<{ smallView: boolean }>`
  width: ${({ smallView }) => (smallView ? "12rem" : "14rem")};
`;

type QuestionActionsProps = {
  formType: FormType;
  questionType: QuestionType;
  onYesClick: () => void;
  onNoClick: () => void;
  onNextClick: () => void;
  onTextTranscriptChange: (value: string) => void;
  onAnswerInputChange: (value: string) => void;
  answerInputValue: string;
  onAnswerTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  answerTextAreaValue: string;
  yesNoAnswer: string;
  answerToCurrentQuestion: Answer | undefined;
};

const { FILE_PHOTO, NUMERIC, TEXT, YES_NO_PHOTO, YES_NO_TEXT } = QUESTION_TYPE_IDS;
const { YES, NO } = Answers;
const { BOTTOM_LEFT } = ErrorPosition;

function QuestionActions({
  formType,
  questionType,
  onYesClick,
  onNoClick,
  onNextClick,
  onTextTranscriptChange,
  onAnswerInputChange,
  answerInputValue = "",
  onAnswerTextAreaChange,
  answerTextAreaValue = "",
  yesNoAnswer,
  answerToCurrentQuestion,
}: QuestionActionsProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [numberInputError, setNumberInputError] = useState({ message: "" });
  const [delayedButton, setDelayedButton] = useState<YesNoAnswer | null>(null);
  const { open, openModal, closeModal } = useModal();
  const { isSmallScreen, smallView } = useScreen();
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { error } = getTranslations(language);

  const [searchParams] = useSearchParams();
  const isListening = !!searchParams.get("listening");

  const { updatePhotosInIndexedDB, checkIfContainsPhotos } =
    usePhotosObjectStore(formType);

  const { isDictaphoneAccessible } = useDictaphoneAccess();

  const setTimeOut = useTimeout();

  const rows = isSmallScreen ? 3 : 5;
  const Question = answerToCurrentQuestion?.Question ?? "";

  let content;

  // --------------- Effect handlers ---------------

  useEffect(() => {
    const btn = searchParams.get("delayButton") as YesNoAnswer;

    setDelayedButton(btn);
  }, [searchParams]);

  useEffect(() => {
    if (!textareaRef.current) return;

    const { current: textArea } = textareaRef;
    // const textLength = answerTextAreaValue ? answerTextAreaValue.length : 0;

    scrollToBottom(textArea);
    // placeCursorAtTheEnd(textArea, textLength);
  }, [answerTextAreaValue]);

  // --------------- On click handlers ---------------

  const handleYesClick = async () => {
    switch (questionType) {
      case YES_NO_PHOTO:
        const containsPhotos = await checkIfContainsPhotos(Question);

        if (containsPhotos) {
          openModal();
        } else {
          onYesClick();
        }

        break;
      case YES_NO_TEXT:
        if (checkIfAnswerContainsTextReaction(answerToCurrentQuestion)) {
          openModal();
        } else {
          onYesClick();
        }
        break;
      default:
        onYesClick();
    }
  };

  const onConfirmationClick = () => {
    closeModal();
    onYesClick();
    if (Question) {
      updatePhotosInIndexedDB({ Question, FilesGallery: null });
    }
  };

  // --------------- Event handlers ---------------

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setTimeOut(() => {
      setIsFocused(false);
    }, 100);
  };

  const onNumberInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(e.target.value);

    if (isNaN(inputValue)) return;

    const answerValue =
      inputValue !== Infinity ? `${Math.abs(Math.trunc(inputValue))}` : answerInputValue;

    onAnswerInputChange(answerValue);

    if (inputValue > MAX_INT_VALUE) {
      setNumberInputError({ message: error.number_too_large });
    } else {
      if (numberInputError.message) {
        setNumberInputError({ message: "" });
      }
    }
  };

  // --------------- Content ---------------

  switch (questionType) {
    case FILE_PHOTO:
      content = (
        <Actions>
          <ButtonContainer smallView={smallView}>
            <Button label={t("button.next")} large question onClick={onNextClick} />
          </ButtonContainer>
        </Actions>
      );
      break;

    case NUMERIC:
      content = (
        <>
          <Input
            large
            onChange={onNumberInputChange}
            value={answerInputValue}
            type='tel'
            onFocus={onFocus}
            onBlur={onBlur}
            error={numberInputError.message}
            name='number-input'
            errorPosition={isMobile ? BOTTOM_LEFT : undefined}
            alignRight
          />
          <Actions>
            <ButtonContainer smallView={smallView}>
              <Button
                label={t("button.next")}
                large
                question
                onClick={onNextClick}
                disabled={!!numberInputError.message}
              />
            </ButtonContainer>
          </Actions>
        </>
      );
      break;

    case TEXT:
      content = (
        <>
          <TextArea
            rows={rows}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onAnswerTextAreaChange}
            value={answerTextAreaValue}
            ref={textareaRef}
          />
          <Actions spaceBetween={isDictaphoneAccessible}>
            {isDictaphoneAccessible && (
              <Dictaphone
                onTextTranscriptChange={onTextTranscriptChange}
                questionType={questionType}
                answerTextAreaValue={answerTextAreaValue}
              />
            )}
            <ButtonContainer smallView={smallView}>
              <Button
                label={t("button.next")}
                large
                question
                onClick={onNextClick}
                disabled={isListening}
              />
            </ButtonContainer>
          </Actions>
        </>
      );
      break;

    default:
      content = (
        <Actions spaceBetween>
          <Button
            label={
              <ButtonLabel
                selected={yesNoAnswer === NO}
                noText={yesNoAnswer === YES}
                down
              />
            }
            large
            noButton
            marginRight
            onClick={onNoClick}
            answered={yesNoAnswer === NO}
            showProgress={delayedButton === NO}
            customClass={`btn ${
              yesNoAnswer === NO ? "enlarged" : yesNoAnswer === YES ? "shrinked" : ""
            }`}
          />

          <Button
            label={
              <ButtonLabel
                selected={yesNoAnswer === YES}
                noText={yesNoAnswer === NO}
                up
              />
            }
            large
            yesButton
            marginLeft
            onClick={handleYesClick}
            answered={yesNoAnswer === YES}
            showProgress={delayedButton === YES}
            customClass={`btn ${
              yesNoAnswer === YES ? "enlarged" : yesNoAnswer === NO ? "shrinked" : ""
            }`}
          />
        </Actions>
      );
  }

  return (
    <Container
      isFocused={isFocused}
      isMobile={isMobile}
      isHeightSet={questionType === TEXT || questionType === NUMERIC}
    >
      {content}

      <ConfirmationModal
        message={<WarningMessage photo={questionType === YES_NO_PHOTO} />}
        onClick={onConfirmationClick}
        onNoClick={closeModal}
        onClose={closeModal}
        open={open}
        buttonLabel={t("button.yes")}
      />
    </Container>
  );
}

export default QuestionActions;
