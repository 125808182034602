import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";
import { device } from "utils/utils";

type WarningMessageProps = {
  photo?: boolean;
};

const Container = styled.div`
  .warn-icon {
    width: 3.5rem;
    fill: ${({ theme }) => theme.orange};
  }

  .line {
    &-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      font-size: 1.8rem;
      font-family: GothamBold;
      text-align: center;

      @media ${device.iPad} {
        font-size: 2rem;
      }
    }

    &-bottom {
      margin-top: 2rem;
      margin-bottom: 3rem;
      text-align: center;
      line-height: 1;
      font-size: 1.9rem;

      @media ${device.iPad} {
        margin-bottom: 4rem;
        font-size: 2.2rem;
      }
    }
  }
`;

function WarningMessage({ photo }: WarningMessageProps) {
  const { t } = useTranslation("questionnaires");

  return (
    <Container>
      <div className='line-top'>
        <WarningIcon className='warn-icon' />
        <div>
          {t("warning-popup.message-start")}&nbsp;
          {photo
            ? t("warning-popup.message-end.photos")
            : t("warning-popup.message-end.description")}
          .
        </div>
      </div>
      <div className='line-bottom'>{t("warning-popup.question")}</div>
    </Container>
  );
}

export default WarningMessage;
