import { useTranslation } from "react-i18next";

import { isMobile } from "utils/utils";
import { CountryCodes } from "types/types";

import Button from "components/atoms/Button";

import {
  BottomPanel as Container,
  ButtonContainer,
  InnerCircle,
  OuterCircle,
  Photo,
  PhotoCircles,
  PhotoCounter,
  PhotoPreview,
} from "../styles/styles";

type BottomPanelProps = {
  disabled: boolean;
  maxPhotos: number | null | undefined;
  photoPreview: { src: string; count: number };
  onPhotoPreviewClick: () => void;
  handleCloseCameraView: (() => void) | undefined;
  onTakePhotoCircleClick: () => void;
};

function BottomPanel({
  disabled,
  maxPhotos,
  photoPreview,
  onPhotoPreviewClick,
  handleCloseCameraView,
  onTakePhotoCircleClick,
}: BottomPanelProps) {
  const { i18n } = useTranslation("common");
  const closeButtonLabel = i18n.language.includes(CountryCodes.EN) ? "Done" : "Gotowe";

  const onPhotoCircleClick = () => {
    if (!disabled) {
      onTakePhotoCircleClick();
    }
  };

  return (
    <Container isMobile={isMobile}>
      <PhotoPreview onClick={onPhotoPreviewClick}>
        {!!photoPreview.src && <Photo src={photoPreview.src} alt='' />}
      </PhotoPreview>
      <PhotoCounter>
        {photoPreview.count}/{maxPhotos}
      </PhotoCounter>

      <PhotoCircles onClick={onPhotoCircleClick}>
        <OuterCircle>
          <InnerCircle />
        </OuterCircle>
      </PhotoCircles>

      <ButtonContainer>
        <Button
          label={closeButtonLabel}
          onClick={handleCloseCameraView}
          customClass='return-button'
        />
      </ButtonContainer>
    </Container>
  );
}

export default BottomPanel;
