import { CountryCodes } from "types/types";
import { PromptType } from "../types/prompt.types";

const { TEXT, PHOTO } = PromptType;

export const getPromptTranslations = (promptType: PromptType, language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let label = "";

  switch (promptType) {
    case TEXT:
      label = "Add description";
      if (isPolish) label = "Dodaj opis";

      break;
    case PHOTO:
      label = "Add photo";
      if (isPolish) label = "Dodaj zdjęcie";
  }

  return label;
};
