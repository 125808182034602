import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";

import useMachineProcessInfo from "hooks/useMachineProcessInfo";

const Container = styled.div<{ marginTop?: boolean; noMarginTop?: boolean }>`
  margin-top: 1rem;
  padding: 0.5rem 0;
  font-size: 2rem;

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 2.7rem;
    `}

  ${({ noMarginTop }) =>
    noMarginTop &&
    css`
      margin-top: 0;
    `}
`;

const Line = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
`;

const Label = styled.span`
  font-size: 1.7rem;
`;

const Name = styled.span`
  font-family: Gothambold;
  text-transform: uppercase;
  font-size: 1.7rem;
`;

function EntityInfo({
  marginTop = false,
  noMarginTop = false,
}: {
  marginTop?: boolean;
  noMarginTop?: boolean;
}) {
  const { entity, name } = useMachineProcessInfo();
  const { t } = useTranslation("machine-process-info");

  return (
    <Container marginTop={marginTop} noMarginTop={noMarginTop}>
      <Line>
        {entity && <Label>{t(`tabs.${entity.toLowerCase()}`)}:</Label>}
        <Name>{name}</Name>
      </Line>
    </Container>
  );
}

export default EntityInfo;
