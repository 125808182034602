import { useNavigate } from "react-router-dom";

import { ReturnPaths } from "types/return-paths.types";

const { ALARMS_RETURN_PATH, PROFILE_RETURN_PATH, TASKS_RETURN_PATH } = ReturnPaths;

function useReturnPaths() {
  const navigate = useNavigate();

  const handleNavigate = (returnPath: ReturnPaths) => {
    let navigatePath = "";

    switch (returnPath) {
      case ALARMS_RETURN_PATH:
        navigatePath = "/accident-forms-list";
        break;
      case PROFILE_RETURN_PATH:
        navigatePath = "/profile";
        break;
      case TASKS_RETURN_PATH:
        navigatePath = "/tasks";
        break;
    }

    navigate(navigatePath);
  };

  const handleOpenPage = (returnPath: ReturnPaths, currentPath: string) => {
    const existingReturnPath = localStorage.getItem(returnPath);

    if (existingReturnPath) {
      const returnPathsArray = JSON.parse(localStorage.getItem(returnPath) as string);
      const updatedPathsArray = JSON.stringify([...returnPathsArray, currentPath]);

      localStorage.setItem(returnPath, updatedPathsArray);
    } else {
      const tasksReturnPath = JSON.stringify([currentPath]);
      localStorage.setItem(returnPath, tasksReturnPath);
    }

    handleNavigate(returnPath);
  };

  const handleClosePage = (returnPath: ReturnPaths) => {
    const pathsArray: string[] = JSON.parse(localStorage.getItem(returnPath) as string);
    const path = pathsArray.pop() as string;

    if (pathsArray.length) {
      localStorage.setItem(returnPath, JSON.stringify(pathsArray));
    } else {
      localStorage.removeItem(returnPath);
    }

    navigate(path, { replace: true });
  };

  return { handleOpenPage, handleClosePage };
}

export default useReturnPaths;
