import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import useScreen from "hooks/useScreen";
import { isMobile } from "utils/utils";

import Button from "components/atoms/Button";

const Container = styled.div`
  border: 4px solid green;

  @media (min-height: 1200px) {
    border: 4px solid red;
  }
`;

const ButtonContainer = styled.div`
  width: 15rem;
`;

function System() {
  const { orientation } = useScreen();
  const navigate = useNavigate();

  return (
    <Container>
      <br />

      <h3>System</h3>
      <div>window.screen.width: {window.screen.width}</div>
      <div>window.screen.height: {window.screen.height}</div>

      <br />

      <div>use these in media query</div>
      <div>window.innerWidth: {window.innerWidth}</div>
      <div>window.innerHeight: {window.innerHeight}</div>

      <br />
      <div>
        {isMobile ? "MOBLIE" : "DESKTOP"} / {orientation}
      </div>
      <br />
      <br />

      <ButtonContainer>
        <Button
          label='Go to serial number'
          onClick={() => {
            navigate("/enter-serial-number");
          }}
        />
      </ButtonContainer>
    </Container>
  );
}

export default System;
