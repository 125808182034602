import { useState, useEffect } from "react";

import { CountryCode } from "types/types";

const getBrowserLanguage = () => {
  const { language: browserLng } = navigator;
  return browserLng.split("-")[0] as CountryCode;
};

const getLanguageFromLocalStorage = () => {
  return localStorage.getItem("language") as CountryCode;
};

const getAppLanguage = () => {
  const languageInLocalStorage = getLanguageFromLocalStorage();

  if (languageInLocalStorage) {
    return languageInLocalStorage;
  }

  return getBrowserLanguage();
};

function useLanguage() {
  const [language, setLanguage] = useState<CountryCode>(getAppLanguage());

  const setAppLanguage = (code: CountryCode) => {
    setLanguage(code);
  };

  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  return { language, setAppLanguage };
}

export default useLanguage;
