import styled from "styled-components/macro";

import { FilesGallery } from "types/types";

import BackArrow from "components/atoms/BackArrow";

export const Container = styled.div`
  padding: 2.5rem 0;
  position: relative;

  .close-icon {
    width: 3rem;
    height: 3rem;
    fill: ${({ theme }) => theme.primary};

    position: absolute;
    top: 1rem;
    right: 0.3rem;
  }

  .arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

export const Counter = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
`;

type TopBarProps = {
  photos: FilesGallery | null | undefined;
  photosVisible: boolean;
  currentIndex: number;
  onClick: () => void;
};

function TopBar({ photos, photosVisible, currentIndex, onClick }: TopBarProps) {
  return (
    <Container>
      <BackArrow onClick={onClick} customClass='arrow-icon' />
      {photosVisible && (
        <Counter>
          {currentIndex + 1}&nbsp;&#x2215;&nbsp;{photos?.length}
        </Counter>
      )}
    </Container>
  );
}

export default TopBar;
