import styled from "styled-components/macro";

const Container = styled.div<{ delay?: number }>`
  .progress {
    margin: 0 auto;
    max-width: 40rem;
    text-align: center;
  }

  .track {
    padding: 0.4rem;
    border-radius: 3rem;
    background: rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  }

  .bar {
    height: 1rem;
    border-radius: 3rem;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.05)
    );
    transition: 0.4s linear;
    transition-property: width, background-color;
  }

  .moved .bar {
    width: 100%;
    background-color: ${({ theme }) => theme.azure};
    animation: progressAnimation ${({ delay }) => (delay ? `${delay}ms` : `2s`)};
  }

  @keyframes progressAnimation {
    0% {
      width: 5%;
      background-color: ${({ theme }) => theme.primary};
    }
    100% {
      width: 100%;
      background-color: ${({ theme }) => theme.azure};
    }
  }
`;

function ProgressBar({ delay }: { delay?: number }) {
  return (
    <Container delay={delay}>
      <div className='progress'>
        <div className='track moved'>
          <div className='bar' />
        </div>
      </div>
    </Container>
  );
}

export default ProgressBar;
