import { ReactNode } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { ReactComponent as QrCodeIcon } from "assets/icons/qr-code-4.svg";

import SnapCheckTitle from "components/atoms/logos/SnapCheckTitle";
import AppFooter from "components/atoms/AppFooter";

const Container = styled.div`
  padding: 0 2rem;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 2.5rem;

  .icon {
    width: 5rem;
    fill: ${({ theme }) => theme.primary};
  }

  .info {
    color: ${({ theme }) => theme.primary};
    font-size: 2rem;
  }
`;

type QrLoginContainerProps = {
  children: ReactNode;
};

function QrLoginContainer({ children }: QrLoginContainerProps) {
  const { t } = useTranslation("login");

  return (
    <Container>
      <SnapCheckTitle />

      <InfoContainer>
        <QrCodeIcon className='icon' />
        <span className='info'>{t("login-with-qr.header")}</span>
      </InfoContainer>
      {children}
      <AppFooter />
    </Container>
  );
}

export default QrLoginContainer;
