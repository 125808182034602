import { ActionType, AuthState, QrTokenStatus } from "../types";

export const init = (initialAuth: boolean) => ({
  authenticated: initialAuth,
  shouldReAuth: false,
  redirectPath: "", // Set if user has scanned QR code before logging in
  inviteEmail: "",
  qrTokenStatus: null,
});

// ----- Action types -----

type ClearAuthStateAction = {
  type: ActionType.CLEAR_AUTH_STATE;
};

type SetAuthenticatedAction = {
  type: ActionType.SET_AUTHENTICATED;
  payload: boolean;
};

type SetShouldReAuthAction = {
  type: ActionType.SET_SHOULD_REAUTH;
  payload: boolean;
};

type SetRedirectPathAction = {
  type: ActionType.SET_REDIRECT_PATH;
  payload: string;
};

type SetInviteEmailAction = {
  type: ActionType.SET_INVITE_EMAIL;
  payload: string;
};

type SetQrTokenStatusAction = {
  type: ActionType.SET_QR_TOKEN_STATUS;
  payload: QrTokenStatus;
};

type Actions =
  | ClearAuthStateAction
  | SetAuthenticatedAction
  | SetShouldReAuthAction
  | SetRedirectPathAction
  | SetInviteEmailAction
  | SetQrTokenStatusAction;

export const authReducer = (state: AuthState, action: Actions) => {
  switch (action.type) {
    case ActionType.CLEAR_AUTH_STATE:
      return init(false);
    case ActionType.SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.payload,
      };
    case ActionType.SET_SHOULD_REAUTH:
      return {
        ...state,
        shouldReAuth: action.payload,
      };
    case ActionType.SET_REDIRECT_PATH:
      return {
        ...state,
        redirectPath: action.payload,
      };
    case ActionType.SET_INVITE_EMAIL:
      return {
        ...state,
        inviteEmail: action.payload,
      };
    case ActionType.SET_QR_TOKEN_STATUS:
      return {
        ...state,
        qrTokenStatus: action.payload,
      };
  }
};
