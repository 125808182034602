import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuestionnaireObject } from "types/types";

const initialState = {
  serialNumber: "",
  userCheckLists: [] as QuestionnaireObject[],
};

export const mainSlice = createSlice({
  name: "mainSlice",
  initialState,
  reducers: {
    setSerialNumber(state, action: PayloadAction<string>) {
      return { ...state, serialNumber: action.payload };
    },
    setUserCheckLists(state, action: PayloadAction<QuestionnaireObject[]>) {
      return { ...state, userCheckLists: action.payload };
    },
  },
});

export const { setSerialNumber, setUserCheckLists } = mainSlice.actions;
