import { CountryCodes } from "types/types";

export const getManagerOptionsTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let header = {
    label: "Status Change",
  };

  let modal = {
    message: {
      variant_1: "Do you want to save your change?",
      variant_2: "Do you want to exit to home page?",
    },
    btnLabel: {
      save: "Save",
      finish: "Exit",
    },
  };

  let actionBtn = {
    label: {
      variant_1: "Return",
      variant_2: "Save change",
    },
  };

  let alert = {
    success: {
      status: "Status has been changed!",
    },
    error: {
      status: "Unable to change status...",
      assignment: "Unable to assign user...",
      comment: "Unable to add comment...",
    },
  };

  if (isPolish) {
    header = {
      label: "Zmiana Statusu",
    };

    modal = {
      message: {
        variant_1: "Czy chcesz zapisać zmianę?",
        variant_2: "Czy chcesz zakończyć i wrócić do strony głównej?",
      },
      btnLabel: {
        save: "Zapisz",
        finish: "Zakończ",
      },
    };

    actionBtn = {
      label: {
        variant_1: "Powrót",
        variant_2: "Zapisz zmianę",
      },
    };

    alert = {
      success: { status: "Status został zmieniony!" },
      error: {
        status: "Nie udało się zmienić statusu...",
        assignment: "Nie udało się przypisać użytkownika...",
        comment: "Nie udało się dodać komentarza...",
      },
    };
  }

  return {
    header,
    modal,
    actionBtn,
    alert,
  };
};

// export const getAssignToUserTranslations = (language: string) => {
//   const isEnglish = language.includes(CountryCodes.EN);

//   let title = "Przypisz do";
//   let selectPlaceholder = "Wybierz użytkownika...";
//   let getUsersError = "Nie można załadować listy użytkowników";
//   let selectNoOptionsMessage = "Brak opcji...";

//   if (isEnglish) {
//     title = "Assign to";
//     selectPlaceholder = "Select user...";
//     getUsersError = "Unable to load users list";
//     selectNoOptionsMessage = "No options...";
//   }

//   return {
//     title,
//     selectPlaceholder,
//     getUsersError,
//     selectNoOptionsMessage,
//   };
// };

export const getAssignmentTranslations = (language: string) => {
  const isEnglish = language.includes(CountryCodes.EN);

  let assignedToLabel = "Przypisane do";
  let actions = { btn: { label: "Przypisz" } };
  let modal = { message: "Usunąć przypisanie?", btn: { label: "Usuń" } };
  let alert = {
    success: { message: "Usunięto przypisanie" },
    error: { message: "Nie można usunąć przypisania" },
  };

  if (isEnglish) {
    assignedToLabel = "Assigned to";
    actions = { btn: { label: "Assign" } };
    modal = {
      message: "Do you want to remove assignment?",
      btn: { label: "Remove" },
    };
    alert = {
      success: { message: "Assignment has been removed" },
      error: { message: "Unable to remove assignment" },
    };
  }

  return {
    assignedToLabel,
    actions,
    modal,
    alert,
  };
};

export const getCommentTranslations = (language: string) => {
  const isEnglish = language.includes(CountryCodes.EN);

  let header = {
    label: "Komentarz",
  };
  let button = {
    add: "Dodaj",
    delete: "Usuń",
  };
  let modal = {
    message: {
      deleteComment: "Usunąć komentarz?",
    },
  };
  let alert = {
    addComment: {
      error: "Nie można dodać komentarza",
    },
  };

  if (isEnglish) {
    header = {
      label: "Comment",
    };
    button = {
      add: "Add",
      delete: "Delete",
    };
    modal = {
      message: {
        deleteComment: "Do you want to delete the comment?",
      },
    };
    alert = {
      addComment: {
        error: "Unable to add comment",
      },
    };
  }

  return {
    header,
    button,
    modal,
    alert,
  };
};
