export type QrTokenStatus = "valid" | "invalid" | null;

export type AuthState = {
  authenticated: boolean;
  shouldReAuth: boolean;
  redirectPath: string;
  inviteEmail: string;
  qrTokenStatus: QrTokenStatus;
};

export enum ActionType {
  CLEAR_AUTH_STATE = "CLEAR_AUTH_STATE",
  SET_AUTHENTICATED = "SET_AUTHENTICATED",
  SET_SHOULD_REAUTH = "SET_SHOULD_REAUTH",
  SET_REDIRECT_PATH = "SET_REDIRECT_PATH",
  SET_INVITE_EMAIL = "SET_INVITE_EMAIL",
  SET_QR_TOKEN_STATUS = "SET_QR_TOKEN_STATUS",
}
