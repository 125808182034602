import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { ReactComponent as ErrorIcon } from "assets/icons/error-2.svg";

import { UserMediaError } from "types/types";

import { ErrorContainer as Container } from "../styles/styles";

import Button from "components/atoms/Button";

const Content = styled.div`
  width: 90%;
  height: 90%;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem 2rem;
  border-radius: 0.6rem;
  border: 2px solid ${({ theme }) => theme.errorColor};
  position: relative;
`;

const ErrorName = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
  color: ${({ theme }) => theme.errorColor};
  font-size: 2rem;
  font-family: GothamBold;

  .error-icon {
    width: 2.5rem;
    height: 2.5rem;
    fill: ${({ theme }) => theme.errorColor};
  }
`;

const ErrorMessage = styled.div`
  margin: 1rem 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;

  .close-btn {
    background-color: ${({ theme }) => theme.royalBlue};
    color: ${({ theme }) => theme.aliceBlue};
    font-size: 1.6rem;
    width: 11rem;
    height: 4rem;
  }
`;

type UserMediaErrorProps = {
  userMediaError: UserMediaError;
  onCloseButtonClick: () => void;
};

function ErrorAlert({ userMediaError, onCloseButtonClick }: UserMediaErrorProps) {
  const { t } = useTranslation("common");

  return (
    <Container>
      <Content>
        <ErrorName>
          <ErrorIcon className='error-icon' />
          <span>{userMediaError.name}</span>
        </ErrorName>
        <ErrorMessage>{userMediaError.message}</ErrorMessage>

        <ButtonContainer>
          <Button
            label={t("button.close")}
            customClass='close-btn'
            onClick={onCloseButtonClick}
          />
        </ButtonContainer>
      </Content>
    </Container>
  );
}

export default ErrorAlert;
