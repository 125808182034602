import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";

import { getAppSettings, ping } from "api/settings";
import { AppSettingsResponse } from "types/types";
import { AxiosError } from "axios";

function useAppSettings() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let interval = useRef<NodeJS.Timer>(setInterval(() => {}, 0));

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const onSuccess = () => {
    setSettingsLoaded(true);

    if (pathname.includes("/server-off-alert")) {
      navigate("/login", { replace: true });
    }
  };

  const onError = (err: AxiosError) => {
    const { response } = err;

    if (response && response.status >= 500) {
      navigate("/server-off-alert", { replace: true });
      clearInterval(interval.current);
    }
  };

  const { data, error, refetch, remove, failureCount } = useQuery<
    AppSettingsResponse,
    AxiosError
  >("appSettings", getAppSettings, {
    onError,
    onSuccess,
    keepPreviousData: false,
    refetchOnMount: true,
  });

  // const { refetch: reping } = useQuery<any>("ping", ping, {});

  useEffect(() => {
    interval.current = setInterval(() => {
      refetch();
    }, 30000);

    return () => clearInterval(interval.current);
  }, [refetch, interval]);

  const appVersionString = data?.data.MobileAppVersion ?? "";

  let appVersionFromServer = {
    major: 0,
    minor: 0,
    patch: 0,
  };

  if (appVersionString) {
    const appVersionArray = appVersionString.split(".");
    const major = Number(appVersionArray[0]);
    const minor = Number(appVersionArray[1]);
    const patch = Number(appVersionArray[2]);

    appVersionFromServer = {
      major,
      minor,
      patch,
    };
  }

  return {
    data,
    appVersionFromServer,
    error,
    refetch,
    remove,
    settingsLoaded,
    failureCount,
  };
}

export default useAppSettings;
