import styled, { css } from "styled-components/macro";

import { isMobile } from "utils/utils";

import { ReactComponent as ChevronIcon } from "assets/icons/chevron-down.svg";

const Container = styled.div<{
  expanded: boolean;
  bottom?: boolean;
  isMobile: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

  .chevron-icon {
    width: 2.5rem;
    transform: translateX(0.4rem);

    path {
      stroke: ${({ theme }) => theme.primary};
    }

    ${({ bottom }) =>
      bottom &&
      css`
        transform: translateX(0.4rem) rotate(180deg);
      `}

    ${({ expanded, bottom }) =>
      expanded &&
      !bottom &&
      css`
        transform: translateX(0.4rem) rotate(180deg);
      `}
  }
`;

type TogglePhotosButtonProps = {
  id: string;
  expanded: boolean;
  toggleAccordion: (id: string) => () => void;
  bottom?: boolean;
};

function ChevronToggle({
  id,
  expanded,
  toggleAccordion,
  bottom = false,
}: TogglePhotosButtonProps) {
  return (
    <Container
      isMobile={isMobile}
      expanded={expanded}
      onClick={toggleAccordion(id)}
      bottom={bottom}
    >
      <ChevronIcon className='chevron-icon' />
    </Container>
  );
}

export default ChevronToggle;
