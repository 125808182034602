import styled from "styled-components/macro";

import { ReactComponent as Close } from "assets/icons/close.svg";

const Container = styled.div<{ disabled: boolean }>`
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 0.5rem;
  right: -0.5rem;

  z-index: ${({ theme }) => theme.level1};

  @media screen and (min-height: 616px) {
    width: 3rem;
    height: 3rem;
    top: 0.7rem;
    right: 0.9rem;
  }

  .close-icon {
    width: 100%;
    height: 100%;
    transform: translate(0.1rem, -0.3rem);
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

    @media screen and (min-height: 616px) {
      transform: translate(1.5rem, -1rem);
    }
  }
`;

type CloseIconProps = {
  onClick: () => void;
  disabled?: boolean;
};

function CloseIcon({ onClick, disabled = false }: CloseIconProps) {
  return (
    <Container disabled={disabled}>
      <Close className='close-icon' onClick={onClick} />
    </Container>
  );
}

export default CloseIcon;
