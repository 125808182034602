import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import { AxiosError, AxiosProgressEvent } from "axios";
import { getCookie } from "react-use-cookie";
import { useTranslation } from "react-i18next";
import { differenceInMilliseconds } from "date-fns";

import {
  Assessment,
  QuestionnairePayload,
  QUESTION_TYPE_IDS,
  Answers,
  SendQuestionnaireResponse,
  FormTypes,
  Answer,
} from "types/types";
import { ACCESS_TOKEN, DEVICE_INFO_STRING, checkIfPhotoType } from "utils/utils";
import { sendQuestionnaire } from "api/questionnaires";
import AuthContext from "contexts/auth-context/AuthContext";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import useModal from "hooks/useModal";
import useScreen from "hooks/useScreen";
import useTimeout from "hooks/useTimeout";
import useNotification from "hooks/useNotification";
import useHandleBackToHomePage from "hooks/useHandleBackToHomePage";
import { useAppDispatch } from "redux/hooks/hooks";
import usePhotosForAnswers from "hooks/usePhotosForAnswers";
import useAdditionalOptionsAccess from "hooks/useAdditionalOptionsAccess";
import packageInfo from "../../../package.json";
import { setAssessmentResponse } from "redux/slices/assessmentSlice";

import Button from "components/atoms/Button";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import Statistics from "./components/Statistics";
import GravityAlert from "./components/GravityAlert";
import EntityInfo from "components/molecules/EntityInfo";
import ReportIncompleteInfo from "./components/ReportIncompleteInfo";
import SuccessAnimated from "components/molecules/success-animated/SuccessAnimated";
import UploadProgressBar from "components/atoms/upload-progress-bar/UploadProgressBar";

import chevron from "assets/icons/navigation-chevron.svg";

import { ContentContainer, Wrapper } from "styles/generalStyles";
import { ModalStyles } from "styles/generalStyles";
import {
  Header,
  Triangle,
  SummaryLabel,
} from "pages/questionnaire/styles/questionnaireStyles";
import { ActionsWrapper, SummaryLabelContent } from "./styles/summaryStyles";

const { Actions, ButtonContainer } = ModalStyles;

const { YES_NO, YES_NO_TEXT, YES_NO_PHOTO } = QUESTION_TYPE_IDS;
const { YES, NO } = Answers;
const { QUESTIONNAIRE } = FormTypes;

function Summary() {
  const [percentage, setPercentage] = useState({ positive: 0, negative: 0 });
  const [assessment, setAssessment] = useState<Assessment | undefined>();
  const [gravityAlertVisible, setGravityAlertVisible] = useState(false);
  const [gravityAlertConfirmed, setGravityAlertConfirmed] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const { photosForAnswers } = usePhotosForAnswers(QUESTIONNAIRE);
  const { hasAccessToAdditionalOptions } = useAdditionalOptionsAccess();

  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation(["summary", "common"], { useSuspense: true });
  const setTimeOut = useTimeout();

  const reportSent = !!searchParams.get("assessment");

  const {
    state: { answers, machineProcess, serialNumber, startTime, selectedQuestionnaire },
    numberOfQuestions,
  } = useContext(QuestionnairesContext);
  const { setShouldReAuth } = useContext(AuthContext);

  const Machine = machineProcess?.Machine ? machineProcess?.Machine.id : null;
  const Process = machineProcess?.Process ? machineProcess?.Process.id : null;

  const navigate = useNavigate();

  const { open, openModal, closeModal } = useModal();
  const { smallView } = useScreen();
  const deviceInfoString = localStorage.getItem(DEVICE_INFO_STRING);

  const { handleBackToHomePage } = useHandleBackToHomePage();
  const notify = useNotification();
  const dispatch = useAppDispatch();
  const token = getCookie(ACCESS_TOKEN);
  const endTimeRef = useRef<Date>();

  const allQwestionsAnswered = numberOfQuestions === answers.length;

  let summaryLabel = (
    <SummaryLabelContent>{t("header.report-incomplete")}</SummaryLabelContent>
  );

  if (allQwestionsAnswered) {
    summaryLabel = (
      <SummaryLabelContent>{t("header.report-complete")}</SummaryLabelContent>
    );
  }

  // --------------- On success handler ---------------

  const onSuccess = (data: SendQuestionnaireResponse) => {
    const { assessment } = data;

    setSearchParams({ assessment });
    dispatch(setAssessmentResponse(data));
  };

  // --------------- On error handler ---------------

  const onError = (error: AxiosError) => {
    const { response, message } = error;

    if (response?.status === 401) {
      setShouldReAuth(true);
    } else {
      notify(message, "error");
    }
  };

  // --------------- API request ---------------

  const { mutate, isLoading } = useMutation<
    SendQuestionnaireResponse,
    AxiosError,
    QuestionnairePayload
  >(sendQuestionnaire, {
    onSuccess,
    onError,
  });

  // --------------- API handler ---------------

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    const value = Math.ceil((progressEvent.progress as number) * 100);

    setUploadProgress(value);
  };

  const handleSendAssessment = () => {
    if (!endTimeRef.current) {
      return;
    }

    const Result = +percentage.positive.toFixed(2);
    const AppVersion = packageInfo.version;
    const UserAgent = deviceInfoString;
    const Duration = Math.round(
      differenceInMilliseconds(endTimeRef.current, startTime) / 1000,
    );

    const assessmentPayload = {
      payload: {
        ...assessment,
        AppVersion,
        Duration,
        Machine,
        Process,
        Result,
        UserAgent,
      },
      token,
      onUploadProgress,
    } as QuestionnairePayload;

    mutate(assessmentPayload);
  };

  // --------------- Navigation handlers ---------------

  const goToLastQuestion = () => {
    const path = `/questionnaire/${selectedQuestionnaire?.id}/${numberOfQuestions}`;

    navigate(path, {
      replace: true,
    });
  };

  const navigateToEndScreenStepper = useCallback(() => {
    const pathname = "/additional-options";
    const search = "?step=1";

    navigate({
      pathname,
      search,
    });
  }, [navigate]);

  // --------------- Unanswered questions handlers ---------------

  const findFirstUnansweredQuestion = () => {
    if (!selectedQuestionnaire) {
      return;
    }

    const unansweredQuestions = selectedQuestionnaire.Questions.map((question) =>
      answers.some((answer) => answer.Question === question.id) ? undefined : question,
    ).filter((item) => !!item);

    const firstUnansweredQuestion = !!unansweredQuestions
      ? unansweredQuestions[0]
      : undefined;

    return firstUnansweredQuestion;
  };

  const goToFirstUnansweredQuestion = (index: number) => {
    const path = `/questionnaire/${selectedQuestionnaire?.id}/${index + 1}`;
    navigate(path);
  };

  const handleUnansweredQuestions = () => {
    if (!selectedQuestionnaire) {
      return;
    }

    const firstUnansweredQuestion = findFirstUnansweredQuestion();

    let firstUnansweredQuestionIndex = 0;

    if (firstUnansweredQuestion) {
      firstUnansweredQuestionIndex = selectedQuestionnaire.Questions.findIndex(
        ({ id }) => id === firstUnansweredQuestion.id,
      );
    }

    goToFirstUnansweredQuestion(firstUnansweredQuestionIndex);
  };

  // --------------- On click handlers ---------------

  const onCheckboxChange = () => {
    setGravityAlertConfirmed((prev) => !prev);
  };

  const onTriangleBackClick = () => {
    if (isLoading) {
      return;
    }

    if (reportSent) {
      handleBackToHomePage();
    } else {
      goToLastQuestion();
    }
  };

  const onReturnBtnClick = () => {
    handleUnansweredQuestions();
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (!photosForAnswers) {
      return;
    }

    const filteredAnswers = answers.filter(
      ({ QuestionType }) =>
        QuestionType === YES_NO ||
        QuestionType === YES_NO_TEXT ||
        QuestionType === YES_NO_PHOTO,
    );

    let positiveCount = 0;
    let negativeCount = 0;

    const positiveVsNegative = filteredAnswers.reduce((acc, curr) => {
      const { Value } = curr;

      if (Value === YES) {
        positiveCount += 1;
        acc = { ...acc, positiveAnswers: positiveCount };
      }
      if (Value === NO) {
        negativeCount += 1;
        acc = { ...acc, negativeAnswers: negativeCount };
      }

      return acc;
    }, {} as { positiveAnswers: number; negativeAnswers: number });

    const { positiveAnswers, negativeAnswers } = positiveVsNegative;
    const getPercentage = (value: number) => (value / filteredAnswers.length) * 100 || 0;

    setPercentage({
      positive: getPercentage(positiveAnswers),
      negative: getPercentage(negativeAnswers),
    });

    // Check for critical/negative answers
    const criticalAnswers = answers.filter(
      ({ Gravity, Value }) => Gravity === 1 && Value === NO,
    );

    if (criticalAnswers.length) {
      setGravityAlertVisible(true);
      // setGravityAlertConfirmed(false);
    } else {
      setBtnDisabled(false);
    }

    // Complete answers with photos (if any)
    const answersWithPhotos = answers.reduce((acc: Answer[], curr: Answer) => {
      if (checkIfPhotoType(curr)) {
        acc = [...acc, { ...curr, FilesGallery: photosForAnswers[curr.Question] }];
      } else {
        acc = [...acc, curr];
      }

      return acc;
    }, []);

    // Sort answers
    const sortedAnswers = answersWithPhotos.sort((a, b) => a.sort - b.sort);

    const summary = {
      AccidentForm: null,
      Payload: sortedAnswers,
      Questionnaire: selectedQuestionnaire?.id,
      SerialNo: serialNumber,
      TotalAnswers: answers.length,
      TotalGravity1Negatives: criticalAnswers.length,
      TotalNegatives: negativeAnswers || 0,
      createdAt: startTime,
    } as Assessment;

    setAssessment(summary);
  }, [answers, serialNumber, selectedQuestionnaire, startTime, photosForAnswers]);

  useEffect(() => {
    const isAnimationDone = !!sessionStorage.getItem("animationDone");

    let delay = 0;

    if (!isAnimationDone) {
      delay = hasAccessToAdditionalOptions ? 1800 : 2500;
    }

    setTimeOut(() => {
      if (reportSent) {
        if (hasAccessToAdditionalOptions) {
          navigateToEndScreenStepper();
        } else {
          handleBackToHomePage();
        }

        sessionStorage.removeItem("animationDone");
      }
    }, delay);
  }, [
    reportSent,
    hasAccessToAdditionalOptions,
    navigateToEndScreenStepper,
    handleBackToHomePage,
    setTimeOut,
  ]);

  useEffect(() => {
    if (gravityAlertVisible) {
      setBtnDisabled(!gravityAlertConfirmed || !assessment);
    } else {
      if (assessment) {
        setTimeOut(() => {
          setBtnDisabled(false);
        }, 200);
      } else {
        setBtnDisabled(true);
      }
    }
  }, [gravityAlertVisible, gravityAlertConfirmed, assessment, setTimeOut]);

  useEffect(() => {
    endTimeRef.current = new Date();
  }, []);

  return (
    <ContentContainer>
      <Wrapper>
        <Header smallView={smallView}>
          <Triangle
            src={chevron}
            alt=''
            onClick={onTriangleBackClick}
            className='back'
            isLoading={isLoading}
          />
          <SummaryLabel>{summaryLabel}</SummaryLabel>
        </Header>

        {allQwestionsAnswered && (
          <>
            <EntityInfo />
            {!reportSent && <Statistics percentage={percentage} />}
            <GravityAlert
              visible={gravityAlertVisible && !reportSent}
              onChange={onCheckboxChange}
            />
          </>
        )}

        {!reportSent && isLoading && <UploadProgressBar progress={uploadProgress} />}

        {reportSent && <SuccessAnimated message={t("alert.report-sent")} />}

        {!reportSent && allQwestionsAnswered && (
          <ActionsWrapper>
            <Actions spaceBetween>
              <ButtonContainer double>
                <Button
                  label={t("button.discard")}
                  question
                  onClick={openModal}
                  disabled={isLoading}
                />
              </ButtonContainer>

              <ButtonContainer long>
                <Button
                  onClick={handleSendAssessment}
                  label={t("button.send")}
                  loading={isLoading}
                  disabled={btnDisabled || isLoading}
                />
              </ButtonContainer>
            </Actions>
          </ActionsWrapper>
        )}

        <ReportIncompleteInfo
          onClick={onReturnBtnClick}
          openModal={openModal}
          visible={!allQwestionsAnswered}
        />
      </Wrapper>

      <ConfirmationModal
        message={t("modal.message")}
        onClick={handleBackToHomePage}
        onClose={closeModal}
        open={open}
        buttonLabel={t("button.discard")}
        double
      />
    </ContentContainer>
  );
}

export default Summary;
