import { useEffect, useRef } from "react";

type Callback = (...args: any[]) => void;

function useTimeout() {
  const timeoutId = useRef<NodeJS.Timeout>(setTimeout(() => {}, 0));

  const setTimeOut = (callback: Callback, delay: number) => {
    timeoutId.current = setTimeout(callback, delay);
  };

  useEffect(() => () => clearTimeout(timeoutId.current), []);

  return setTimeOut;
}

export default useTimeout;
