import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { getTranslations } from "./translations/confirn-message.translations";

import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";

const Container = styled.div`
  padding-bottom: 1rem;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
  font-family: GothamBold;

  .w-icon {
    width: 2.5rem;
    fill: ${({ theme }) => theme.orange};
  }
`;

const Bottom = styled.div`
  /* font-size: 1.9rem; */
  margin-top: 1rem;
  padding-left: 3.1rem;
`;

function ConfirmMessage() {
  const {
    i18n: { language },
  } = useTranslation();

  const {
    message: { top, bottom },
  } = getTranslations(language);

  return (
    <Container>
      <Top>
        <WarningIcon className='w-icon' />
        {top}
      </Top>
      <Bottom>{bottom}</Bottom>
    </Container>
  );
}

export default ConfirmMessage;
