import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import Thumb from "components/atoms/Thumb";

import { ReactComponent as ChevronRightIcon } from "assets/icons/chevron-right.svg";

const Container = styled.div`
  position: relative;

  .chevron-right {
    width: 3rem;
    position: absolute;
    top: 50%;
    right: 0.2rem;
    transform: translateY(-50%);
    animation: move-chevron forwards 0.8s ease infinite;

    path {
      stroke: ${({ theme }) => theme.white};
    }

    @keyframes move-chevron {
      0% {
        right: 1.5rem;
      }
    }
  }
`;

const ThumbContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const YesNoLabel = styled.span`
  font-size: 1.8rem;
`;

type ButtonLabelProps = {
  selected: boolean;
  up?: boolean;
  down?: boolean;
  noText: boolean;
};

function ButtonLabel({ selected, up = false, down = false, noText }: ButtonLabelProps) {
  const { t } = useTranslation("common");

  return (
    <Container>
      <ThumbContainer>
        <Thumb up={up} down={down} filled={selected} />
        {!noText && <YesNoLabel>{up ? t("button.yes") : t("button.no")}</YesNoLabel>}
      </ThumbContainer>

      {selected && <ChevronRightIcon className='chevron-right' />}
    </Container>
  );
}

export default ButtonLabel;
