import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import Button from "components/atoms/Button";

import { ContentContainer } from "styles/generalStyles";
import { WarningBar } from "pages/accident-form/styles/accidenFormStyles";

const Container = styled.div`
  ${({ theme }) => theme.fillUpRemainingSpace}
  justify-content: space-between;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  background-color: rgba(196, 205, 222, 0.5);
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding-top: 2rem;

  .em-icon {
    width: 5rem;
    height: 5rem;
    fill: ${({ theme }) => theme.primary};
  }
`;

const Messsage = styled.div`
  text-align: center;
  font-family: GothamBold;
`;

const Top = styled.div`
  font-size: 2rem;
`;

const Bottom = styled.div`
  margin-top: 1.5rem;
  font-size: 1.8rem;
  padding: 0 1rem;
`;

const Actions = styled.div`
  padding-bottom: 1rem;
`;

function ProdServerWarning() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/home");
  };

  return (
    <ContentContainer>
      <Container>
        <div style={{ marginTop: "2rem" }}>
          <WarningBar />
          <Main>
            <Header>
              <h2>UWAGA!</h2>
            </Header>

            <Messsage>
              <Top>Aplikacja działa na serwerze produkcyjnym.</Top>
              <Bottom>
                Zaleca się zachowanie szczególnej ostrożności podczas jej użytkowania.
              </Bottom>
            </Messsage>

            <WarningBar />
          </Main>
        </div>
        <Actions>
          <Button label='Rozumiem' onClick={onClick} />
        </Actions>
      </Container>
    </ContentContainer>
  );
}

export default ProdServerWarning;
