export enum CollectionEnum {
  MACHINES = "Machines",
  PROCESSES = "Processes",
  TASKS = "Tasks",
  TASKS_FILES = "Tasks_files",
  TASKS_RELATED = "Tasks_Related",
}

export enum ActionsEnum {
  CREATE = "create",
  READ = "read",
  UPDATE = "update",
  DELETE = "delete",
  SHARE = "share",
}

const CommonFieldsEnum = {
  ALL: "*",
  ID: "id",
  STATUS: "status",
  USER_CREATED: "user_created",
  USER_UPDATED: "user_updated",
  DATE_CREATED: "date_created",
  DATE_UPDATED: "date_updated",
} as const;

export const TasksFieldsEnum = {
  ASSIGN_TO: "AssignTo",
  DESCRIPTION: "Description",
  FILES: "Files",
  RELATED: "Related",
  SORT: "sort",
  TITLE: "Title",
} as const;

export const MachProcFieldsEnum = {
  SERIAL_NUMBER: "SerialNo",
  TAGS: "Tags",
  DEPARTMENT: "Department",
  LAST_ASSESSMENT: "LastAssessment",
  SERVICE_EXPIRY_DATE: "ServiceExpiryDate",
  MISC: "Misc",
  ASSESSMENTS: "Assessments",
} as const;

export const MachinesFieldsEnum = {
  MACHINE_MODEL: "MachineModel",
  MACHINE_TYPE: "MachineType",
  ACCORDION: "accordion-cfh2fe",
  SOME_RAW_GROUP: "somerawgroup",
  MAKE: "Make",
  MAKE_YEAR: "MakeYear",
  HOUR_METER: "HourMeter",
  DOCUMENTS: "Dokumenty",
} as const;

export const ProcessesFieldsEnum = {
  PROCESS_NAME: "ProcessName",
  PROCESS_TYPE: "ProcessType",
  ADDRESS: "Address",
  GEOLOCATION: "Geolocation",
} as const;

export const FieldsEnum = {
  ...CommonFieldsEnum,
  ...TasksFieldsEnum,
  ...MachProcFieldsEnum,
  ...MachinesFieldsEnum,
  ...ProcessesFieldsEnum,
} as const;

export type Field = (typeof FieldsEnum)[keyof typeof FieldsEnum];

export type Permission = {
  collection: CollectionEnum;
  action: ActionsEnum;
  fields: Field[];
};

export type UserPermissionsResponse = {
  data: Permission[];
};

const {
  ID,
  ASSIGN_TO,
  DESCRIPTION,
  FILES,
  RELATED,
  SORT,
  STATUS,
  TITLE,
  USER_CREATED,
  USER_UPDATED,
  DATE_CREATED,
  DATE_UPDATED,
  SERIAL_NUMBER,
  TAGS,
  DEPARTMENT,
  LAST_ASSESSMENT,
  SERVICE_EXPIRY_DATE,
  MISC,
  ASSESSMENTS,
  MACHINE_MODEL,
  MACHINE_TYPE,
  ACCORDION,
  SOME_RAW_GROUP,
  MAKE,
  MAKE_YEAR,
  HOUR_METER,
  DOCUMENTS,
  PROCESS_NAME,
  PROCESS_TYPE,
  ADDRESS,
  GEOLOCATION,
} = FieldsEnum;

type CommonPermObject = {
  ALL: boolean;
  [ID]: boolean;
  [STATUS]: boolean;
  [USER_CREATED]: boolean;
  [USER_UPDATED]: boolean;
  [DATE_CREATED]: boolean;
  [DATE_UPDATED]: boolean;
};

type TasksPermObject = {
  [ASSIGN_TO]: boolean;
  [DESCRIPTION]: boolean;
  [FILES]: boolean;
  [RELATED]: boolean;
  [SORT]: boolean;
  [TITLE]: boolean;
};

type MachProcPermObject = {
  [SERIAL_NUMBER]: boolean;
  [TAGS]: boolean;
  [DEPARTMENT]: boolean;
  [LAST_ASSESSMENT]: boolean;
  [SERVICE_EXPIRY_DATE]: boolean;
  [MISC]: boolean;
  [ASSESSMENTS]: boolean;
};

type MachinesPermObject = {
  [MACHINE_MODEL]: boolean;
  [MACHINE_TYPE]: boolean;
  [ACCORDION]: boolean;
  [SOME_RAW_GROUP]: boolean;
  [MAKE]: boolean;
  [MAKE_YEAR]: boolean;
  [HOUR_METER]: boolean;
  [DOCUMENTS]: boolean;
};

type ProcessesPermObject = {
  [PROCESS_NAME]: boolean;
  [PROCESS_TYPE]: boolean;
  [ADDRESS]: boolean;
  [GEOLOCATION]: boolean;
};

export type PermObject = CommonPermObject &
  TasksPermObject &
  MachProcPermObject &
  MachinesPermObject &
  ProcessesPermObject;

type Perm = Partial<PermObject> | undefined;

export type TasksPermissions = {
  create: Perm;
  read: Perm;
  update: Perm;
  delete: Perm;
  share: Perm;
};
