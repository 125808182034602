import { useEffect } from "react";
import styled, { css } from "styled-components/macro";

import useTimeout from "hooks/useTimeout";

const Container = styled.div<{ isAnimationDone: boolean }>`
  margin-top: 1.5rem;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;

  @media screen and (min-height: 508px) {
    margin-top: 3rem;
  }

  .checkmark {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    display: block;
    stroke-width: 3;
    stroke: ${({ theme }) => theme.emeraldGreen};
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px ${({ theme }) => theme.emeraldGreen};
    margin: 0 auto;

    @media screen and (min-height: 508px) {
      width: 12rem;
      height: 12rem;
    }

    ${({ isAnimationDone }) =>
      !isAnimationDone &&
      css`
        animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
      `}

    ${({ isAnimationDone }) =>
      isAnimationDone &&
      css`
        box-shadow: inset 0px 0px 0px 30px ${({ theme }) => theme.emeraldGreen};
        transform: scale3d(1.1, 1.1, 1);
      `}
  }
  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 4;
    stroke-miterlimit: 10;
    stroke: ${({ theme }) => theme.emeraldGreen};
    fill: ${({ theme }) => theme.primary_50};

    ${({ isAnimationDone }) =>
      !isAnimationDone &&
      css`
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
      `}

    ${({ isAnimationDone }) =>
      isAnimationDone &&
      css`
        stroke-dashoffset: 0;
      `}
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;

    ${({ isAnimationDone }) =>
      !isAnimationDone &&
      css`
        animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
      `}

    ${({ isAnimationDone }) =>
      isAnimationDone &&
      css`
        stroke-dashoffset: 0;
      `}
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes scale {
    0%,
    100% {
      transform: none;
    }

    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px ${({ theme }) => theme.emeraldGreen};
    }
  }
`;

const Message = styled.div`
  font-size: 2rem;
  color: ${({ theme }) => theme.emeraldGreen};
  font-family: GothamBold;
`;

type SuccessAnimatedProps = {
  message: string | JSX.Element;
};

function SuccessAnimated({ message }: SuccessAnimatedProps) {
  const setTimeOut = useTimeout();
  const isAnimationDone = !!sessionStorage.getItem("animationDone");

  useEffect(() => {
    setTimeOut(() => {
      if (!isAnimationDone) {
        sessionStorage.setItem("animationDone", JSON.stringify(true));
      }
    }, 2000);
  }, [isAnimationDone, setTimeOut]);

  return (
    <Container isAnimationDone={isAnimationDone}>
      <svg className='checkmark' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
        <circle className='checkmark__circle' cx='26' cy='26' r='25' fill='none' />
        <path className='checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
      </svg>

      <Message>{message}</Message>
    </Container>
  );
}

export default SuccessAnimated;
