export enum Environments {
  PRODUCTION = "production",
  DEVELOPMENT = "development",
  TEST = "test",
}

export enum Domains {
  SNAP_BUG = "snapbug",
  SNAP_CHECK = "snapcheck",
  SENSOTEC = "sensotec",
}
