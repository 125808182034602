import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let label = {
    assign: "Assign to",
    assigned: "Assigned to",
  };

  let placeholder = {
    user: "Select user...",
    status: "Select status...",
  };
  let getUsersError = "Unable to load users list";
  let selectNoOptionsMessage = "No options...";

  if (isPolish) {
    label = {
      assign: "Przypisz do",
      assigned: "Przypisane do",
    };

    placeholder = {
      user: "Wybierz użytkownika...",
      status: "Wybierz status...",
    };
    getUsersError = "Nie można załadować listy użytkowników";
    selectNoOptionsMessage = "Brak opcji...";
  }

  return {
    label,
    placeholder,
    getUsersError,
    selectNoOptionsMessage,
  };
};
