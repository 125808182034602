import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import Button from "components/atoms/Button";

const Container = styled.div`
  padding: 2rem;
  max-width: 45rem;
  margin: 0 auto;
`;

const MessageContainer = styled.div`
  padding: 2rem;
  border: 4px solid red;
  background-color: rgba(0, 0, 0, 0.8);
  color: aliceblue;
  border-radius: 0.4rem;
`;

const Message = styled.p`
  margin-top: 1rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  width: 15rem;
  margin-top: 1rem;
`;

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  const { t } = useTranslation("common");

  return (
    <Container>
      <MessageContainer>
        <h2>{t("error.application")}...</h2>
        <Message>{error.message}</Message>
      </MessageContainer>

      <Actions>
        <ButtonContainer>
          <Button label={t("button.back")} onClick={resetErrorBoundary} />
        </ButtonContainer>
      </Actions>
    </Container>
  );
}

export default ErrorFallback;
