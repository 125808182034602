import { RefObject, useEffect, useState, useRef, UIEvent } from "react";

import usePrevious from "./usePrevious";
import useTimeout from "hooks/useTimeout";

type useContentOverflowProps = {
  contentContainerRef: RefObject<HTMLDivElement> | null;
  currentQuestionIndex: number;
};

function useContentOverflow({
  contentContainerRef,
  currentQuestionIndex,
}: useContentOverflowProps) {
  const [isContentOverlow, setIsContentOverFlow] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const prevQuestionIndex = usePrevious(currentQuestionIndex);

  const setTimeOut = useTimeout();

  const scrollHeightRef = useRef(0);
  const top = isBottom ? 0 : scrollHeightRef.current + 200;

  const scrollOptions: ScrollToOptions = {
    top,
    left: 0,
    behavior: "smooth",
  };

  const onScroll = ({ currentTarget }: UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = currentTarget;

    // The scrollTop property is sometimes a decimal number
    // that is why it is wrapped in Math.round().
    // It affects the general equation hence the +1 and -1
    // in the flag statement below.
    const base = Math.round(scrollTop) + clientHeight;
    const flag =
      base === scrollHeight || base + 1 === scrollHeight || base - 1 === scrollHeight;

    setIsBottom(flag);
  };

  const onScrollButtonClick = () => {
    contentContainerRef?.current?.scrollTo(scrollOptions);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!contentContainerRef || !contentContainerRef.current) {
      return;
    }

    setTimeOut(() => {
      const contentContainer = contentContainerRef.current;
      if (contentContainer) {
        const { clientHeight, scrollHeight } = contentContainer;
        scrollHeightRef.current = scrollHeight;

        setIsContentOverFlow(scrollHeight > clientHeight);
      }
    }, 100);
  });

  useEffect(() => {
    setIsBottom(false);

    if (!isContentOverlow) {
      return;
    }

    if (prevQuestionIndex !== currentQuestionIndex) {
      contentContainerRef?.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
      });

      setIsContentOverFlow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionIndex, prevQuestionIndex, isContentOverlow]);

  return { isContentOverlow, isBottom, onScrollButtonClick, onScroll };
}

export default useContentOverflow;
