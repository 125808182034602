import { useContext, useEffect, UIEvent, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FormTypes } from "types/types";
import { device, isMobile } from "utils/utils";
import usePhotosForAnswers from "hooks/usePhotosForAnswers";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as UpArrowIcon } from "assets/icons/up-arrow.svg";

import Answer from "./components/Answer";
import Button from "components/atoms/Button";
import EntityInfo from "components/molecules/EntityInfo";

import { ContentContainer } from "styles/generalStyles";
import { NumberOfPhotos, PhotoPreview } from "./types/tableOfContents.types";

const Container = styled.div<{ isMobile: boolean }>`
  position: relative;
  background-color: ${({ theme }) => theme.primary_100};
  overflow: hidden;
  ${({ theme }) => theme.fillUpRemainingSpace}

  @media ${device.iPad} {
    margin: 2rem 0;
    border-radius: 0.4rem;
    padding: 2rem 3rem;
    ${({ theme }) => theme.cardShadow};
  }

  .close-icon {
    width: 3rem;
    height: 3rem;
    fill: ${({ theme }) => theme.primary};

    position: absolute;
    top: 1rem;
    right: -0.4rem;

    ${({ isMobile }) =>
      !isMobile &&
      css`
        cursor: pointer;
      `}
  }

  animation: showContainer 0.25s ease-out;

  @keyframes showContainer {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

const Header = styled.div`
  margin-bottom: 0.6rem;
  padding-top: 0.7rem;
  padding-right: 2.2rem;
  padding-bottom: 0.7rem;
  border-top: 1px solid ${({ theme }) => theme.primary_600};
`;

const Title = styled.div<{ isMobile: boolean }>`
  max-height: 6.1rem;
  font-family: GothamBold;
  font-size: 1.8rem;
  ${({ theme }) => theme.ellipsisMultiline}

  ${({ isMobile }) =>
    isMobile &&
    css`
      -webkit-line-clamp: 3;
    `}
`;

const Subtitle = styled.div`
  margin-top: 0.6rem;
  font-size: 1.6rem;
  ${({ theme }) => theme.ellipsisMultiline}
`;

const CounterContainer = styled.div`
  padding-left: 0.2rem;
  margin: 0.5rem 0;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Counter = styled.div`
  font-family: "GothamBold";
  font-size: 1.8rem;

  display: flex;
  align-items: center;

  @media screen and (min-height: 616px) {
    font-size: 2rem;
  }

  .label {
    font-size: 1.6rem;
    align-self: flex-end;
    font-family: "GothamBook";
    padding-bottom: 0.1rem;
  }
`;

const ButtonContainer = styled.div`
  width: 12rem;

  .send-btn {
    height: 4rem;
    font-size: 1.4rem;
    ${({ theme }) => theme.cardShadow};
  }
`;

const QuestionsList = styled.ul`
  overflow: auto;
  margin-top: 1rem;
  padding-bottom: 0.2rem;
  ${({ theme }) => theme.scrollbarFirefox}
`;

const Question = styled.li<{ isMobile: boolean }>`
  position: relative;
  height: 10rem;
  background-color: ${({ theme }) => theme.aliceBlue};
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  border-radius: 0.4rem;
  color: ${({ theme }) => theme.snapcheckBlue};
  ${({ theme }) => theme.cardShadow};
  overflow: hidden;
  transition: opacity 0.2s ease-out;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .text,
  .sub-text {
    opacity: 0.9;
    ${({ theme }) => theme.ellipsisMultiline}
  }

  .text {
    font-family: GothamBold;
    margin-bottom: 0.4rem;
    font-size: 1.4rem;

    @media screen and (min-height: 616px) {
      font-size: 1.6rem;
    }
  }

  .sub-text {
    font-size: 1.4rem;
  }

  ${({ isMobile }) =>
    !isMobile &&
    css`
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    `}
`;

const UpArrowContainer = styled.div<{
  isMobile: boolean;
}>`
  position: absolute;
  right: 1.9rem;
  bottom: 1.5rem;
  width: 4rem;
  height: 4rem;
  padding: 0.7rem;

  border: 4px solid ${({ theme }) => theme.snapCheckBlueLight};
  border-radius: 0.6rem;
  background-color: rgba(255, 255, 255, 0.5);

  animation: showUpArrow 0.4s ease;

  @keyframes showUpArrow {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .up-arrow {
    fill: ${({ theme }) => theme.snapCheckBlueLight};
  }

  ${({ isMobile }) =>
    !isMobile &&
    css`
      cursor: pointer;
    `}
`;

const { QUESTIONNAIRE } = FormTypes;

function TableOfContents() {
  const {
    state: { answers, selectedQuestionnaire },
  } = useContext(QuestionnairesContext);
  const navigate = useNavigate();
  const listRef = useRef<HTMLUListElement>(null);
  const [arrowVisible, setArrowVisible] = useState(false);
  const { t } = useTranslation(["table-of-contents", "summary"]);
  const { photosForAnswers } = usePhotosForAnswers(QUESTIONNAIRE);

  const numberOfQuestions = selectedQuestionnaire?.Questions.length;
  const numberOfAnswers = answers.length;

  const allAnswered = numberOfQuestions === numberOfAnswers;

  const title = selectedQuestionnaire?.QuestionnaireTitle;
  const subTitle = selectedQuestionnaire?.QuestionnaireSubtitle;

  let content = null;

  const onQuestionClick = (index: number) => () => {
    const questionNumber = index + 1;
    const questionnaireId = selectedQuestionnaire?.id;
    const path = `/questionnaire/${questionnaireId}/${questionNumber}`;

    navigate(path);
  };

  const onUpArrowClick = () => {
    if (listRef.current) {
      listRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const onSendButtonClick = () => {
    navigate(`/questionnaire/summary/${selectedQuestionnaire?.id}`);
  };

  const onCloseIconClick = () => {
    const path = localStorage.getItem("tableOfContentsReturnPath") ?? "";
    navigate(path, { replace: true });
    localStorage.removeItem("tableOfContentsReturnPath");
  };

  const showArrow = () => {
    setArrowVisible(true);
  };

  const hideArrow = () => {
    setArrowVisible(false);
  };

  const onScroll = (e: UIEvent<HTMLUListElement>) => {
    if (listRef.current) {
      const scrollPosition = listRef.current.scrollTop;

      sessionStorage.setItem("scrollPosition", scrollPosition.toString());

      if (scrollPosition > 2000) {
        if (!arrowVisible) {
          showArrow();
        }
      } else {
        if (arrowVisible) {
          hideArrow();
        }
      }
    }
  };

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");

    if (scrollPosition) {
      if (listRef.current) {
        listRef.current.scrollTo({
          top: Number(scrollPosition),
          left: 0,
          behavior: "auto",
        });
      }
    }
  }, []);

  if (selectedQuestionnaire) {
    const { Questions } = selectedQuestionnaire;

    content = (
      <QuestionsList onScroll={onScroll} ref={listRef}>
        {Questions.map((question, index) => {
          let numberOfPhotos: NumberOfPhotos;
          let photoPreview: PhotoPreview;

          if (photosForAnswers) {
            numberOfPhotos = photosForAnswers[question.id]
              ? photosForAnswers[question.id].length
              : undefined;

            if (numberOfPhotos) {
              photoPreview = photosForAnswers[question.id]
                ? photosForAnswers[question.id][numberOfPhotos - 1].src
                : undefined;
            }
          }

          return (
            <Question
              key={question.id}
              onClick={onQuestionClick(index)}
              isMobile={isMobile}
            >
              <div className='text'>
                {index + 1}&#x2024;&nbsp;{question.QuestionText}
              </div>
              <Answer
                questionId={question.id}
                numberOfPhotos={numberOfPhotos}
                photoPreview={photoPreview}
              />
            </Question>
          );
        })}
      </QuestionsList>
    );
  }

  return (
    <ContentContainer background_100>
      <Container isMobile={isMobile}>
        <CloseIcon className='close-icon' onClick={onCloseIconClick} />

        <EntityInfo marginTop />

        <Header>
          <Title isMobile={isMobile}>{title}</Title>
          {!!subTitle && <Subtitle>{subTitle}</Subtitle>}
        </Header>

        <CounterContainer>
          <Counter>
            <span className='label'>{t("answers")}</span>
            <span>
              &nbsp;{numberOfAnswers}
              &#x2f;{numberOfQuestions}
            </span>
          </Counter>

          {allAnswered && (
            <ButtonContainer>
              <Button
                label={t("summary:button.send")}
                customClass='send-btn'
                onClick={onSendButtonClick}
                noShaddow
              />
            </ButtonContainer>
          )}
        </CounterContainer>

        {content}

        {arrowVisible && (
          <UpArrowContainer isMobile={isMobile} onClick={onUpArrowClick}>
            <UpArrowIcon className='up-arrow' />
          </UpArrowContainer>
        )}
      </Container>
    </ContentContainer>
  );
}

export default TableOfContents;
