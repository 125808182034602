import styled, { css } from "styled-components/macro";

import { isMobile } from "utils/utils";

import { ReactComponent as CommentIcon } from "assets/icons/comment.svg";

import ChevronToggle from "./ChevronToggle";

const Container = styled.div<{ isMobile: boolean }>`
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s ease;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

  ${({ isMobile }) =>
    !isMobile &&
    css`
      &:hover {
        background-color: rgba(232, 235, 241, 0.15);
      }
    `}
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .comment-icon {
    width: 3rem;
    transform: translateX(-0.3rem);
  }
`;

type CommentInfoProps = {
  id: string;
  expanded: boolean;
  toggleAccordion: (id: string) => () => void;
};

function ReactionValuePanel({ id, expanded, toggleAccordion }: CommentInfoProps) {
  return (
    <Container isMobile={isMobile} onClick={toggleAccordion(id)}>
      <Label>
        <CommentIcon className='comment-icon' />
      </Label>
      <ChevronToggle id={id} expanded={expanded} toggleAccordion={toggleAccordion} />
    </Container>
  );
}

export default ReactionValuePanel;
