import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { device } from "utils/utils";

const Container = styled.div<{
  preview: boolean;
}>`
  width: max-content;
  height: 6rem;
  padding: 0 4rem;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  color: ${({ theme }) => theme.warningColor};
  border: 4px solid;
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 1.8rem;
  z-index: ${({ theme }) => theme.level1};
  display: grid;
  place-content: center;

  @media ${device.iPad} {
    width: 50%;
  }

  animation: showPhotoLimit 0.5s ease;

  @keyframes showPhotoLimit {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${({ preview }) =>
    preview &&
    css`
      padding: 0 3rem;
    `}
`;

function PhotoLimitInfo({ preview = false }: { preview?: boolean }) {
  const { t } = useTranslation("photo-gallery");

  return (
    <Container preview={preview}>
      <span>{t("alert.limit-reached")}</span>
    </Container>
  );
}

export default PhotoLimitInfo;
