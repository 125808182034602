import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import styled from "styled-components/macro";
import jwt_decode from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { transformInviteToken, inviteUser } from "api/auth";
import useQueryString from "hooks/useQueryString";
import AuthContext from "contexts/auth-context/AuthContext";
import useTimeout from "hooks/useTimeout";

import { ContentContainer, Header, InputContainer } from "styles/generalStyles";

import { ReactComponent as EyeOpen } from "assets/icons/eye-open.svg";
import { ReactComponent as EyeClosed } from "assets/icons/eye-closed.svg";

import BackArrow from "components/atoms/BackArrow";
import Input from "components/atoms/Input";
import Button from "components/atoms/Button";
import ErrorAlert from "components/molecules/ErrorAlert";

type Token = {
  email: string;
  exp: number;
  iat: number;
  iss: string;
  scope: string;
};

const Info = styled.div`
  margin-bottom: 2rem;
`;

const Label = styled.div`
  margin-bottom: 0.2rem;
`;

const Email = styled.div`
  font-size: 1.8rem;
  ${({ theme }) => theme.ellipsis};
  font-family: GothamBold;
  opacity: 0.75;
`;

function InviteUser() {
  const { inviteEmail, setInviteEmail } = useContext(AuthContext);
  const [inviteToken, setInviteToken] = useState("");
  const [password, setPassword] = useState("");
  const [tokenInvalid, setTokenInvalid] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [error, setError] = useState("");
  const setTimeOut = useTimeout();
  const navigate = useNavigate();
  const { t } = useTranslation(["reset-password", "common"]);

  const query = useQueryString();
  const urlToken = query.get("token") as string;

  const inputRef = useRef<HTMLInputElement>(null);

  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  const redirectToLogin = () => {
    navigate("/login");
  };

  const clearError = () => {
    if (!!error) {
      setError("");
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  // --------------- API ---------------

  const {
    mutate: transformInviteTokenAction,
    isLoading: customInviteLoading,
    isError,
  } = useMutation(transformInviteToken, {
    onSuccess: setInviteToken,
    onError: () => {
      notifyError(t("notify.error.token"));
    },
  });

  const { mutate: inviteUserAction, isLoading } = useMutation(inviteUser, {
    onSuccess: () => {
      notifySuccess(t("notify.success.new-password-set"));
      setPassword("");
      setTimeOut(() => {
        redirectToLogin();
      }, 3500);
    },
    onError: () => {
      notifyError(t("notify.error.set-password"));
    },
  });

  const sendRequest = () => {
    const payload = {
      token: inviteToken,
      password,
    };

    inviteUserAction(payload);
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (urlToken) {
      const decodedJwt: Token = jwt_decode(urlToken);
      const tokenExpired = decodedJwt.exp * 1000 < Date.now();

      setInviteEmail(decodedJwt.email);

      if (tokenExpired) {
        setTokenInvalid(true);
      }

      if (inputRef.current) {
        inputRef.current.focus();
      }

      transformInviteTokenAction({ token: urlToken });
    }
  }, [urlToken, setInviteEmail, transformInviteTokenAction]);

  // --------------- Event handlers ---------------

  const onPasswordInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    clearError();
    setPassword(value);
  };

  const onButtonClick = () => {
    if (!password.length) {
      setError(t("common:error.empty-field"));
    } else if (password.length < 8) {
      setError(t("input.error.min-8-characters"));
    } else if (password.length > 50) {
      setError(
        `${t("input.error.too-long")} ${t("input.error.max-number-of-characters")} 50`,
      );
    } else {
      sendRequest();
    }
  };

  return (
    <ContentContainer>
      <Header>
        {tokenInvalid && <BackArrow onClick={redirectToLogin} />}
        <span>{t("header.new-account")}</span>
      </Header>

      {!tokenInvalid ? (
        <>
          <Info>
            <Label>{t("info-label.account-created-for")}</Label>
            <Email>{inviteEmail}</Email>
          </Info>

          <InputContainer password>
            <Input
              label={t("input.label.create-password")}
              onChange={onPasswordInputChange}
              type={isPasswordVisible ? "text" : "password"}
              value={password}
              placeholder={t("input.placeholder")}
              paddingRight
              ref={inputRef}
              error={error}
            />

            {isPasswordVisible ? (
              <EyeOpen className='eye-icon' onClick={togglePasswordVisibility} />
            ) : (
              <EyeClosed className='eye-icon' onClick={togglePasswordVisibility} />
            )}
          </InputContainer>

          <Button
            label={t("input.label.create-password")}
            onClick={onButtonClick}
            loading={isLoading}
            disabled={isLoading || customInviteLoading || isError}
          />
        </>
      ) : (
        <ErrorAlert message={t("alert.message")} smallMargin />
      )}

      <ToastContainer position='top-left' theme='colored' autoClose={3500} />
    </ContentContainer>
  );
}

export default InviteUser;
