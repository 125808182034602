import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";

const Container = styled.div`
  .icon {
    width: 5rem;
    height: 5rem;
    fill: ${({ theme }) => theme.warningColor};
  }

  .arrow {
    width: 3.5rem;
    height: 3.5rem;
    cursor: pointer;
  }
`;

const Warning = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.graphiteGrey};
  border-radius: 0.7rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  border: 4px solid ${({ theme }) => theme.warningColor};
`;

const Label = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.warningColor};
`;

function NoQuestionnairesFound() {
  const { t } = useTranslation("questionnaires");

  return (
    <Container>
      <Warning>
        <WarningIcon className='icon' />
        <Label>
          <span>{t("alert.no-questionnaires.top")}</span>
          <span>{t("alert.no-questionnaires.bottom")}</span>
        </Label>
      </Warning>
    </Container>
  );
}

export default NoQuestionnairesFound;
