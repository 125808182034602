import { Answer, QuestionType } from "types/types";

export type PromptProps = {
  onClick: () => void;
  questionType: QuestionType;
  currentQuestionId?: string;
  answerToCurrentQuestion: Answer | undefined;
};

export enum PromptType {
  TEXT = "text",
  PHOTO = "photo",
}
