import { instance } from "axios-instance/axios-instance";
import {
  AuthMode,
  LoginFormPayload,
  LoginResponse,
  LogoutPayload,
  RefreshSessionResponse,
} from "types/types";

const { JSON } = AuthMode;

export const login = async (payload: LoginFormPayload) => {
  const { data } = await instance.post<LoginResponse>("/auth/login", payload);
  return data;
};

export const logout = async (payload: LogoutPayload) => {
  const { data } = await instance.post("/auth/logout", { ...payload, mode: JSON });
  return data;
};

export const requestResetPassword = async (payload: { email: string }) => {
  const { data } = await instance.post("/auth/password/request", payload);
  return data;
};

export const resetPassword = async (payload: { token: string; password: string }) => {
  const { data } = await instance.post("/auth/password/reset", payload);
  return data;
};

export const inviteUser = async (payload: { token: string; password: string }) => {
  const { data } = await instance.post("/users/invite/accept", payload);
  return data;
};

// Transforms email from invite token to lower case
export const transformInviteToken = async (payload: { token: string }) => {
  const { data } = await instance.post("/custominvite", payload);
  return data;
};

export const refreshSession = async (refresh_token: string) => {
  const { data } = await instance.post<RefreshSessionResponse>("/auth/refresh", {
    refresh_token,
    mode: JSON,
  });

  return data;
};
