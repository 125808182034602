import { CountryCodes } from "types/types";

export const getSuccessfullySentTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let alert = {
    success: "Changes have been saved!",
  };

  if (isPolish) {
    alert = {
      success: "Zmiany zostały zapisane!",
    };
  }

  return {
    alert,
  };
};
