import { useRef, MouseEvent, useState, useEffect } from "react";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { isMobile } from "utils/utils";
import { CountryCodes } from "types/types";

import { ReactComponent as ChevronIcon } from "assets/icons/chevron-down.svg";

const Container = styled.div<{ open: boolean }>`
  position: relative;

  .chevron-icon {
    width: 2rem;
    position: absolute;
    top: 0;
    right: 0;

    ${({ open }) =>
      open &&
      css`
        transform: rotate(180deg);
      `}
  }
`;

const TextField = styled.div<{ isOverflow: boolean; open: boolean }>`
  height: 1.9rem;
  font-size: 1.4rem;

  @media screen and (min-height: 616px) {
    font-size: 1.6rem;
  }

  ${({ open }) =>
    open &&
    css`
      width: 92%;
      height: auto;
      padding-bottom: 1rem;
    `}

  ${({ isOverflow, open }) =>
    isOverflow &&
    !open &&
    css`
      width: 87.5%;
      white-space: nowrap;
      overflow: hidden;

      &::after {
        content: "...";
        position: absolute;
        right: 3.4rem;
        bottom: 0.1rem;
      }
    `}
`;

const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let expand = "Expand";
  let collapse = "Collapse";

  if (isPolish) {
    expand = "Rozwiń";
    collapse = "Zwiń";
  }

  return { expand, collapse };
};

type SubTextProps = {
  id: string;
  html: string;
  toggleChevron: (id: string) => void;
  open: boolean;
};

function SubText({ id, html, toggleChevron, open }: SubTextProps) {
  const [isOverflow, setIsOverflow] = useState(false);
  const subtextRef = useRef<HTMLDivElement>(null);
  const {
    i18n: { language },
  } = useTranslation();
  const { expand, collapse } = getTranslations(language);

  let title = "";

  if (!isMobile) {
    title = expand;

    if (open) {
      title = collapse;
    }
  }

  useEffect(() => {
    if (subtextRef.current) {
      const { clientHeight, scrollHeight } = subtextRef.current;
      setIsOverflow(scrollHeight > clientHeight);
    }
  }, [id]);

  const onClick = (id: string) => (e: MouseEvent<HTMLDivElement>) => {
    if (!isOverflow) return;

    e.stopPropagation();
    toggleChevron(id);
  };

  return (
    <Container open={open} onClick={onClick(id)}>
      <TextField
        ref={subtextRef}
        isOverflow={isOverflow}
        open={open}
        dangerouslySetInnerHTML={{ __html: html }}
      />

      {isOverflow && <ChevronIcon className='chevron-icon' title={title} />}
    </Container>
  );
}

export default SubText;
