import { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import useCookie from "react-use-cookie";

import { ErrorType } from "types/qr-login.types";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "utils/utils";
import AuthContext from "contexts/auth-context/AuthContext";

import { ReactComponent as ErrorIcon } from "assets/icons/error-2.svg";

import QrLoginContainer from "components/molecules/qr-login-container/QrLoginContainer";
import Button from "components/atoms/Button";

const TokenError = styled.div`
  max-width: 40rem;
  margin: 3rem auto;
  text-align: center;

  @media screen and (min-height: 508px) {
    margin: 5rem auto;
  }

  .button {
    font-size: 1.6rem;
  }
`;

const Alert = styled.div`
  border-radius: 0.4rem;
  font-family: GothamBold;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.aliceBlue};
  border-left: 1rem solid ${({ theme }) => theme.errorColor};

  @media (min-width: 384px) {
    font-size: 1.7rem;
  }

  .icon {
    width: 3rem;
    fill: ${({ theme }) => theme.errorColor};
    transform: translateX(-2px);
  }
`;

const { INVALID_TOKEN, NO_TOKEN } = ErrorType;

function QrLoginError() {
  const { qrTokenStatus, setQrTokenStatus } = useContext(AuthContext);
  const [, setAccessToken] = useCookie(ACCESS_TOKEN, "");
  const [, setRefreshToken] = useCookie(REFRESH_TOKEN, "");
  const [searchParams] = useSearchParams();
  const { t } = useTranslation("login");
  const navigate = useNavigate();
  const errorType = searchParams.get("type") as ErrorType;
  let message = "";

  switch (errorType) {
    case INVALID_TOKEN:
      message = t("login-with-qr.error.no-access");
      break;
    case NO_TOKEN:
      message = t("login-with-qr.error.no-token");
  }

  const onBackToLoginPageBtnClick = () => {
    if (qrTokenStatus) {
      setQrTokenStatus(null);
    }

    setAccessToken("");
    setRefreshToken("");

    navigate("/login", { replace: true });
  };
  return (
    <QrLoginContainer>
      <TokenError>
        <Alert>
          <ErrorIcon className='icon' />
          <span>{message}</span>
        </Alert>
        <Button
          label={t("login-with-qr.button.back-to-sing-in-page")}
          customClass='button'
          onClick={onBackToLoginPageBtnClick}
        />
      </TokenError>
    </QrLoginContainer>
  );
}

export default QrLoginError;
