import useCookie, { getCookie } from "react-use-cookie";
import { Tokens } from "types/types";

import { ACCESS_TOKEN, REFRESH_TOKEN } from "utils/utils";

function useTokens() {
  const [, setAccessToken] = useCookie(ACCESS_TOKEN, "");
  const [, setRefreshToken] = useCookie(REFRESH_TOKEN, "");

  const accessToken = getCookie(ACCESS_TOKEN);
  const refreshToken = getCookie(REFRESH_TOKEN);

  const setTokens = ({ access_token, refresh_token }: Tokens) => {
    setAccessToken(access_token);
    setRefreshToken(refresh_token);
  };

  return { accessToken, refreshToken, setTokens };
}

export default useTokens;
