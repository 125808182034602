import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { ReactComponent as CameraOffIcon } from "assets/icons/camera-off.svg";

import Button from "components/atoms/Button";
import { CountryCodes } from "types/types";

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  color: #fff;
  z-index: ${({ theme }) => theme.level2};

  .camera-off {
    width: 40%;
    height: 40%;

    path {
      stroke: ${({ theme }) => theme.white};
    }
  }
`;

const Content = styled.div`
  width: 90%;
  height: 90%;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem 2rem;
  border-radius: 0.6rem;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Message = styled.div`
  font-size: 2rem;
`;

const Info = styled.div`
  font-size: 1.6rem;
  margin-top: 3rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
`;

function PhotoNotTakenAlert() {
  const {
    i18n: { language },
  } = useTranslation();

  const isPolish = language.includes(CountryCodes.PL);

  let message = "Failed to take photo...";
  let infoTop = "An unexpected camera error occurred,";
  let infoBottom = "preventing the photo from being taken.";
  let btnLabel = "Try again";

  if (isPolish) {
    message = "Nie udało się zrobić zdjęcia...";
    infoTop = "Wystąił nieoczekiwany błąd kamery,";
    infoBottom = "który uniemożliwił wykonanie zdjęcia.";
    btnLabel = "Spróbuj ponownie";
  }

  return (
    <Container>
      <Content>
        <CameraOffIcon className='camera-off' />
        <Message>{message}</Message>
        <Info>
          <div>{infoTop}</div>
          <div>{infoBottom}</div>
        </Info>
        <ButtonContainer>
          <Button label={btnLabel} onClick={() => window.location.reload()} />
        </ButtonContainer>
      </Content>
    </Container>
  );
}

export default PhotoNotTakenAlert;
