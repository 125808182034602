import { Helmet } from "react-helmet-async";

function Title() {
  return (
    <Helmet>
      <title>SnapCheck</title>
    </Helmet>
  );
}

export default Title;
