import { MouseEvent, useContext, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { device, isIOS, isMobile } from "utils/utils";
import useModal from "hooks/useModal";
import useDevices from "hooks/useDevices";
import useIndexedDB from "hooks/useIndexedDB";
import useNotification from "hooks/useNotification";
import useClickOutside from "hooks/useClickOutside";
import useSerialNumberPath from "hooks/useSerialNumberPath";
import UserContext from "contexts/user-context/UserContext";

import { ActionStatus, Statuses, Stores } from "types/types";

import { ReactComponent as Keyboard } from "assets/icons/keypad-3.svg";
import { ReactComponent as QrCode } from "assets/icons/qr-code-2.svg";
import { ReactComponent as BinIcon } from "assets/icons/bin.svg";
import { ReactComponent as MoreIcon } from "assets/icons/more-vertical.svg";

import BackArrow from "components/atoms/BackArrow";
import ConfirmationModal from "components/organisms/ConfirmationModal";

import {
  ContentContainer,
  OptionsContainer,
  OptionLabel,
  Option,
} from "styles/generalStyles";

const Header = styled.div<{ isMobile: boolean }>`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 0.2rem;
  font-family: GothamBold;
  text-align: center;
  position: relative;

  @media screen and (min-height: 616px) {
    margin-top: 2rem;
  }

  .header-title {
    display: inline-block;
    max-width: 82%;
    font-size: 2rem;

    @media screen and (min-width: 360px) {
      font-size: 2.2rem;
    }

    @media screen and (min-width: 380px) {
      font-size: 2.4rem;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .move-arrow {
    top: 50%;
    transform: translateY(-50%);
  }
`;

const ContentWrapper = styled.div`
  ${({ theme }) => theme.fillUpRemainingSpace};
  overflow: hidden;
`;

const LastUsedContainer = styled.div`
  height: calc(100% - 9rem);
`;

export const OptionsContainerFlex = styled(OptionsContainer)<{ isVideoInput: boolean }>`
  height: 9rem;
  transform: translateY(5px);
  overflow-y: hidden;

  ${({ isVideoInput }) =>
    isVideoInput &&
    css`
      display: flex;
      justify-content: space-between;
      gap: 1rem;
    `}
`;

const OptionFlex = styled(Option)<{ light?: boolean; isVideoInput: boolean }>`
  height: 90%;
  width: 100%;
  ${({ isVideoInput }) =>
    isVideoInput &&
    css`
      flex-basis: 50%;
    `}

  .icon {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 2.4rem;
    height: 2.4rem;

    g {
      fill: ${({ theme }) => theme.white};
    }
  }

  .scanner {
    left: 0.7rem;
  }

  ${({ light }) =>
    light &&
    css`
      background-color: #7a8cb1;
    `}
`;

const LastUsedHeader = styled.div<{ empty?: boolean; isMobile?: boolean }>`
  height: 8%;
  max-height: 4rem;
  padding-left: 0.5rem;
  font-family: GothamBold;
  position: relative;
  display: flex;
  align-items: center;

  ${({ empty }) =>
    empty &&
    css`
      font-family: GothamBook;
      font-style: italic;
      height: 100%;
    `}

  .more-icon {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    right: -0.5rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "default")};
  }
`;

const InnerContainer = styled.div`
  height: 95%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const LastUsedList = styled.ul`
  max-height: 100%;
  padding-left: 0.5rem;
  overflow-y: auto;
  margin-top: 1rem;
`;

const LastUsedItem = styled.li<{ isMobile: boolean }>`
  padding: 1rem;
  border-radius: 0.4rem;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.primary_100};
  color: ${({ theme }) => theme.primary};
  ${({ theme }) => theme.cardShadow};
  margin-bottom: 1rem;
  font-size: 1.8rem;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
  transition: filter 0.2s ease-out;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    filter: brightness(98%);
  }

  .bin {
    width: 2.5rem;
    height: 2.5rem;

    path {
      stroke: ${({ theme }) => theme.primary};
    }
  }

  @media screen and (min-height: 571px) {
    padding: 1.5rem 1rem;
  }
`;

const PopUpMenuOutsideContainer = styled.div<{ isMobile: boolean }>`
  width: 75%;
  position: absolute;
  top: 1rem;
  right: 0;
  z-index: ${({ theme }) => theme.level2};
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

  @media ${device.iPad} {
    right: -1rem;
  }
`;

const PopUpMenuContainer = styled.div`
  width: 93%;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 0.4rem;
  ${({ theme }) => theme.cardShadow};
`;

const PopUpMenuContent = styled.div`
  position: relative;
  padding: 1rem 0 1rem 1rem;
`;

const MenuOption = styled.div`
  width: 97%;
  color: ${({ theme }) => theme.textColor1};
  user-select: none;
  padding: 0.7rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: background-color 0.15s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.royalBlue};
    border-radius: 0.4rem;
  }

  .bin {
    width: 2.5rem;
    height: 2.5rem;
    transform: translateY(-0.2rem);

    path {
      stroke: ${({ theme }) => theme.textColor1};
    }
  }
`;

const MenuLabel = styled.span``;

const queries = {
  type: "?method=type",
  scan: "?method=scan",
};

enum ActionTypes {
  DELETE_ONE = "delete-one",
  DELETE_ALL = "delete-all",
}

const { DELETE_ONE, DELETE_ALL } = ActionTypes;

type LastUsed = { id: string; created: Date; count: number }[];

const { SUCCESS, ERROR } = Statuses;
const { SerialNumbers } = Stores;

function SnMethodSelection() {
  const { allItems, deleteItem, deleteAll } = useIndexedDB(SerialNumbers);
  const lastUsed = allItems as LastUsed | undefined;
  const { t } = useTranslation("serial-number");

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleSerialNumberPath = useSerialNumberPath();
  const { isVideoInput } = useDevices();

  const { open, openModal, closeModal } = useModal();
  const [menuOpen, setMenuOpen] = useState(false);

  const numberToBeDeleted = useRef("");
  const action = useRef({ type: "", message: "" });
  const menuRef = useRef(null);

  const notify = useNotification();

  const {
    labels: { serialNumberTitle },
  } = useContext(UserContext);

  let content: JSX.Element | null = null;

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  // --------------- Action handlers ---------------

  const handleDeleteNumberFromDB = async () => {
    const { current: id } = numberToBeDeleted;
    const { status }: ActionStatus = await deleteItem(id);

    if (status === SUCCESS) {
      closeModal();
    }

    if (status === ERROR) {
      closeModal();
      notify(t("notify.number"), "error");
    }
  };

  const handleDeleteAllNumbersFromDB = async () => {
    const { status }: ActionStatus = await deleteAll();

    if (status === SUCCESS) {
      closeModal();
    }

    if (status === ERROR) {
      closeModal();
      notify(t("notify.numbers"), "error");
    }
  };

  // --------------- On click handlers ---------------

  const onLastUsedClick = (serialNumber: string) => () => {
    handleSerialNumberPath(serialNumber);
  };

  const onBackArrowClick = () => {
    navigate("/home", { replace: true });
  };

  const onOptionClick = (query: string) => () => {
    const goToPath = `/enter-serial-number${query}`;

    if (!isIOS) {
      navigate(goToPath);
      return;
    }

    if (query.includes("scan")) {
      sessionStorage.setItem("returnPath", pathname);
      sessionStorage.setItem("goToPath", goToPath);
      navigate("/camera-detection");
    }

    if (query.includes("type")) {
      navigate(goToPath);
    }
  };

  const onBinIconClick = (id: string) => async (e: MouseEvent<SVGSVGElement>) => {
    numberToBeDeleted.current = id;
    action.current = { type: DELETE_ONE, message: t("modal.delete-number") };

    e.stopPropagation();
    openModal();
  };

  const onMoreIconClick = () => {
    openMenu();
  };

  const onMenuOptionClick = () => {
    action.current = { type: DELETE_ALL, message: t("modal.delete-all") };
    closeMenu();
    openModal();
  };

  const onConfirmationClick = () => {
    const {
      current: { type },
    } = action;

    if (type === DELETE_ONE) {
      handleDeleteNumberFromDB();
    }

    if (type === DELETE_ALL) {
      handleDeleteAllNumbersFromDB();
    }
  };

  const handleClickOutside = () => {
    closeMenu();
  };

  useClickOutside(menuRef, handleClickOutside);

  // --------------- Content ---------------

  if (lastUsed) {
    if (lastUsed.length) {
      content = (
        <div style={{ height: "100%" }}>
          <LastUsedHeader isMobile={isMobile}>
            <span>{t("last-used")}:</span>
            <MoreIcon className='more-icon' onClick={onMoreIconClick} />

            {menuOpen && (
              <PopUpMenuOutsideContainer isMobile={isMobile} onClick={closeMenu}>
                <PopUpMenuContainer ref={menuRef}>
                  <PopUpMenuContent>
                    <MenuOption onClick={onMenuOptionClick}>
                      <BinIcon className='bin' />
                      <MenuLabel>{t("menu.delete-all")}</MenuLabel>
                    </MenuOption>
                  </PopUpMenuContent>
                </PopUpMenuContainer>
              </PopUpMenuOutsideContainer>
            )}
          </LastUsedHeader>
          <InnerContainer>
            <LastUsedList>
              {lastUsed.map(({ id }) => (
                <LastUsedItem key={id} isMobile={isMobile} onClick={onLastUsedClick(id)}>
                  <span>&#8226;&nbsp;{id}</span>
                  <BinIcon
                    className='bin'
                    onClick={onBinIconClick(id)}
                    title={t("menu.delete-number")}
                  />
                </LastUsedItem>
              ))}
            </LastUsedList>
          </InnerContainer>
        </div>
      );
    }
  }

  return (
    <ContentContainer>
      <Header isMobile={isMobile}>
        <BackArrow onClick={onBackArrowClick} customClass='move-arrow' />
        <span className='header-title'>{serialNumberTitle}</span>
      </Header>
      <ContentWrapper>
        <LastUsedContainer>{content}</LastUsedContainer>

        <OptionsContainerFlex isVideoInput={isVideoInput}>
          <OptionFlex onClick={onOptionClick(queries.type)} isVideoInput={isVideoInput}>
            <Keyboard className='icon' />
            <OptionLabel>{t("button-label.type")}</OptionLabel>
          </OptionFlex>

          {isVideoInput && (
            <OptionFlex onClick={onOptionClick(queries.scan)} isVideoInput={isVideoInput}>
              <QrCode className='icon scanner' />
              <OptionLabel>{t("button-label.scan")}</OptionLabel>
            </OptionFlex>
          )}
        </OptionsContainerFlex>
      </ContentWrapper>

      <ConfirmationModal
        message={action.current.message}
        onClick={onConfirmationClick}
        onClose={closeModal}
        open={open}
        buttonLabel={t("button-label.delete")}
      />
    </ContentContainer>
  );
}

export default SnMethodSelection;
