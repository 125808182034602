import { CountryCodes } from "types/types";

export const getTopBarTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  //   ----- ENGLISH -----

  let alert = {
    offline: "You're offline",
    update: "Update application",
    updating: "Updating...",
  };

  //   ----- POLISH -----

  if (isPolish) {
    alert = {
      offline: "Jesteś offline",
      update: "Aktualizuj aplikację",
      updating: "Aktualizuję...",
    };
  }

  return { alert };
};
