type Item =
  | "machineProcess"
  | "selectedQuestionnaire"
  | "selectedAccidentForm"
  | "serialNumber";

export const getLocalData = (item: Item) => {
  const localData = localStorage.getItem(item);

  if (item !== "serialNumber") {
    return localData ? JSON.parse(localData) : undefined;
  } else {
    return localData ? localData : "";
  }
};
