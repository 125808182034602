import { instance } from "axios-instance/axios-instance";
import {
  AccidentFormPayload,
  QuestionnairePayload,
  QuestionnairesResponse,
  TaskInProgressResponse,
  Entity,
  EntitiesEnum,
  ChangeEntityStatusPayload,
  ChangeEntityStatusResponse,
  SendQuestionnaireResponse,
  AssignToUserPayload,
  AssignToUserResponse,
  AddCommentToAssessmentPayload,
  AddCommentToAssessmentResponse,
} from "types/types";

export const getQuestionnaires = (token: string, serialNo: string) => async () => {
  const { data } = await instance.get<QuestionnairesResponse>(
    `/responder/questionnaires?serialNo=${serialNo}&multiDepartmentSupport=1`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
};

export const sendQuestionnaire = async (questionnairePayload: QuestionnairePayload) => {
  const { payload, token, onUploadProgress } = questionnairePayload;

  const { data } = await instance.post<SendQuestionnaireResponse>(
    "/responder/assessment",
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress,
    },
  );
  return data;
};

export const sendAccidentReport = async (accidentPayload: AccidentFormPayload) => {
  const { payload, token, onUploadProgress } = accidentPayload;

  const { data } = await instance.post<{ id: string }>("/responder/statement", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onUploadProgress,
  });
  return data;
};

type GetTasksInProgressPayload = {
  machineId: string | undefined;
  processId: string | undefined;
};

export const getTasksInProgress =
  (token: string, payload: GetTasksInProgressPayload) => async () => {
    const { machineId, processId } = payload;
    const { MACHINE, PROCESS } = EntitiesEnum;

    let id: string | undefined;
    let entity: Entity;

    if (machineId) {
      id = machineId;
      entity = MACHINE;
    }

    if (processId) {
      id = processId;
      entity = PROCESS;
    }

    const { data } = await instance.get<TaskInProgressResponse>(
      `/items/Answers?limit=25&fields[]=status&fields[]=date_created&fields[]=OriginalText&fields[]=Assessment.Questionnaire.QuestionnaireTitle&fields[]=Assessment.id&fields[]=Value&fields[]=ReactionValue&fields[]=FilesGalleryOurs.directus_files_id.id&fields[]=FilesGalleryOurs.directus_files_id.type&fields[]=FilesGalleryOurs.directus_files_id.title&fields[]=FilesGalleryOurs.directus_files_id.description&fields[]=FilesGalleryOurs.id&fields[]=id&sort[]=-date_created&page=1&filter[_and][0][status][_eq]=in_progress&filter[_and][0][${entity}][id][_eq]=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return data;
  };

export const changeEntityStatus = async (payload: ChangeEntityStatusPayload) => {
  const { id, collection, status, token } = payload;

  const { data } = await instance.patch<ChangeEntityStatusResponse>(
    `/items/${collection}/${id}?fields=id,status`,
    { status },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
};

export const assignToUser = async (payload: AssignToUserPayload) => {
  const { userId, assessmentId, token } = payload;

  const { data } = await instance.patch<AssignToUserResponse>(
    `/items/Assessments/${assessmentId}?fields=id,AssignTo`,
    { AssignTo: userId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
};

export const addCommentToAssessment = async (payload: AddCommentToAssessmentPayload) => {
  const { token, ...body } = payload;

  const { data } = await instance.post<AddCommentToAssessmentResponse>(
    "/activity/comment?fields=id,item",
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return data;
};
