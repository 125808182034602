import { ChangeEvent, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import useDictaphoneAccess from "hooks/useDictaphoneAccess";
import { QuestionType } from "types/types";
import { getTranslations } from "./translations/reaction-modal.translations";
import { focusElement, placeCursorAtTheEnd, scrollToBottom } from "utils/utils";

import Modal from "components/templates/Modal";
import Button from "components/atoms/Button";
import Dictaphone from "components/molecules/Dictaphone";

import { ModalStyles, TextArea } from "styles/generalStyles";

const { Content, Actions, ButtonContainer } = ModalStyles;

const BtnWrapper = styled.div`
  .save-btn {
    height: 5rem;
  }
`;

type EditReactionModalProps = {
  open: boolean;
  onClose: () => void;
  questionType?: QuestionType | "";
  reactionTextAreaValue: string;
  onReactionTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onTextTranscriptChange: (val: string) => void;
  onSaveReactionBtnClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  header?: string;
  optional?: boolean;
};

function ReactionModal({
  open,
  onClose,
  questionType,
  reactionTextAreaValue,
  onReactionTextAreaChange,
  onTextTranscriptChange,
  onSaveReactionBtnClick,
  loading = false,
  disabled = false,
  header = "",
  optional = false,
}: EditReactionModalProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation(["questionnaires", "common"]);
  const [searchParams] = useSearchParams();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { isDictaphoneAccessible } = useDictaphoneAccess();

  const { headerLabel } = getTranslations(language);
  const modalHeader = !!header ? header : headerLabel;
  const isListening = !!searchParams.get("listening");
  const isEmpty = !reactionTextAreaValue;

  let btnDisabled = disabled || isListening || isEmpty;

  if (optional) {
    btnDisabled = disabled || isListening;
  }

  const onButtonClick = () => {
    onSaveReactionBtnClick();
  };

  useEffect(() => {
    if (!open || !textareaRef.current) return;

    const { current: textArea } = textareaRef;
    const textLength = reactionTextAreaValue ? reactionTextAreaValue.length : 0;

    focusElement(textArea);
    scrollToBottom(textArea);
    placeCursorAtTheEnd(textArea, textLength);
  }, [open, reactionTextAreaValue]);

  return (
    <Modal header={modalHeader} onClose={onClose} open={open} closeDisabled={isListening}>
      <Content>
        <TextArea
          rows={8}
          onChange={onReactionTextAreaChange}
          value={reactionTextAreaValue}
          ref={textareaRef}
          smallFont
        />
        <Actions spaceBetween={isDictaphoneAccessible}>
          {isDictaphoneAccessible && (
            <Dictaphone
              onTextTranscriptChange={onTextTranscriptChange}
              questionType={questionType}
              answerTextAreaValue={reactionTextAreaValue}
            />
          )}

          <ButtonContainer>
            <BtnWrapper>
              <Button
                onClick={onButtonClick}
                label={t("common:button.save")}
                loading={loading}
                disabled={btnDisabled}
                customClass='save-btn'
              />
            </BtnWrapper>
          </ButtonContainer>
        </Actions>
      </Content>
    </Modal>
  );
}

export default ReactionModal;
