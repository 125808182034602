import { instance } from "axios-instance/axios-instance";
import { ComapaniesInfoResponse } from "types/types";

type GetCompanyPayload = { token: string; companyId: string };

export const getCompany = (payload: GetCompanyPayload) => async () => {
  const { token, companyId } = payload;

  const { data } = await instance.get<ComapaniesInfoResponse>(
    `/items/Comapanies?filter[id]=${companyId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return data;
};
