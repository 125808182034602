import { useRef } from "react";
import { Id, toast, TypeOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const params = {
  autoClose: 2500,
  style: { fontSize: "1.8rem" },
  number: 1,
};

function useNotification() {
  const toastId = useRef<Id>("");

  const notify = (message: string, type: TypeOptions, autoClose?: number) => {
    const p = !autoClose ? params : { ...params, autoClose };

    if (!toast.isActive(toastId.current)) {
      if (type !== "default") {
        toastId.current = toast[type](message, p);
      }
    }
  };

  return notify;
}

export default useNotification;
