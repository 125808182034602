import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { ReactComponent as ErrorIcon } from "assets/icons/error-2.svg";

import Button from "components/atoms/Button";

const ErrorContainer = styled.div<{ smallMargin?: boolean }>`
  margin-top: ${({ smallMargin }) => (smallMargin ? "1rem" : "4rem")};
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.textColor1};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.errorColor};
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 0.4rem;
  margin-bottom: 1.5rem;

  .error-icon {
    fill: ${({ theme }) => theme.textColor1};
    width: 3rem;
  }
`;

function ErrorAlert({
  onClick,
  message,
  smallMargin,
}: {
  onClick?: () => void;
  message: string;
  smallMargin?: boolean;
}) {
  const { t } = useTranslation("common");

  return (
    <ErrorContainer smallMargin={smallMargin}>
      <ErrorMessage>
        <ErrorIcon className='error-icon' />
        <span>{message}</span>
      </ErrorMessage>

      {onClick && <Button label={t("button.try-again")} onClick={onClick} />}
    </ErrorContainer>
  );
}

export default ErrorAlert;
