import styled from "styled-components/macro";

import { URL_REGEX } from "utils/utils";

import { ReactComponent as LinkIcon } from "assets/icons/link.svg";

const Container = styled.div`
  .line {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
  }

  .link {
    display: inline-block;
    background-color: ${({ theme }) => theme.primary_600};
    padding: 0 0.7rem;
    margin-right: 0.5rem;
    border-radius: 0.4rem;
    font-size: 1.6rem;

    cursor: pointer;

    &-icon {
      width: 16px;
      height: 16px;
      margin-left: 0.2rem;
      transform: translateY(0.2rem);

      path {
        stroke: ${({ theme }) => theme.white};
      }
    }
  }

  a {
    color: ${({ theme }) => theme.white};
    text-decoration: none;
  }
`;

function Linkify({ text }: { text: string | null }) {
  let content = null;

  const isUrl = (word: string) => word.match(URL_REGEX);

  const checkIfLineContainsUrl = (line: string) => {
    const wordsArray = line.split(" ");
    const hasUrl = wordsArray.some((word) => isUrl(word));

    return hasUrl;
  };

  const buildLineWithLinkIcon = (line: string) => {
    const wordsArray = line.split(" ");

    return wordsArray.map((word, i) =>
      isUrl(word) ? (
        <span className='link' key={`${word}-${i}`}>
          <a href={word} target='_blank' rel='noopener noreferrer'>
            <span>link</span>
            <LinkIcon className='link-icon' />
          </a>
        </span>
      ) : (
        <span key={`${word}-${i}`}>{word}&nbsp;</span>
      ),
    );
  };

  const addMarkup = (line: string, i: number) => {
    if (checkIfLineContainsUrl(line)) {
      return (
        <span className='line' key={`${line}-${i}`}>
          {buildLineWithLinkIcon(line)}
        </span>
      );
    }

    return !!line ? (
      <span className='line' key={`${line}-${i}`}>
        {line}
      </span>
    ) : (
      <span key={`${line}-${i}`}>
        <br></br>
      </span>
    );
  };

  if (text) {
    const lines = text.split("\n");

    content = lines.map((line, i) => addMarkup(line, i));
  }

  return (
    <Container>
      <span>{content}</span>
    </Container>
  );
}

export default Linkify;
