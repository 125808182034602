import { useContext } from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components/macro";

import UserContext from "contexts/user-context/UserContext";
import { colorMap, isMobile } from "utils/utils";

import { ReactComponent as UserIcon } from "assets/icons/profile-user.svg";
import AvatarImage from "components/molecules/avatar-image/AvatarImage";

const Container = styled.div<{
  isMobile: boolean;
}>`
  position: relative;
  display: flex;
  cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};
`;

const Initials = styled.div<{ initial: string }>`
  height: 3.1rem;
  width: 3.1rem;
  border-radius: 50%;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ initial }) => colorMap[`${initial}`]};
  color: ${({ theme }) => theme.textColor1};
  font-family: GothamBold;

  @media screen and (min-height: 616px) {
    height: 4rem;
    width: 4rem;
    font-size: 1.6rem;
  }
`;

const Loader = styled.div`
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  position: relative;
  background-color: ${({ theme }) => theme.primary_50};
  opacity: 0.4;
`;

const Shimmer = styled.div`
  ${({ theme }) => theme.shimmerEffect}
`;

const UserIconContainer = styled.div<{
  isMobile: boolean;
}>`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: rgba(196, 205, 222, 0.5);
  border: 2px solid ${({ theme }) => theme.primary};

  ${({ isMobile }) =>
    isMobile &&
    css`
      @media screen and (orientation: landscape) {
        width: 2.5rem;
        height: 2.5rem;
        transform: translateY(0.2rem);
      }
    `}

  .user-icon {
    fill: ${({ theme }) => theme.primary};
    width: 100%;
    height: 100%;
  }
`;

const UserIconFallback = () => (
  <UserIconContainer isMobile={isMobile}>
    <UserIcon className='user-icon' />
  </UserIconContainer>
);

function UserAvatar() {
  const { user, isLoading, isSuccess, isError, userInitials, toggleSideMenu } =
    useContext(UserContext);

  const { pathname } = useLocation();

  let initial = "";
  let content = null;

  if (userInitials.charAt(1)) {
    initial = userInitials.charAt(1);
  } else if (userInitials.charAt(0)) {
    initial = userInitials.charAt(0);
  }

  if (isLoading) {
    content = (
      <Loader>
        <Shimmer />
      </Loader>
    );
  }

  if (isSuccess) {
    if (user && user.avatar) {
      const { avatar } = user;
      const avatarId = typeof avatar === "string" ? avatar : avatar.id;

      content = <AvatarImage avatarId={avatarId} />;
    } else if (userInitials) {
      content = <Initials initial={initial}>{userInitials}</Initials>;
    } else {
      content = <UserIconFallback />;
    }
  }

  if (isError) {
    content = <UserIconFallback />;
  }

  const onAvatarClick = () => {
    if (pathname !== "/production-server-warning") {
      toggleSideMenu();
    }
  };

  return (
    <Container isMobile={isMobile} onClick={onAvatarClick}>
      {content}
    </Container>
  );
}

export default UserAvatar;
