import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useCookie from "react-use-cookie";
import { useTranslation } from "react-i18next";

import useTimeout from "hooks/useTimeout";
import useDeviceAndAppInfo from "hooks/useDeviceAndAppInfo";
import { ACCESS_TOKEN, REFRESH_TOKEN, getRandomNumber } from "utils/utils";
import AuthContext from "contexts/auth-context/AuthContext";
import UserContext from "contexts/user-context/UserContext";
import { ErrorType } from "types/qr-login.types";

import ProgressBar from "components/atoms/loaders/ProgressBarLoader";
import QrLoginContainer from "components/molecules/qr-login-container/QrLoginContainer";

const { INVALID_TOKEN, NO_TOKEN } = ErrorType;

const delay = getRandomNumber();

function QrLogin() {
  const [accessToken, setAccessToken] = useCookie(ACCESS_TOKEN, "");
  const [refreshToken, setRefreshToken] = useCookie(REFRESH_TOKEN, "");

  const [progressVisible, setProgressVisible] = useState(false);
  const { qrTokenStatus } = useContext(AuthContext);
  const { setUserInfoEnabled } = useContext(UserContext);

  const { setUpDeviceAndAppSettings } = useDeviceAndAppInfo();

  const { t } = useTranslation("login");
  const setTimeOut = useTimeout();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const Personal_token = searchParams.get("Personal_token");
  const SerialNo = searchParams.get("SerialNo");

  const navigateToErrorPage = useCallback(
    (errorType: string) => {
      navigate(`/qr-login-error?type=${errorType}`, {
        replace: true,
      });
    },
    [navigate],
  );

  const setTokens = useCallback(
    (token: string) => {
      setAccessToken(token);
      setRefreshToken(token);
    },
    [setAccessToken, setRefreshToken],
  );

  if (Personal_token) {
    if (!accessToken && !refreshToken) {
      setTokens(Personal_token);
    }
  } else {
    navigateToErrorPage(NO_TOKEN);
  }

  const redirectToProperPage = useCallback(() => {
    setTimeOut(() => {
      const path = SerialNo ? `/check-lists?SerialNo=${SerialNo}` : "/home";
      navigate(path, { replace: true });
    }, delay);
  }, [setTimeOut, navigate, SerialNo]);

  // --------------- Effect handlers ---------------

  useEffect(() => {
    setUpDeviceAndAppSettings();
  }, [setUpDeviceAndAppSettings]);

  useEffect(() => {
    if (qrTokenStatus === "invalid") {
      navigateToErrorPage(INVALID_TOKEN);
    }

    if (qrTokenStatus === "valid") {
      redirectToProperPage();
      setProgressVisible(true);
    }
  }, [qrTokenStatus, redirectToProperPage, navigateToErrorPage, t]);

  useEffect(() => {
    if (!!accessToken && !!refreshToken) {
      setUserInfoEnabled(true);
    }
  }, [accessToken, refreshToken, setUserInfoEnabled]);

  return (
    <QrLoginContainer>
      {progressVisible && <ProgressBar delay={delay} />}
    </QrLoginContainer>
  );
}

export default QrLogin;
