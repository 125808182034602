import { baseURL } from "axios-instance/axios-instance";
import { Environments, Domains } from "types/environment.types";

const { DEVELOPMENT, PRODUCTION, TEST } = Environments;
const { SNAP_BUG, SNAP_CHECK, SENSOTEC } = Domains;
const DOMAINS_ARRAY = [SNAP_BUG, SNAP_CHECK, SENSOTEC];

const init = { [SNAP_BUG]: false, [SNAP_CHECK]: false, [SENSOTEC]: false };

const checkDomain = (domainName: Domains) =>
  window.location.hostname.includes(domainName);

const checkProxyTarget = (domainName: Domains) =>
  process.env.REACT_APP_SETUP_PROXY_TARGET?.includes(domainName) ?? false;

const checkServer = (domainName: Domains) => baseURL.includes(domainName);

const environment = {
  [DEVELOPMENT]: process.env.NODE_ENV === DEVELOPMENT,
  [PRODUCTION]: process.env.NODE_ENV === PRODUCTION,
  [TEST]: process.env.REACT_APP_PORT === "9001",
};

const getEntityObject = (cb: (d: Domains) => boolean) =>
  DOMAINS_ARRAY.reduce((acc, curr) => {
    return { ...acc, [curr]: cb(curr) };
  }, init);

const domain = getEntityObject(checkDomain);
const target = getEntityObject(checkProxyTarget);
const server = getEntityObject(checkServer);

const serverWarnLabel = process.env.REACT_APP_SERVER_WARN_LABEL ?? "";

export { environment, serverWarnLabel, domain, server, target };
