import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreatedTask } from "types/tasks.types";

type SelectedTask = CreatedTask | undefined;

type InitialState = {
  allTasks: CreatedTask[];
  selectedTask: SelectedTask;
};

const initialState: InitialState = {
  allTasks: [],
  selectedTask: undefined,
};

export const tasksSlice = createSlice({
  name: "tasksSlice",
  initialState,
  reducers: {
    setAllTasks(state, action: PayloadAction<CreatedTask[]>) {
      return { ...state, allTasks: action.payload };
    },
  },
});

export const { setAllTasks } = tasksSlice.actions;
