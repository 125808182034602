import { ArrowContainer } from "styles/generalStyles";

import { isMobile } from "utils/utils";

import { ReactComponent as ArrowBack } from "assets/icons/arrow-back.svg";

type BackArrowProps = {
  onClick?: () => void;
  customClass?: string;
};

function BackArrow({ onClick, customClass = "" }: BackArrowProps) {
  return (
    <ArrowContainer className={customClass} isMobile={isMobile}>
      <ArrowBack className='arrow' onClick={onClick} />
    </ArrowContainer>
  );
}

export default BackArrow;
