import styled, { css } from "styled-components/macro";

import { CheckListsContainer } from "styles/generalStyles";

const CheckListPlaceholder = styled.div<{ single?: boolean; questionnaires?: boolean }>`
  ${({ theme }) => theme.checkListItem}
  height: 10rem;
  overflow: hidden;

  ${({ single }) =>
    single &&
    css`
      @media screen and (min-height: 616px) {
        height: 12rem;
      }
    `}

  ${({ questionnaires }) =>
    questionnaires &&
    css`
      /* height: 15rem; */
    `}

    &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;

const Shimmer = styled.div`
  ${({ theme }) => theme.shimmerEffect}
`;

function Placeholders({
  single = false,
  questionnaires = false,
}: {
  single?: boolean;
  questionnaires?: boolean;
}) {
  const content = single ? [1] : [1, 2];

  return (
    <CheckListsContainer>
      {content.map((item) => (
        <CheckListPlaceholder key={item} single={single} questionnaires={questionnaires}>
          <Shimmer />
        </CheckListPlaceholder>
      ))}
    </CheckListsContainer>
  );
}

export default Placeholders;
