import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let headerLabel = "Description";

  if (isPolish) {
    headerLabel = "Opis";
  }

  return { headerLabel };
};
