import { getCookie } from "react-use-cookie";
import styled, { css } from "styled-components/macro";

import { ACCESS_TOKEN, device } from "utils/utils";
import { baseURL } from "axios-instance/axios-instance";

const Container = styled.div<{ large?: boolean }>`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid rgb(160, 160, 160);

  @media screen and (min-height: 616px) {
    width: 4rem;
    height: 4rem;
  }

  ${({ large }) =>
    large &&
    css`
      width: 4.5rem;
      height: 4.5rem;
      border: none;

      @media screen and (min-height: 616px) {
        width: 5rem;
        height: 5rem;
      }

      @media ${device.iPad} {
        width: 6rem;
        height: 6rem;
      }
    `};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

type AvatarImageProps = {
  avatarId: string;
  large?: boolean;
};

function AvatarImage({ avatarId, large = false }: AvatarImageProps) {
  const token = getCookie(ACCESS_TOKEN);
  const src = `${baseURL}assets/${avatarId}?access_token=${token}`;

  return (
    <Container large={large}>
      <Image src={src} alt='Avatar' />
    </Container>
  );
}

export default AvatarImage;
