import styled, { css } from "styled-components/macro";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

import { isMobile } from "utils/utils";
import useLanguage from "hooks/useLanguage";
import { CountryCode, CountryCodes } from "types/types";

const Container = styled.div<{ paddingRight: boolean }>`
  display: flex;
  gap: 1.5rem;

  ${({ paddingRight }) =>
    paddingRight &&
    css`
      padding-right: 1.7rem;
    `}
`;

const FlagContainer = styled.div<{ active: boolean; isMobile: boolean }>`
  .flag {
    width: 4rem;
    height: 4rem;
    cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

    filter: drop-shadow(3px -2px 2px rgb(0 0 0 / 0.25)) grayscale(100%) opacity(0.7);

    ${({ active }) =>
      active &&
      css`
        filter: drop-shadow(3px -2px 2px rgb(0 0 0 / 0.25)) grayscale(0) opacity(1);
      `}
  }
`;

const { PL, EN } = CountryCodes;

const style = { width: "3rem", height: "3rem" };

function Flags({ paddingRight = false }: { paddingRight?: boolean }) {
  const { i18n } = useTranslation("common");
  const { language, setAppLanguage } = useLanguage();

  const onFlagClick = (code: CountryCode) => () => {
    i18n.changeLanguage(code);

    setAppLanguage(code);
  };

  return (
    <Container paddingRight={paddingRight}>
      <FlagContainer active={language === PL} isMobile={isMobile}>
        <ReactCountryFlag
          countryCode='PL'
          svg
          className='flag'
          style={style}
          onClick={onFlagClick(PL)}
        />
      </FlagContainer>
      <FlagContainer active={language === EN} isMobile={isMobile}>
        <ReactCountryFlag
          countryCode='GB'
          svg
          className='flag'
          style={style}
          onClick={onFlagClick(EN)}
        />
      </FlagContainer>
    </Container>
  );
}

export default Flags;
