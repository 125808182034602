import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";
import { useTranslation } from "react-i18next";

import "swiper/css";
import "swiper/css/effect-cards";

import useTimeout from "hooks/useTimeout";
import useModal from "hooks/useModal";
import useScreen from "hooks/useScreen";
import usePhotosObjectStore from "hooks/usePhotosObjectStore";
import {
  FilesGallery,
  FormType,
  QuestionType,
  QUESTION_TYPE_IDS,
  Answers,
  Answer,
} from "types/types";
import { isMobile, device } from "utils/utils";
import { getPromptTranslations } from "components/molecules/action-prompts/translations/prompt.translations";
import { PromptType } from "components/molecules/action-prompts/types/prompt.types";

import ConfirmationModal from "components/organisms/ConfirmationModal";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as AddPhotoIcon } from "assets/icons/add-photo.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info-circle.svg";

import Button from "components/atoms/Button";
import PhotoLimitInfo from "components/molecules/PhotoLimitInfo";

const Container = styled.div<{
  imageSize: ImageSize;
  smallView: boolean;
  isMobile: boolean;
}>`
  position: relative;
  margin-bottom: 1rem;

  .swiper-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(-5rem, 0, 0);
    cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
  }

  .swiper,
  .icons-container {
    /* small phones */
    height: ${({ imageSize, smallView }) => {
      const divisor = smallView ? 30 : 22;
      return `${imageSize.height / divisor}rem`;
    }};
    width: ${({ imageSize, smallView }) => {
      const divisor = smallView ? 30 : 22;
      return `${imageSize.width / divisor}rem`;
    }};

    @media screen and (min-height: 571px) {
      height: ${({ imageSize, smallView }) => {
        const divisor = smallView ? 28 : 25;
        return `${imageSize.height / divisor}rem`;
      }};
      width: ${({ imageSize, smallView }) => {
        const divisor = smallView ? 28 : 25;
        return `${imageSize.width / divisor}rem`;
      }};
    }

    @media ${device.iPad} {
      height: ${({ imageSize, smallView }) => {
        // const divisor = smallView ? 14 : 12;
        const divisor = smallView ? 28 : 26;
        return `${imageSize.height / divisor}rem`;
      }};

      width: ${({ imageSize, smallView }) => {
        // const divisor = smallView ? 14 : 12;
        const divisor = smallView ? 28 : 26;
        return `${imageSize.width / divisor}rem`;
      }};
    }
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
    border: 4px solid ${({ theme }) => theme.aliceBlue};
    -webkit-box-shadow: 8px 8px 6px -5px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 6px -5px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 6px -5px rgba(66, 68, 90, 1);
  }
`;

const Wrapper = styled.div`
  padding-top: 2rem;
  padding-bottom: 4rem;
  border-radius: 0.6rem;
  background-color: ${({ theme }) => theme.white};
`;

const PhotoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 0.4rem;
  box-sizing: border-box;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.4rem;
  }

  .bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 3.5rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: ${({ theme }) => theme.textColor1};
    display: flex;
    align-items: center;
  }

  .number-container {
    flex-basis: 15%;
    position: relative;
    margin: 0 0.5rem;
  }

  .photo {
    &-number {
      font-size: 1.5rem;
      z-index: ${({ theme }) => theme.level1};
      display: grid;
      place-content: center;
      font-weight: 600;
    }

    &-comment {
      flex-basis: 79%;
      font-size: 1.3rem;
      ${({ theme }) => theme.ellipsisMultiline};
      -webkit-line-clamp: 1;
      font-style: italic;
    }
  }
`;

const IconsContainer = styled.div<{
  limitReached: boolean;
  isMobile: boolean;
}>`
  position: absolute;
  right: 1.7rem;
  top: 2rem;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  .info-icon {
    width: 2.5rem;
    cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "default")};

    circle,
    line {
      stroke: ${({ theme }) => theme.primary_600};
      stroke-width: 0.2rem;
    }
  }

  .add-photo-icon {
    transform: translateY(1.8rem);
    cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "default")};

    path {
      fill: ${({ theme }) => theme.primary_600};
    }

    ${({ limitReached }) =>
      limitReached &&
      css`
        path {
          fill: ${({ theme }) => theme.primary_100};
        }
        cursor: default;
      `}
  }
`;

const SuggestionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem 1rem 1.3rem 1rem;
  z-index: ${({ theme }) => theme.level1};
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.textColor1};
  border-radius: 0.4rem;
  -webkit-box-shadow: 1px 2px 20px -5px rgba(66, 68, 90, 1);
  -moz-box-shadow: 1px 2px 20px -5px rgba(66, 68, 90, 1);
  box-shadow: 1px 2px 20px -5px rgba(66, 68, 90, 1);
`;

const SuggestionTopBar = styled.div`
  display: flex;
  justify-content: flex-end;

  .close-icon {
    width: 2.5rem;
    height: 2.5rem;
    fill: ${({ theme }) => theme.textColor1};
    cursor: pointer;
  }
`;

const SuggestionMessage = styled.div`
  padding-bottom: 1rem;
  width: 90%;
  margin: 0 auto;
`;

const SuggestionFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  .ok-btn {
    height: 3rem;
    flex: 0 1;
    padding: 0 2rem;
    background-color: ${({ theme }) => theme.primary_50};
    color: ${({ theme }) => theme.primary};
    font-size: 1.6rem;
  }
`;

type ImageSize = { width: number; height: number };

type PhotoInAnswerProps = {
  formType: FormType;
  maxPhotos: number | undefined | null;
  removePhoto: (photoIndex: number) => void;
  questionType: QuestionType;
  onTakePhotoBtnClick: () => void;
  currentQuestionId: string;
  answerToCurrentQuestion: Answer | undefined;
  setPhotosInAnswerVisible?: Dispatch<SetStateAction<boolean>>;
};

const { FILE_PHOTO, YES_NO_PHOTO } = QUESTION_TYPE_IDS;
const { NO } = Answers;

function PhotosInAnswer({
  formType,
  maxPhotos,
  removePhoto,
  questionType,
  currentQuestionId,
  onTakePhotoBtnClick,
  answerToCurrentQuestion,
  setPhotosInAnswerVisible,
}: PhotoInAnswerProps) {
  const [photos, setPhotos] = useState<FilesGallery>([]);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [showPhotos, setShowPhotos] = useState(false);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);
  const [suggestionVisible, setSuggestionVisible] = useState(false);
  const [limitAlertVisible, setLimitAlertVisible] = useState(false);
  const [searchParams] = useSearchParams();
  const isPhotoType = questionType === FILE_PHOTO || questionType === YES_NO_PHOTO;

  const { getPhotosFromIndexedDB } = usePhotosObjectStore(formType);

  const {
    t,
    i18n: { language },
  } = useTranslation(["photo-gallery", "common"]);
  const limitReached = photos.length === maxPhotos;
  const title =
    !isMobile && !limitReached ? getPromptTranslations(PromptType.PHOTO, language) : "";

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const setTimeOut = useTimeout();
  const { open, closeModal } = useModal();
  const { smallView } = useScreen();

  const handleDeletePhoto = () => {
    removePhoto(activePhotoIndex);
    closeModal();
  };

  const onAddPhotoIconClick = () => {
    if (!limitReached) {
      onTakePhotoBtnClick();
    } else {
      setLimitAlertVisible(true);
    }
  };

  const onPhotoClick = (index: number) => () => {
    const formType = smallView ? "accident" : "questionnaire";
    const galleryReturnPath = pathname;

    localStorage.setItem("galleryReturnPath", galleryReturnPath);
    navigate(
      `/gallery?formType=${formType}&currentQuestionId=${currentQuestionId}&photoIndex=${index}`,
    );
  };

  const showSuggestion = () => {
    setSuggestionVisible(true);
  };

  const hideSuggestion = () => {
    setSuggestionVisible(false);
  };

  useEffect(() => {
    let isMounted = true;

    if (
      !isPhotoType ||
      (questionType === YES_NO_PHOTO && answerToCurrentQuestion?.Value !== NO)
    ) {
      setPhotos([]);
      setShowPhotos(false);

      return;
    }

    const isCameraView = Boolean(searchParams.get("cameraView"));
    if (isCameraView) return;

    getPhotosFromIndexedDB(currentQuestionId).then((photosFromDB) => {
      if (isMounted) {
        const photos = photosFromDB ? photosFromDB : [];

        setPhotos(photos);
      }
    });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams,
    currentQuestionId,
    isPhotoType,
    answerToCurrentQuestion?.Value,
    questionType,
  ]);

  useEffect(() => {
    if (setPhotosInAnswerVisible) {
      setPhotosInAnswerVisible(!!photos.length);
    }

    if (!photos || !photos.length) {
      return;
    }

    if (!imageSize.width && !imageSize.height) {
      const img = new Image();
      img.src = photos[0].src;

      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img;
        setImageSize({ width, height });
      };
    }

    // Prevents from display error.
    // Swiper library needs another render
    // to display the photos pack the right way.
    setTimeOut(() => {
      setShowPhotos(true);
    }, 100);
  }, [photos, imageSize.height, imageSize.width, setPhotosInAnswerVisible, setTimeOut]);

  useEffect(() => {
    if (limitAlertVisible) {
      setTimeOut(() => {
        setLimitAlertVisible(false);
      }, 2500);
    }
  }, [limitAlertVisible, setTimeOut]);

  return (
    <Container imageSize={imageSize} smallView={smallView} isMobile={isMobile}>
      {suggestionVisible && (
        <SuggestionContainer>
          <SuggestionTopBar>
            <CloseIcon className='close-icon' onClick={hideSuggestion} />
          </SuggestionTopBar>
          <SuggestionMessage>{t("help.photo-comment")}</SuggestionMessage>

          <SuggestionFooter>
            <Button label='OK' onClick={hideSuggestion} customClass='ok-btn' />
          </SuggestionFooter>
        </SuggestionContainer>
      )}

      {showPhotos && !!photos.length && (
        <Wrapper>
          <Swiper
            // loop
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            onSlideChange={({ activeIndex }) => {
              setActivePhotoIndex(activeIndex);
            }}
            onClick={onPhotoClick(activePhotoIndex)}
          >
            {photos.map((image, index) => (
              <SwiperSlide key={`photo-${index}`}>
                <PhotoContainer>
                  <div className='bar'>
                    <div className='number-container'>
                      <div className='photo-number'>&#x28;{index + 1}&#x29;</div>
                    </div>
                    <div className='photo-comment'>{image.comment}</div>
                  </div>
                  <img src={image.src} alt='' />
                </PhotoContainer>
              </SwiperSlide>
            ))}
          </Swiper>
        </Wrapper>
      )}

      {!!photos.length && (
        <IconsContainer
          isMobile={isMobile}
          limitReached={limitReached}
          className='icons-container'
        >
          <InfoIcon onClick={showSuggestion} className='info-icon' />
          <AddPhotoIcon
            className='add-photo-icon'
            onClick={onAddPhotoIconClick}
            title={title}
          />
        </IconsContainer>
      )}

      <ConfirmationModal
        message={t("modal.question.delete-photo")}
        onClick={handleDeletePhoto}
        onClose={closeModal}
        open={open}
        buttonLabel={t("common:button.delete")}
      />

      {limitAlertVisible && <PhotoLimitInfo preview />}
    </Container>
  );
}

export default PhotosInAnswer;
