import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import usePhotosObjectStore from "./usePhotosObjectStore";
import { clearLocalStorage } from "utils/utils";
import { FormTypes } from "types/types";

const { QUESTIONNAIRE } = FormTypes;

function useHandleBackToHomePage() {
  const { clearQuestionnaireStateAction } = useContext(QuestionnairesContext);
  const { deleteAllPhotosInIndexedDB } = usePhotosObjectStore(QUESTIONNAIRE);
  const navigate = useNavigate();

  const clearState = () => {
    clearQuestionnaireStateAction();
    deleteAllPhotosInIndexedDB();
    clearLocalStorage([
      "selectedQuestionnaire",
      "serialNumberPath",
      "machineProcess",
      "serialNumber",
      "currentPath",
      "answers",
      "accidentAnswers",
    ]);

    sessionStorage.clear();
  };

  const navigateToHomePage = () => {
    navigate("/home", {
      replace: true,
    });
  };

  const handleBackToHomePage = () => {
    clearState();
    navigateToHomePage();
  };

  return { handleBackToHomePage };
}

export default useHandleBackToHomePage;
