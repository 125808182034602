import styled from "styled-components/macro";

import { device } from "utils/utils";

export const ActionsWrapper = styled.div`
  ${({ theme }) => theme.fillUpRemainingSpace}
  justify-content: flex-end;
  padding-bottom: 1.25rem;

  @media screen and (min-height: 508px) {
    padding-bottom: 2rem;
  }
`;

export const SummaryLabelContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  position: relative;

  .check-icon {
    position: absolute;
    right: -3rem;
    bottom: -0.2rem;
    width: 2.5rem;
    height: 2.5rem;
    fill: ${({ theme }) => theme.emeraldGreen};

    @media ${device.iPad} {
      bottom: 0;
    }
  }
`;
