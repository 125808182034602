import { useContext } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import useTokens from "./useTokens";
import useNotification from "hooks/useNotification";
import { refreshSession } from "api/auth";
import { CountryCodes, RefreshSessionResponse } from "types/types";
import AuthContext from "contexts/auth-context/AuthContext";

const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let labels = {
    header: "Session has expired and you will be signed out as a result.",
    signOut: "Sign out",
    refreshSession: "Refresh session",
    errorMessage: "Unable to refresh session...",
  };

  if (isPolish) {
    labels = {
      header: "Sesja wygasła, w wyniku czego zostaniesz wylogowany.",
      signOut: "Wyloguj",
      refreshSession: "Przedłuż sesję",
      errorMessage: "Nie udało się przedłuzyć sesji...",
    };
  }

  return labels;
};

function useRefreshSession() {
  const { refreshToken: refresh_token, setTokens } = useTokens();
  const { setShouldReAuth } = useContext(AuthContext);
  const notify = useNotification();
  const {
    i18n: { language },
  } = useTranslation("login");
  const labels = getTranslations(language);
  const { pathname } = useLocation();

  const onSuccess = ({ data: { expires, ...tokens } }: RefreshSessionResponse) => {
    setTokens(tokens);
    setShouldReAuth(false);

    if (!pathname.includes("questionnaire") && !pathname.includes("accident-form")) {
      window.location.reload();
    }
  };

  const onError = () => {
    notify(labels.errorMessage, "error");
  };

  const { mutate, isLoading } = useMutation(refreshSession, {
    onSuccess,
    onError,
  });

  const handleRefreshSession = () => {
    mutate(refresh_token);
  };

  return { handleRefreshSession, isLoading, labels };
}

export default useRefreshSession;
