import styled from "styled-components/macro";

import useAccidentForm from "hooks/useAccidentForm";

import { Question, SubText } from "../styles/accidenFormStyles";

const Container = styled.div`
  padding-top: 1rem;
  margin-bottom: 2rem;
  user-select: none;
`;

function QuestionTitle() {
  const { currentQuestion } = useAccidentForm();

  const question = currentQuestion?.QuestionText as string;
  const subtext = currentQuestion?.QuestionSubtext as string;

  return (
    <Container>
      <Question>{question}</Question>

      {!!subtext && <SubText dangerouslySetInnerHTML={{ __html: subtext }} />}
    </Container>
  );
}

export default QuestionTitle;
