import { useEffect, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import styled, { css } from "styled-components/macro";

import useLanguage from "hooks/useLanguage";
import { QUESTION_TYPE_IDS, QuestionType } from "types/types";
import { isMobile } from "utils/utils";

import { ReactComponent as Microphone } from "assets/icons/microphone.svg";

const Container = styled.div<{ isMobile: boolean }>`
  align-self: center;
`;

const MicrophoneContainer = styled.div<{
  listening: boolean;
  isMobile: boolean;
  large: boolean;
  small: boolean;
  mini?: boolean;
}>`
  width: 12rem;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.7rem;
  padding-right: 1.5rem;
  padding-left: 0.4rem;

  ${({ small }) =>
    small &&
    css`
      height: 4rem;
    `}

  ${({ large }) =>
    large &&
    css`
      @media screen and (min-width: 360px) {
        width: 14rem;
      }
    `}

  ${({ listening, isMobile }) =>
    listening &&
    !isMobile &&
    css`
      cursor: pointer;
    `}

  ${({ listening }) =>
    listening &&
    css`
      background: ${({ theme }) => theme.primary};
      border: 4px solid ${({ theme }) => theme.white};
      ${({ theme }) => theme.cardShadow};
    `}


  @media screen and (min-height: 571px) {
    height: ${({ large }) => (large ? "6rem" : "5rem")};

    ${({ small }) =>
      small &&
      css`
        height: 4rem;
        border-width: 3px;
      `}
  }

  ${({ mini }) =>
    mini &&
    css`
      width: 6rem;
    `}

  ${({ mini, listening }) =>
    mini &&
    listening &&
    css`
      border: none;
      background: none;
      padding-left: 1rem;
      box-shadow: none;
    `}

  .microphone-icon {
    height: 3rem;

    path {
      fill: ${({ theme, listening }) => (listening ? theme.white : theme.primary)};
    }

    ${({ isMobile }) =>
      !isMobile &&
      css`
        cursor: pointer;
      `}

    ${({ listening, mini }) =>
      listening &&
      mini &&
      css`
        display: none;
      `}
  }
`;

const PulsatingCircle = styled.span<{ isMobile: boolean; isBusy: boolean }>`
  height: 1.5rem;
  width: 1.5rem;
  display: block;
  border-radius: 50%;
  background-color: ${({ theme, isBusy }) => (isBusy ? theme.ruby : theme.greyDark)};
  animation: ${({ isBusy }) =>
    isBusy ? "pulse-busy 1500ms infinite" : "pulse 1500ms infinite"};

  @keyframes pulse {
    0% {
      box-shadow: ${({ theme }) => theme.greyDark} 0 0 0 0;
    }
    75% {
      box-shadow: transparent 0 0 0 16px;
    }
  }

  @keyframes pulse-busy {
    0% {
      box-shadow: ${({ theme }) => theme.ruby} 0 0 0 0;
    }
    75% {
      box-shadow: transparent 0 0 0 16px;
    }
  }

  ${({ isMobile }) =>
    !isMobile &&
    css`
      cursor: pointer;
    `}
`;

type DictaphoneProps = {
  questionType?: QuestionType | "";
  onTextTranscriptChange: (val: string) => void;
  photoComment?: string;
  mini?: boolean;
  answerTextAreaValue?: string;
};

const { TEXT, FILE_PHOTO } = QUESTION_TYPE_IDS;

function Dictaphone({
  questionType,
  onTextTranscriptChange,
  mini,
  answerTextAreaValue,
}: DictaphoneProps) {
  const { transcript, listening, resetTranscript, interimTranscript, finalTranscript } =
    useSpeechRecognition();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const isPhotoGallery = pathname.includes("/gallery");
  const isTask = pathname.includes("/task");

  const isTextType = questionType === TEXT;
  const isPhotoType = questionType === FILE_PHOTO;
  const previousText = useRef<string>("");
  const { language } = useLanguage();

  // --------------- Search params handlers ---------------

  const getGalleryParams = () => {
    const formType = searchParams.get("formType") as string;
    const currentQuestionId = searchParams.get("currentQuestionId") as string;
    const photoIndex = searchParams.get("photoIndex") as string;

    return {
      formType,
      currentQuestionId,
      photoIndex,
    };
  };

  const getTaskParams = () => {
    const id = searchParams.get("id") as string;

    return {
      id,
    };
  };

  const addSearchParams = () => {
    if (isPhotoGallery) {
      setSearchParams({ ...getGalleryParams(), listening: "true" });
    } else if (isTask) {
      setSearchParams({ ...getTaskParams(), listening: "true" });
    } else {
      setSearchParams({ listening: "true" });
    }
  };

  const removeSearchParams = () => {
    if (isPhotoGallery) {
      setSearchParams({ ...getGalleryParams() });
    } else if (isTask) {
      setSearchParams({ ...getTaskParams() });
    } else {
      setSearchParams({});
    }
  };

  // --------------- Prevous text handlers ---------------

  const setPreviousText = () => {
    if (answerTextAreaValue) {
      previousText.current = answerTextAreaValue;
    }
  };

  const resetPrevoiusText = () => {
    previousText.current = "";
  };

  // --------------- Start/stop listening handlers ---------------

  const start = () => {
    SpeechRecognition.startListening({
      continuous: true,
      language,
    });
  };

  const stop = () => {
    SpeechRecognition.stopListening();
  };

  // --------------- On click handlers ---------------

  const onMicrophoneClick = () => {
    if (!listening) {
      start();
      addSearchParams();
      setPreviousText();
    }

    if (listening) {
      // If microphone button is clicked too soon after recording text,
      // transcript is not reset (the 'resetTranscript' function is not working)
      // and is pasted into the next recording.
      // Once the finalTranscript variable is filled with text,
      // it means that the recording has been concluded and trascrpit
      // can be reset (the 'resetTranscript' function is working).
      if ((!finalTranscript && !interimTranscript && !transcript) || finalTranscript) {
        stop();
        resetPrevoiusText();
        removeSearchParams();
        resetTranscript();
      }
    }
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    // Prevents from getting 'undefined' or 'null' into textarea.
    const prevText = previousText.current ?? "";
    const space = previousText.current ? " " : "";

    if (listening) {
      if (transcript) {
        onTextTranscriptChange(prevText + space + transcript);
      }
    }
  }, [transcript, onTextTranscriptChange, listening]);

  useEffect(() => {
    return () => {
      previousText.current = "";
      SpeechRecognition.abortListening();
      resetTranscript();
    };
  }, []);

  return (
    <Container isMobile={isMobile}>
      <MicrophoneContainer
        isMobile={isMobile}
        listening={listening}
        onClick={listening ? onMicrophoneClick : () => {}}
        large={isTextType}
        small={isPhotoType}
        mini={mini}
      >
        <Microphone
          className='microphone-icon'
          onClick={!listening ? onMicrophoneClick : () => {}}
        />

        {listening && (
          <PulsatingCircle isMobile={isMobile} isBusy={!!interimTranscript} />
        )}
      </MicrophoneContainer>
    </Container>
  );
}

export default Dictaphone;
