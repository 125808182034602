import { useContext } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import UserContext from "contexts/user-context/UserContext";

import { ReactComponent as AlertIcon } from "assets/icons/alert-exclamation.svg";

const Container = styled.div`
  font-family: GothamBold;

  @media screen and (min-height: 616px) {
    margin-bottom: 5rem;
  }
`;

const IconContainer = styled.div`
  .icon {
    width: 5rem;
    path {
      fill: ${({ theme }) => theme.errorColor};
    }
  }
`;

const InfoContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: ${({ theme }) => theme.aliceBlue};
  border: 4px solid ${({ theme }) => theme.errorColor};
  border-radius: 0.6rem;
  font-size: 1.6rem;

  display: flex;
  align-items: center;
`;

const Info = styled.div`
  padding-right: 1rem;
`;

const Confirmation = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.7rem;
`;

const Checkbox = styled.input`
  width: 2.5rem;
  height: 2.5rem;
  border-width: 0.4rem;

  &:hover {
    cursor: pointer;
  }
`;

const Label = styled.label`
  font-size: 1.8rem;
  opacity: 0.9;

  &:hover {
    cursor: pointer;
  }
`;

type GravityAlertProps = {
  visible: boolean;
  onChange: () => void;
};

function GravityAlert({ visible, onChange }: GravityAlertProps) {
  const {
    labels: { gravityAlertInfo },
  } = useContext(UserContext);
  const { t } = useTranslation("summary");

  return visible ? (
    <Container>
      <InfoContainer>
        <IconContainer>
          <AlertIcon className='icon' />
        </IconContainer>
        <Info>{gravityAlertInfo}</Info>
      </InfoContainer>
      <Confirmation>
        <Checkbox type='checkbox' id='confirm' onChange={onChange} />
        <Label htmlFor='confirm'>{t("gravity-alert.checkbox-label")}</Label>
      </Confirmation>
    </Container>
  ) : null;
}

export default GravityAlert;
