import { useState } from "react";

function useModal() {
  const [open, setOpen] = useState(false);
  const [secondOpen, setSecondOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const openSecondModal = () => {
    setSecondOpen(true);
  };

  const closeSecondModal = () => {
    setSecondOpen(false);
  };

  return { open, openModal, closeModal, secondOpen, openSecondModal, closeSecondModal };
}

export default useModal;
