import { HTMLAttributeAnchorTarget, MouseEvent } from "react";
import styled, { css } from "styled-components/macro";

import { isMobile, isAPK } from "utils/utils";

import { ReactComponent as LinkIcon } from "assets/icons/link.svg";

type LastFilledOutProps = {
  lastFilledOut: string;
  href: string;
};

const conatinerStyles = css`
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;

  position: absolute;
  bottom: -1rem;
  right: 0.2rem;

  background: ${({ theme }) => theme.primary_400};
  color: ${({ theme }) => theme.primary_50};

  @media screen and (min-height: 616px) {
    font-size: 1.4rem;
  }
`;

const AnchorContainer = styled.a`
  ${conatinerStyles}
  text-decoration: none;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  .link-icon {
    width: 1.5rem;

    path {
      stroke: ${({ theme }) => theme.primary_50};
    }
  }
`;

const SpanContainer = styled.span`
  ${conatinerStyles}
  cursor: default;
`;

const getTarget = () => {
  let target: HTMLAttributeAnchorTarget = "_blank";

  if (isMobile || isAPK) {
    target = "_self";
  }

  return target;
};

function LastFilledOut({ lastFilledOut, href }: LastFilledOutProps) {
  const visible = !!lastFilledOut;
  const target = getTarget();
  let content = null;

  const onClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
  };

  if (visible) {
    if (href) {
      content = (
        <AnchorContainer href={href} target={target} rel='noreferrer' onClick={onClick}>
          <span>{lastFilledOut}</span>
          <LinkIcon className='link-icon' />
        </AnchorContainer>
      );
    } else {
      content = <SpanContainer onClick={onClick}>{lastFilledOut}</SpanContainer>;
    }
  }

  return content;
}

export default LastFilledOut;
