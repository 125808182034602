import { useContext } from "react";

import { CollectionEnum } from "types/permissions.types";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";

const { MACHINES, PROCESSES } = CollectionEnum;

function useCollection() {
  const {
    state: { machineProcess },
  } = useContext(QuestionnairesContext);

  let collection;
  let entityId = "";

  if (machineProcess) {
    const { Machine, Process } = machineProcess;

    if (Machine) {
      collection = MACHINES;
      entityId = Machine.id;
    }

    if (Process) {
      collection = PROCESSES;
      entityId = Process.id;
    }
  }

  return { collection, entityId };
}

export default useCollection;
