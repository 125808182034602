import { ReactNode } from "react";
import { createPortal } from "react-dom";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { CountryCodes } from "types/types";

import close from "assets/icons/close.svg";

const ModalContainer = styled.div`
  width: 100vw;
  height: 80vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme }) => theme.level10};
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.primary_50};
  border-radius: 0.6rem;
  padding: 2rem;
  z-index: ${({ theme }) => theme.level10};
  animation: showModal 0.3s ease-out forwards;

  @keyframes showModal {
    0% {
      opacity: 0;
      transform: translateY(-50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Header = styled.div<{ error: boolean; paddingLeft?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: ${({ paddingLeft }) => (paddingLeft ? "0.8rem" : "0")};
  font-size: 1.8rem;

  > h4 {
    color: ${({ theme, error }) => (error ? theme.errorColor : theme.primary)};
  }

  .warning-icon {
    width: 3rem;
    height: 3rem;
    fill: ${({ theme }) => theme.warningColor};
  }
`;

const IconWrapper = styled.div<{ closeDisabled: boolean }>`
  width: 3rem;
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 0.8;
  }

  ${({ closeDisabled }) =>
    closeDisabled &&
    css`
      opacity: 0.25;
      cursor: not-allowed;

      &:hover {
        opacity: 0.25;
      }
    `}
`;
const CloseIcon = styled.img`
  width: 100%;
  height: 100%;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.75;
  z-index: ${({ theme }) => theme.level9};
`;

type ModalPropsType = {
  open: boolean;
  onClose?: () => void;
  header?: ReactNode;
  children: ReactNode;
  error?: boolean;
  paddingLeft?: boolean;
  closeDisabled?: boolean;
};

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let title = "Close";

  if (isPolish) {
    title = "Zamknij";
  }

  return { title };
};

function Modal({
  open,
  onClose,
  header,
  children,
  error = false,
  paddingLeft,
  closeDisabled = false,
}: ModalPropsType) {
  const {
    i18n: { language },
  } = useTranslation();

  const { title } = getTranslations(language);

  const onClick = () => {
    if (!closeDisabled && !!onClose) {
      onClose();
    }
  };

  return open
    ? createPortal(
        <ModalContainer>
          <ModalContent aria-modal='true' role='dialog'>
            <Header error={error} paddingLeft={paddingLeft}>
              <h4>{header}</h4>
              {onClose && (
                <IconWrapper
                  closeDisabled={closeDisabled}
                  onClick={onClick}
                  title={!closeDisabled ? title : ""}
                >
                  <CloseIcon src={close} alt='' />
                </IconWrapper>
              )}
            </Header>
            {children}
          </ModalContent>
          <ModalOverlay onClick={onClose} />
        </ModalContainer>,
        document.body,
      )
    : null;
}

export default Modal;
