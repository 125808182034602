import styled, { css } from "styled-components/macro";

import Thumb from "components/atoms/Thumb";

const Container = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  @media screen and (min-height: 508px) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
`;

const Label = styled.span<{ up?: boolean; down?: boolean; isZero: boolean }>`
  flex-basis: 15%;
  height: 3rem;

  display: grid;
  place-content: center;

  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;

  ${({ up }) =>
    up &&
    css`
      background-color: ${({ theme }) => theme.yesButton};
    `}

  ${({ down }) =>
    down &&
    css`
      background-color: ${({ theme }) => theme.noButton};
    `}

    ${({ isZero }) =>
    isZero &&
    css`
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
    `}

  .thumb {
    height: 2rem;
    transform: translateY(0);
  }

  .down {
    transform: scaleX(-1) translateY(0);
  }
`;

const Bar = styled.div<{ positive?: boolean; negative?: boolean; value: number }>`
  flex-basis: 85%;
  height: 3rem;
  position: relative;

  &::before {
    content: "";
    width: ${({ value }) => `${value}%`};
    height: 3rem;
    background-color: ${({ theme, positive }) =>
      positive ? theme.yesButton : theme.noButton};
    position: absolute;
    top: 0;
    left: 0;
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
`;

const Value = styled.div<{ isDark: boolean }>`
  width: 100%;
  color: ${({ isDark, theme }) => (isDark ? theme.primary : theme.white)};
  font-size: 1.5rem;
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 700;
`;

const Divider = styled.div`
  height: 4rem;
`;

type StatisticsProps = {
  percentage: {
    positive: number;
    negative: number;
  };
};

function Statistics({ percentage: { positive, negative } }: StatisticsProps) {
  const getFinalValue = (value: number) => +value.toFixed(0);

  let content = null;

  const positiveValue = getFinalValue(positive);
  const negativeValue = getFinalValue(negative);

  if (!positiveValue && !negativeValue) {
    content = <Divider />;
  } else {
    content = (
      <Container>
        <Line>
          <Label up isZero={!positiveValue}>
            <Thumb up customClass='thumb' />
          </Label>
          <Bar positive value={positive}>
            <Value isDark={!positiveValue}>{positiveValue}%</Value>
          </Bar>
        </Line>
        <Line>
          <Label down isZero={!negativeValue}>
            <Thumb down customClass='thumb down' />
          </Label>
          <Bar negative value={negative}>
            <Value isDark={!negativeValue}>{negativeValue}%</Value>
          </Bar>
        </Line>
      </Container>
    );
  }

  return content;
}

export default Statistics;
