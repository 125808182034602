import { useContext, ReactNode, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { RouteProps } from "react-router";

import AuthContext from "contexts/auth-context/AuthContext";
import useQueryString from "hooks/useQueryString";
import useCurrentPath from "hooks/useCurrentPath";

type ProtectedRouteProps = {
  children: ReactNode;
} & RouteProps;

function ProtectedRoute(props: ProtectedRouteProps) {
  const { authenticated, setRedirectPath } = useContext(AuthContext);
  const { pathname } = useLocation();
  const query = useQueryString();
  const { setCurrentPath } = useCurrentPath();

  const serialNo = query.get("SerialNo") ?? "";

  // Handles the case when user scanned qr code with
  // native phone scanner before logging in.
  // The 'ridirectPath' is set so that once user has logged in,
  // they are redirected to that path.
  useEffect(() => {
    if (!authenticated) {
      if (pathname.includes("check-lists")) {
        setRedirectPath(`/check-lists?SerialNo=${serialNo}`);
      }
    } else {
      const queryString = serialNo ? `?SerialNo=${serialNo}` : "";
      const path = pathname + queryString;

      setCurrentPath(path);
    }
  }, [authenticated, pathname, serialNo, setRedirectPath, setCurrentPath]);

  return authenticated ? <>{props.children}</> : <Navigate to={{ pathname: "/login" }} />;
}

export default ProtectedRoute;
