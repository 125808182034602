import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import Button from "components/atoms/Button";

import { ButtonContainer } from "styles/generalStyles";
import { CountryCodes } from "types/types";

const Container = styled.div`
  ${({ theme }) => theme.fillUpRemainingSpace}
  text-align: center;
  font-size: 1.8rem;
  justify-content: space-between;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
`;

const Header = styled.div`
  font-style: italic;
`;

function NoQuestionsAlert({
  onQuitQuestionnaireClick,
}: {
  onQuitQuestionnaireClick: () => void;
}) {
  const {
    i18n: { language },
  } = useTranslation();

  let labels = {
    title: "No questions",
    btn: "Back",
  };

  const isPolish = language.includes(CountryCodes.PL);

  if (isPolish) {
    labels = {
      title: "Brak pytań",
      btn: "Powrót",
    };
  }

  return (
    <Container>
      <Header>&#8211;&nbsp;{labels.title}&nbsp;&#8211;</Header>

      <Actions>
        <ButtonContainer middle>
          <Button label={labels.btn} onClick={onQuitQuestionnaireClick} />
        </ButtonContainer>
      </Actions>
    </Container>
  );
}

export default NoQuestionsAlert;
