import { ReactNode } from "react";

import { isMobile } from "utils/utils";

import { Container, Wrapper } from "../../styles/prompt.styles";

type PromptContainerProps = {
  onClick: () => void;
  children: ReactNode;
};

function PromptContainer({ children, onClick }: PromptContainerProps) {
  return (
    <Container>
      <Wrapper isMobile={isMobile} onClick={onClick}>
        {children}
      </Wrapper>
    </Container>
  );
}

export default PromptContainer;
